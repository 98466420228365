import { THM_WINDOW_URL } from 'common/constants/window';
import {
  getConfigDataProperty,
  getCorrelationId,
  getTHMLaunchData,
  getWindowState
} from 'sdk/selectors';
import observeStore from 'sdk/utils/observe-store';
import { Store } from 'types/redux';

let thmProfilingAttempt = 0;
let thmReloadInterval: NodeJS.Timeout | null = null;
const THM_REFRESH_TIME_FOR_SSI = 25 * 60 * 1000; // 25 minutes

/**
 * Handles launching and refreshing the THM script and iframe.
 */
const thmSideEffects = (store: Store) => {
  observeStore(store, getTHMLaunchData, thmLaunchData => {
    if (!thmLaunchData) {
      return;
    }

    const state = store.getState();

    if (getWindowState(store.getState(), 'thm')) {
      store.dispatch({
        data: { id: 'thm' },
        type: '@@window/CLOSE_WINDOW'
      });
    }

    const { orgId, sessionId } = thmLaunchData;
    const ssiStatus = thmLaunchData.ssiStatus || 'undefined';

    if (thmReloadInterval) {
      clearInterval(thmReloadInterval);
    }

    store.dispatch({
      data: {
        attributes: {
          'data-tmxsession': sessionId,
          title: 'For system use, please ignore'
        },
        id: 'thm',
        noscript: true,
        query: {
          org_id: orgId,
          session_id: sessionId
        },
        src: THM_WINDOW_URL,
        type: 'hidden'
      },
      type: '@@window/OPEN_WINDOW'
    });

    store.dispatch({
      data: {
        api_response: 'undefined',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        correlation_id: getCorrelationId(state)!,
        event: 'THM Profiling',
        event_action: 'THM Profiling Request',
        event_label: `THM Request–Attempt-${++thmProfilingAttempt}-${ssiStatus}`,
        xo_visitid: getConfigDataProperty(state, 'visitId')
      },
      type: '@@window/SEND_GTM_EVENT'
    });

    if (ssiStatus === 'OPTED_IN') {
      thmReloadInterval = setInterval(() => {
        store.dispatch({
          data: { id: 'thm' },
          type: '@@window/RELOAD'
        });
      }, THM_REFRESH_TIME_FOR_SSI);
    }
  });
};

export default thmSideEffects;
