import { SDKLITE_LOADER_URL } from 'common/constants/window';
import * as Hybrid from 'sdk/dom/hybrid';
import onCheckoutComplete from 'sdk/effects/postmessage/handlers/checkout-complete';
import {
  getCardBrandsQueryParam,
  getCorrelationId,
  getHybridAPIVersion,
  getIntegrationType,
  getIsCheckoutReadyToLaunch,
  getIsHybridManualCheckout,
  getIsSRCBranded,
  getMerchantConfig,
  getMobileButtonType,
  getSDKParams,
  getSRCConfigs,
  getVInit,
  getVInitProperty,
  getVInitRequest
} from 'sdk/selectors';
import logEvent from 'sdk/utils/log-event';
import { Action, Middleware, MiddlewareAPI } from 'types/redux';
import { PrefillCallback } from 'types/sdk';

const getLaunchCheckoutCallback =
  (store: MiddlewareAPI, onPrefillRequest: PrefillCallback) => () => {
    const state = store.getState();
    const integrationType = getIntegrationType(state);
    const hybridAPIVersion = getHybridAPIVersion(state);

    // Hybrid 6.x and Cross App implementations use localStorage to sync
    // correlationId between the two instances of sdk.js, one in the button
    // webview (mobile-button.html) and one in the checkout webview
    // (sdk-lite.html). The value is set in the button webview on button click,
    // and removed when the checkout complete message is received.
    if (hybridAPIVersion === '6.x' || integrationType === 'sdk-lite-cross-app') {
      store.dispatch({
        data: {
          key: 'correlationId',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          value: getCorrelationId(store.getState())!
        },
        type: '@@window/SET_LOCALSTORAGE'
      });
    }

    store.dispatch({
      onProfile(thmData) {
        store.dispatch({
          data: thmData,
          type: '@@thm/LAUNCH'
        });
      },
      type: '@@hybrid/PROFILE_DEVICE'
    });

    if (getIsCheckoutReadyToLaunch(state)) {
      store.dispatch({
        events: { onPrefillRequest },
        type: '@@hybrid/CHECKOUT_LAUNCH'
      });

      if (hybridAPIVersion === '7.x') {
        store.dispatch({
          type: '@@hybrid/CHECKOUT_QUEUED'
        });
      }
    }
  };

/**
 * Handles SDK Lite interactions with the HybridSDK.
 */
const hybridMiddleware: Middleware = store => next => (action: Action) => {
  const state = store.getState();
  const apiVersion = getHybridAPIVersion(state);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const vOptions = getVInit(state)!;

  switch (action.type) {
    case '@@hybrid/REGISTER_MERCHANT_DATA':
      Hybrid.registerMerchantData(action.data);
      break;
    case '@@hybrid/LOG_EVENT':
      logEvent({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        correlationId: getCorrelationId(state)!,
        ...action
      });
      break;
    case '@@hybrid/BUTTON_INIT':
      const launchCheckout = getLaunchCheckoutCallback(store, action.events.onPrefillRequest);

      Hybrid.initButton({
        apiVersion,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        correlationId: getCorrelationId(state)!,
        events: {
          checkoutReady({ alreadyLaunched }) {
            store.dispatch({
              alreadyLaunched,
              events: {
                launchCheckout
              },
              type: '@@hybrid/CHECKOUT_READY'
            });
          },
          launchCheckout,
          onNativeButtonClick() {
            // onNativeButtonClick is not called for hybrid-plugin integrations
            store.dispatch({
              type: '@@hybrid/NATIVE_BUTTON_CLICK'
            });

            store.dispatch({
              message: {
                description: 'Hybrid Button Click',
                event: 'visa.sdklite.button-click'
              },
              type: '@@hybrid/LOG_EVENT'
            });

            launchCheckout();
          },
          openLoader() {
            store.dispatch({
              data: {
                id: 'checkout',
                overlay: false,
                src: SDKLITE_LOADER_URL,
                type: 'popup'
              },
              type: '@@window/OPEN_WINDOW'
            });
          },
          showNativeSpinner() {
            store.dispatch({
              events: action.events,
              type: '@@hybrid/LAUNCH_NATIVE_SPINNER'
            });
          }
        },
        integrationType: getIntegrationType(state),
        isHybridManualCheckout: getIsHybridManualCheckout(state),
        onSyncCookies(cookies) {
          store.dispatch({
            data: cookies,
            type: '@@hybrid/SYNC_COOKIES'
          });

          // Return value used by hybrid-plugin to determine support for card art
          return !getIsSRCBranded(store.getState());
        },
        vOptions
      });

      break;
    case '@@hybrid/LAUNCH_NATIVE_SPINNER':
      Hybrid.launchNativeSpinner({
        cancelCheckout: () =>
          onCheckoutComplete(
            store,
            {
              data: { callid: null },
              type: 'cancel'
            },
            action.events.onPrefillRequest
          )
      });
      break;
    case '@@hybrid/BUTTON_LOAD':
      Hybrid.loadButton({
        cardBrands: getCardBrandsQueryParam(state),
        integrationType: getIntegrationType(state),
        mobileButtonType: getMobileButtonType(state)
      });
      break;
    case '@@hybrid/RENDER':
      Hybrid.initCheckout({
        onRender: action.onRender
      });
      break;
    case '@@hybrid/CHECKOUT_READY':
      Hybrid.checkoutReady({
        alreadyLaunched: action.alreadyLaunched,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        correlationId: getCorrelationId(state)!,
        events: action.events
      });
      break;
    case '@@hybrid/CHECKOUT_LAUNCH':
      Hybrid.launchHybridCheckout({
        apiVersion,
        events: {
          ...action.events,
          onCheckoutComplete: message =>
            onCheckoutComplete(store, message, action.events.onPrefillRequest)
        },
        launchData: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          merchantConfig: getMerchantConfig(state)!,
          sdkParams: getSDKParams(state),
          srcConfigs: getSRCConfigs(state),
          vInitRequest: getVInitRequest(state)
        }
      });
      break;
    case '@@hybrid/SETUP_MESSAGE_FORWARDING':
      Hybrid.setupCheckoutMessageForwarding();
      break;
    case '@@hybrid/PROFILE_DEVICE':
      Hybrid.profileDevice({
        onProfile: action.onProfile
      });
      break;
    case '@@hybrid/DISMISS':
      Hybrid.dismissCheckout({ onDismiss: action.onDismiss });
      break;
    case '@@hybrid/SEND_RESULT':
      const sdkLiteMerchantAppInfo = getVInitProperty(state, 'sdkLiteMerchantAppInfo');
      Hybrid.sendResultToNative({
        packageName: sdkLiteMerchantAppInfo?.package,
        payload: action.data,
        scheme: sdkLiteMerchantAppInfo?.scheme
      });
      break;
    case '@@hybrid/SYNC_COOKIES':
      Hybrid.syncCookies({ cookies: action.data });
      break;
    case '@@hybrid/UPDATE_PAYMENT_COMPLETE':
      Hybrid.updatePaymentComplete(action.data);
      break;
    case '@@hybrid/REGISTER_DEFAULT_STRINGS':
      Hybrid.registerDefaultStrings();
      break;
  }

  return next(action);
};

export default hybridMiddleware;
