/**
 * Check if an element with class "v-button" exists. Optionally look for a
 * src property containing "legacy=false". Currently "legacy=false" is the only indicator for
 * RXO vs SRC branded buttons. Load of merchant config will change query param to `false` if
 * it's SRC enabled, so if this fires too fast or before config load, it'll return a false negative.
 * @param {boolean} checkSrc
 * @returns {boolean}
 */
const vButtonCheck = (checkSrc = false): boolean => {
  const vButton = document.querySelector('.v-button');
  const srcSearchStr = 'legacy=false';

  if (!vButton) {
    return false;
  }

  if (!checkSrc) {
    return true;
  }

  const buttonSrc = vButton.getAttribute('src');

  return Boolean(buttonSrc && buttonSrc.indexOf(srcSearchStr) !== -1);
};

export default vButtonCheck;
