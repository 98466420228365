import canUseLocalStorage from 'config/utils/can-use-localstorage';
import { StorageTypeMap } from 'types/storage';

function shouldParse(key: string) {
  return key === 'thmData';
}

const localStorageAvailable = canUseLocalStorage();

function createStorage(storageType: 'sessionStorage' | 'localStorage') {
  function getItem<K extends keyof StorageTypeMap>(key: K): StorageTypeMap[K] | null;
  function getItem(key: string): string | null;
  function getItem(key: string): string | null {
    if (!localStorageAvailable) {
      return null;
    }

    const value = window[storageType].getItem(key);
    return value && shouldParse(key) ? JSON.parse(value) : value;
  }

  function setItem<K extends keyof StorageTypeMap>(key: K, value: StorageTypeMap[K]): void;
  function setItem(key: string, value: string): void;
  function setItem(key: string, value: string) {
    if (localStorageAvailable) {
      const storedValue = typeof value === 'string' ? value : JSON.stringify(value);
      window[storageType].setItem(key, storedValue);
    }
  }

  function removeItem(key: keyof StorageTypeMap): void;
  function removeItem(key: string): void;
  function removeItem(key: string) {
    if (localStorageAvailable) {
      window[storageType].removeItem(key);
    }
  }

  return {
    getItem,
    removeItem,
    setItem
  };
}

export const sessionStorage = createStorage('sessionStorage');
export const localStorage = createStorage('localStorage');
