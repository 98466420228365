// initialize Hybrid SDK ASAP to register global message handlers
import 'common/hybrid';
import {
  CHECKOUT_INITIALIZED,
  CHECKOUT_NOT_INITIALIZED,
  INVALID_OPTIONS
} from 'common/constants/error-messages';
import { RXO_WINDOW_URL, SDK_LOADER_URL } from 'common/constants/window';
import { getUserAgent, isBlacklistedBrowser } from 'common/utils/browser';
import { mark, marks } from 'common/utils/instrumentation';
import logger from 'common/utils/logger';
import { initVInitClickSpy, removeVInitClickSpy } from 'sdk/dom/button';
import handlePostmessageSideEffects from 'sdk/effects/postmessage';
import handleTHMSideEffects from 'sdk/effects/thm';
import checkout from 'sdk/lifecycle/checkout';
import init from 'sdk/lifecycle/init';
import { defaultCheckoutState } from 'sdk/reducers/checkout';
import { defaultHybridState } from 'sdk/reducers/hybrid';
import {
  getApiKey,
  getCheckoutResponse,
  getCheckoutStatus,
  getCheckoutWindowType,
  getHybridAPIVersion,
  getIsCheckoutReadyToLaunch,
  getIsHybrid,
  getIsSRCBranded,
  getIsVSBButtonless,
  getIsVSBInit,
  getMerchantPayload,
  getOrchestrationVSBInitResponse,
  getPrefillStatus,
  getRXOWindowQuery,
  getVInitSetting,
  shouldPreloadApp
} from 'sdk/selectors';
import createStore from 'sdk/store';
import getErrorStack from 'sdk/utils/get-error-stack';
import normalizeVInitOptions from 'sdk/utils/normalize-vinit-options';
import observeStore from 'sdk/utils/observe-store';
import resolvePromise from 'sdk/utils/resolve-promise';
import { CheckoutCancel, CheckoutError, CheckoutSuccess } from 'types/messages/incoming';
import { VSBInitResponse } from 'types/orchestration';
import { PrefillCallback, RawVOptions, VCallbacks } from 'types/sdk';
import getMerchantCallback from './utils/get-merchant-callback';

export default function loadSDK() {
  const store = createStore({
    checkout: defaultCheckoutState,
    hybrid: defaultHybridState
  });

  const merchantCallbacks: VCallbacks = {
    'payment.cancel': () => {},
    'payment.error': () => {},
    'payment.success': () => {},
    'pre-payment.user-data-prefill': () => null
  };

  // Need to access prefill callback lazily in case merchants call V.on() again
  // after calling V.init()
  const onPrefillRequest: PrefillCallback = () =>
    merchantCallbacks['pre-payment.user-data-prefill']();

  // Temporarily add click listener to button to detect clicks prior to V.init()
  // and send that information to GTM.
  const vInitClickSpy = () => {
    store.dispatch({
      payload: true,
      type: '@@sdk/NSMI_VINIT_BUTTON_CLICK'
    });
  };

  // add vInit click spy for NSMI GTM tracking
  initVInitClickSpy(vInitClickSpy);

  const removeInitListener = observeStore(store, getCheckoutStatus, checkoutStatus => {
    if (checkoutStatus !== 'pre-init' && !getIsVSBButtonless(store.getState())) {
      // remove vInit click spy for NSMI GTM tracking
      removeVInitClickSpy(vInitClickSpy);
      removeInitListener();
    }
  });

  function queueCheckout() {
    store.dispatch({
      data: {
        event: 'Visa Checkout Button State',
        // Note: this event_action is probably a misnomer, since checkout can be
        // initiated via the keyboard, via Learn More, or the Hybrid SDK
        event_action: 'Visa Checkout Button Click Registered',
        event_category: 'Merchant Site',
        event_label: 'Visa Checkout Button Click Registered'
      },
      type: '@@window/SEND_GTM_EVENT'
    });

    const state = store.getState();

    // Launch checkout immediately if merchant response is available.
    if (getIsCheckoutReadyToLaunch(state)) {
      checkout({ onPrefillRequest, store });
      return;
    }

    if (!getIsHybrid(state)) {
      if (getCheckoutWindowType(state) === 'POPUP') {
        // If app is not yet ready to launch, open the popup window
        // immediately with the loader URL. When app is ready, the URL will be
        // replaced with the app URL.
        store.dispatch({
          data: {
            id: 'checkout',
            query: {
              isSRCBranded: getIsSRCBranded(state)
            },
            src: SDK_LOADER_URL,
            type: 'popup'
          },
          type: '@@window/OPEN_WINDOW'
        });
      } else {
        store.dispatch({
          message: {
            type: 'visa.src.show-launch-loader'
          },
          target: 'self',
          type: '@@window/SEND_POSTMESSAGE'
        });
      }
    }

    // Otherwise, launch checkout once merchant response comes back.
    const unsubscribe = observeStore(store, getIsCheckoutReadyToLaunch, isReady => {
      if (isReady) {
        checkout({ onPrefillRequest, store });
        unsubscribe();
      }
    });
  }

  const V = {
    init(rawVOptions: RawVOptions) {
      // Validate and normalize merchant-provided init data
      const vOptions = normalizeVInitOptions(rawVOptions);

      mark(marks.vinit);

      store.dispatch({
        payload: getErrorStack(),
        type: '@@sdk/NSMI_VINIT_ERROR_STACK'
      });

      if (isBlacklistedBrowser()) {
        store.dispatch({
          type: '@@sdk/UNSUPPORTED_BROWSER',
          ua: getUserAgent()
        });

        return null;
      }

      // Calling V.init() multiple times is deprecated. However, some major
      // merchants (e.g. Pizza Hut, Walmart, Telstra) rely on this legacy
      // behavior to update VOptions or reinitialize the button long after
      // calling V.init() the first time.
      if (getApiKey(store.getState())) {
        // VSB integrations may lazy-load the button and call V.init() again
        // after V.initializeVsb() resolves. Do not throw a warning in this case.
        if (!getIsVSBInit(store.getState())) {
          logger.warn(CHECKOUT_INITIALIZED);
        }

        // If V.init() has already been called, skip setting up store observers.
        return init({ onPrefillRequest, store, vOptions });
      }

      if (!vOptions.apikey) {
        logger.error(INVALID_OPTIONS);

        store.dispatch({
          type: '@@config/INVALID_INIT_OPTIONS'
        });

        store.dispatch({
          data: {
            error: {
              reason: 'API_KEY_MISSING'
            }
          },
          type: '@@orchestration/VSB_INIT_COMPLETE'
        });

        return null;
      }

      handlePostmessageSideEffects(store, onPrefillRequest);
      // THM side-effects are based purely on changes to state.thm
      handleTHMSideEffects(store);

      observeStore(store, getCheckoutStatus, (currStatus, prevStatus) => {
        // Queue checkout when checkout status changes to 'queued'
        if (prevStatus !== 'queued' && currStatus === 'queued') {
          queueCheckout();
        }
      });

      observeStore(store, getCheckoutResponse, message => {
        // Call merchant callbacks when checkout response becomes available
        if (message) {
          if (message.data) {
            delete message.data.dataLayer;
          }

          const state = store.getState();

          if (message.type === 'success') {
            const successPayload = getMerchantPayload<CheckoutSuccess>(state);

            // US49316: Do not send token indicator in merchant payload if dataLevel equals "NONE"
            if (getVInitSetting(state, 'dataLevel') === 'NONE') {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              delete (successPayload as any).paymentMethodType;
            }

            merchantCallbacks['payment.success'](successPayload);

            // Postmessage checkout payload to the VSB Adapter
            store.dispatch({
              message: {
                data: successPayload,
                type: 'visa.vsb.complete'
              },
              target: 'self',
              type: '@@window/SEND_POSTMESSAGE'
            });
          } else if (message.type === 'cancel') {
            const cancelPayload = getMerchantPayload<CheckoutCancel>(state);
            merchantCallbacks['payment.cancel'](cancelPayload);

            // Postmessage checkout payload to the VSB Adapter
            store.dispatch({
              message: {
                data: cancelPayload,
                type: 'visa.vsb.cancel'
              },
              target: 'self',
              type: '@@window/SEND_POSTMESSAGE'
            });
          } else {
            const errorPayload = getMerchantPayload<CheckoutError>(state);
            merchantCallbacks['payment.error'](errorPayload, message.error);

            // Postmessage checkout payload to the VSB Adapter
            store.dispatch({
              message: {
                data: errorPayload,
                type: 'visa.vsb.error'
              },
              target: 'self',
              type: '@@window/SEND_POSTMESSAGE'
            });
          }
        }
      });

      // Dispatch prefill data
      observeStore(store, getPrefillStatus, (currStatus, prevStatus) => {
        if (prevStatus !== 'requested' && currStatus === 'requested') {
          resolvePromise(onPrefillRequest(), data => {
            if (data) {
              store.dispatch({
                data,
                type: '@@sdk/PREFILL_DATA_RECEIVED'
              });

              store.dispatch({
                message: {
                  data,
                  type: 'setPrefillData'
                },
                target: 'checkout',
                type: '@@window/SEND_POSTMESSAGE'
              });
            }
          });
        }
      });

      return init({ onPrefillRequest, store, vOptions });
    },

    initializeVsb(rawVOptions: RawVOptions): Promise<VSBInitResponse> {
      /*
       * As of F23210, we need to differentiate Vsb-button vs Vsb-buttonless (aka Vsb-integrated) flows.
       * the merchant is considered a Vsb merchant if they call initializeVsb at least once.
       * Vsb-button vs Vsb-buttonless is determined by if the *latest* init call was
       * V.init or V.initializeVsb
       */
      store.dispatch({
        data: true,
        type: '@@sdk/CHECKOUT_VSB_INIT_BUTTONLESS'
      });
      store.dispatch({
        type: '@@sdk/CHECKOUT_VSB_INIT'
      });

      const initPromise: Promise<VSBInitResponse> = new Promise(resolve => {
        const unsubscribe = observeStore(store, getOrchestrationVSBInitResponse, response => {
          if (response) {
            resolve(response);
            unsubscribe();
          }
        });
      });
      V.init(rawVOptions);

      return initPromise;
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    on(eventName: keyof VCallbacks, callback: (...args: any[]) => any) {
      if (!merchantCallbacks[eventName]) {
        return;
      }

      // Prefill callback can always be called synchronously.
      if (eventName === 'pre-payment.user-data-prefill') {
        merchantCallbacks[eventName] = callback;
        return;
      }

      const isHybrid = getIsHybrid(store.getState());

      // SDKLite and Hybrid Plugin integrations must execute merchant callbacks
      // synchronously due to issues with setTimeout on iOS.
      if (isHybrid) {
        merchantCallbacks[eventName] = callback;
      } else {
        // For normal web integrations, merchant callbacks should be called
        // after a timeout to allow the event queue to clear. This behavior is
        // carried over from SDK v1. Whether it is actually required for some
        // integrations is unknown.
        merchantCallbacks[eventName] = (...args: Array<unknown>) => {
          setTimeout(() => callback(...args), 0);
        };
      }
    },

    orchReady() {
      store.dispatch({
        type: '@@orchestration/READY'
      });
    },

    setOptions(rawVOptions: RawVOptions) {
      if (!getApiKey(store.getState())) {
        logger.error(CHECKOUT_NOT_INITIALIZED);
        return;
      }

      const vOptions = normalizeVInitOptions(rawVOptions);

      store.dispatch({
        data: vOptions,
        type: '@@sdk/CHECKOUT_SETUP'
      });

      const state = store.getState();

      // Preload the app a second time if the merchant config has already been
      // resolved. First preload happens when we receive the merchant config (see
      // middleware/postmessage/handlers/merchant-config.ts).
      if (shouldPreloadApp(state)) {
        store.dispatch({
          data: {
            attributes: {
              title: 'Visa Checkout'
            },
            id: 'checkout',
            onLoad: () => {
              store.dispatch({
                message: {
                  data: Date.now(),
                  type: 'rxo:render'
                },
                target: 'checkout',
                type: '@@window/SEND_POSTMESSAGE'
              });
            },
            query: getRXOWindowQuery(state),
            src: RXO_WINDOW_URL,
            type: 'preload'
          },
          type: '@@window/OPEN_WINDOW'
        });
      }
    }
  };

  const hybridAPIVersion = getHybridAPIVersion(store.getState());

  if (hybridAPIVersion === '5.x') {
    store.dispatch({
      type: '@@sdk/UNSUPPORTED_HYBRID_VERSION',
      version: hybridAPIVersion
    });
  } else if (isBlacklistedBrowser()) {
    store.dispatch({
      type: '@@sdk/UNSUPPORTED_BROWSER',
      ua: getUserAgent()
    });
  } else {
    window.V = V;

    let callbackCalled = false;

    const isDocumentReady = () =>
      document.readyState === 'interactive' || document.readyState === 'complete';

    const callMerchantCallback = () => {
      const merchantCallback = getMerchantCallback();
      if (merchantCallback && !callbackCalled) {
        callbackCalled = true;
        merchantCallback();
      }
    };

    // Some merchants lazy-load the SDK after a button click. In this case,
    // immediately call the merchant callback.
    if (isDocumentReady()) {
      callMerchantCallback();
    } else {
      document.addEventListener('readystatechange', () => {
        if (isDocumentReady()) {
          callMerchantCallback();
        }
      });
    }
  }

  return V;
}
