/**
 * Return number of script tags in merchant window that has a src containing a certain string.
 * Used to check for multiple loads of SDKs.
 * @param {*} searchTerm
 * @returns {number}
 */
const sdkSrcSearchScriptCount = (searchTerm = ''): number => {
  if (searchTerm.trim() === '') {
    return 0;
  }

  return [].slice
    .call(document.querySelectorAll('script'))
    .filter((s: HTMLScriptElement) => Boolean(s.src && s.src.indexOf(searchTerm) !== -1)).length;
};

export default sdkSrcSearchScriptCount;
