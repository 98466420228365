/* START GENAI@GHCOPILOT */
import { Locale, LocaleUnderscore } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */
import { NONEXISTANT_ELEMENT } from 'common/constants/error-messages';
import logger from 'common/utils/logger';
import { iframeIdMap } from 'sdk/constants/iframe';
import { LearnMoreEvents } from 'types/sdk';
import { positionIframe } from './iframe';
import { injectGlobalStyle } from './style';

type LearnResizeOptions = { height: number };

export function getLearnMoreLinks() {
  return document.querySelectorAll('.v-learn') as NodeListOf<HTMLAnchorElement>;
}

export function getActiveLearnMoreLink() {
  const linkEl = document.querySelector('.v-learn[data-active="true"]') as HTMLAnchorElement | null;

  if (!linkEl) {
    logger.error(NONEXISTANT_ELEMENT, {
      selector: '.v-learn[data-active="true"]'
    });
  }

  return linkEl;
}

export function getLocaleFromLink(linkEl: HTMLAnchorElement | null): Locale | LocaleUnderscore {
  if (linkEl) {
    return linkEl.getAttribute('data-locale') as Locale | LocaleUnderscore;
  }

  return 'en-US';
}

export function resizeLearnContent({ height }: LearnResizeOptions) {
  const iframe = document.getElementById(iframeIdMap.learn) as HTMLIFrameElement;

  if (!iframe) {
    return;
  }

  iframe.style.height = `${height}px`;

  const linkEl = getActiveLearnMoreLink();

  if (linkEl) {
    positionIframe({ iframe, target: linkEl });
  }
}

export function hideLearnMore() {
  getLearnMoreLinks().forEach(linkEl => {
    linkEl.style.visibility = 'hidden';
  });
}

export function deactivateLearnLink() {
  const linkEl = getActiveLearnMoreLink();

  if (linkEl) {
    linkEl.removeAttribute('data-active');
  }
}

export function initLearnMore(events: LearnMoreEvents) {
  if (document.querySelectorAll('.v-learn-default').length) {
    injectGlobalStyle(
      'v-learn-default',
      `
        .v-learn.v-learn-default {
          float: right;
          margin-right: 4px;
          font-size: 12px;
          text-transform: capitalize;
          color: #003ea9;
          cursor: pointer;
          text-decoration: none;
        }
        .v-learn.v-learn-default:hover {
          text-decoration: underline;
        }
        .v-learn.v-learn-default:visited {
          color: #003ea9;
        }
      `
    );
  }

  getLearnMoreLinks().forEach(linkEl => {
    linkEl.setAttribute('aria-label', 'Learn more about Visa Checkout');

    linkEl.addEventListener('click', e => {
      // Prevent TMM link from adding hash to the URL, which can cause problems in
      // SPA integrations that rely on hash-based routing.
      e.preventDefault();

      linkEl.setAttribute('data-active', 'true');

      events.click(e);
    });

    linkEl.addEventListener('keydown', e => {
      if (e.key === 'Enter') {
        linkEl.setAttribute('data-active', 'true');
        events.enter(e);
      }
    });
  });
}
