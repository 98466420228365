/* eslint-disable @typescript-eslint/ban-types */

import { BooleanString } from 'types/enums';
import {
  NVPair,
  PaymentRequest,
  RawVOptions,
  TokenizationSetup,
  VOptions,
  VSettings
} from 'types/sdk';
import normalizeLocale from './normalize-locale';
import normalizeTotal from './normalize-total';

function propertyExists<K extends string>(obj: object, key: K): obj is { [key in K]: unknown } {
  return key in obj;
}

function propertyIsString<K extends string>(obj: object, key: K): obj is { [key in K]: string } {
  if (propertyExists(obj, key)) {
    return typeof obj[key] === 'string';
  }

  return false;
}

function propertyIsNumber<K extends string>(obj: object, key: K): obj is { [key in K]: number } {
  if (propertyExists(obj, key)) {
    return typeof obj[key] === 'number';
  }

  return false;
}

function propertyIsBoolean<K extends string>(obj: object, key: K): obj is { [key in K]: boolean } {
  if (propertyExists(obj, key)) {
    return typeof obj[key] === 'boolean';
  }

  return false;
}

function propertyIsBooleanString<K extends string>(
  obj: object,
  key: K
): obj is { [key in K]: BooleanString } {
  if (propertyIsString(obj, key)) {
    return obj[key] === 'true' || obj[key] === 'false';
  }

  return false;
}

function propertyIsObject<K extends string>(obj: object, key: K): obj is { [key in K]: object } {
  if (propertyExists(obj, key)) {
    return typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]);
  }

  return false;
}

function propertyIsArray<K extends string>(
  obj: object,
  key: K
): obj is { [key in K]: Array<unknown> } {
  if (propertyExists(obj, key)) {
    return Array.isArray(obj[key]);
  }

  return false;
}

// Since we have no idea what the client will pass to V.init(), normalize these
// values in an extremely strict way that discards all invalid values.
export default function normalizeVInitOptions(rawVOptions?: RawVOptions): VOptions {
  const vOptions: VOptions = { apikey: '' };

  if (typeof rawVOptions !== 'object' || rawVOptions === null) {
    return vOptions;
  }

  if (propertyIsString(rawVOptions, 'apikey')) {
    vOptions.apikey = rawVOptions.apikey;
  }

  if (propertyIsString(rawVOptions, 'backgroundImageId')) {
    vOptions.backgroundImageId = rawVOptions.backgroundImageId;
  }

  if (propertyIsString(rawVOptions, 'clientId')) {
    vOptions.clientId = rawVOptions.clientId;
  }

  if (propertyIsString(rawVOptions, 'encryptionKey')) {
    vOptions.encryptionKey = rawVOptions.encryptionKey;
  }

  if (propertyIsString(rawVOptions, 'externalClientId')) {
    vOptions.externalClientId = rawVOptions.externalClientId;
  }

  if (propertyIsString(rawVOptions, 'externalProfileId')) {
    vOptions.externalProfileId = rawVOptions.externalProfileId;
  }

  if (propertyIsObject(rawVOptions, 'paymentRequest')) {
    const rawPaymentRequest = rawVOptions.paymentRequest;

    const paymentRequest: PaymentRequest = {};

    const knownPaymentRequestProperties = [
      'currencyCode',
      'customData',
      'description',
      'discount',
      'giftWrap',
      'merchantRequestId',
      'misc',
      'orderId',
      'promoCode',
      'shippingHandling',
      'subtotal',
      'tax',
      'total'
    ];

    // Walgreens includes custom properties on paymentRequest which are not part
    // of the integration guide. Since v1 allows arbitrary properties on
    // paymentRequest and merchants have taken advantage of that, we must
    // support it in v2. See sdk/__tests__/sdk-integration-walgreens.ts
    for (const key of Object.keys(rawPaymentRequest)) {
      if (!knownPaymentRequestProperties.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - This is NOT type-safe.
        paymentRequest[key] = rawPaymentRequest[key];
      }
    }

    if (propertyIsString(rawPaymentRequest, 'currencyCode')) {
      paymentRequest.currencyCode = rawPaymentRequest.currencyCode;
    }

    if (
      propertyIsString(rawPaymentRequest, 'subtotal') ||
      propertyIsNumber(rawPaymentRequest, 'subtotal')
    ) {
      const normalizedTotal = normalizeTotal(rawPaymentRequest.subtotal);

      if (normalizedTotal) {
        paymentRequest.subtotal = normalizedTotal;
      }
    }

    if (propertyIsString(rawPaymentRequest, 'merchantRequestId')) {
      paymentRequest.merchantRequestId = rawPaymentRequest.merchantRequestId;
    }

    if (propertyIsString(rawPaymentRequest, 'discount')) {
      paymentRequest.discount = rawPaymentRequest.discount;
    }

    if (propertyIsString(rawPaymentRequest, 'giftWrap')) {
      paymentRequest.giftWrap = rawPaymentRequest.giftWrap;
    }

    if (propertyIsString(rawPaymentRequest, 'misc')) {
      paymentRequest.misc = rawPaymentRequest.misc;
    }

    if (propertyIsString(rawPaymentRequest, 'shippingHandling')) {
      paymentRequest.shippingHandling = rawPaymentRequest.shippingHandling;
    }

    if (propertyIsString(rawPaymentRequest, 'tax')) {
      paymentRequest.tax = rawPaymentRequest.tax;
    }

    if (
      propertyIsNumber(rawPaymentRequest, 'total') ||
      propertyIsString(rawPaymentRequest, 'total')
    ) {
      const normalizedTotal = normalizeTotal(rawPaymentRequest.total);

      if (normalizedTotal) {
        paymentRequest.total = normalizedTotal;
      }
    }

    if (propertyIsString(rawPaymentRequest, 'orderId')) {
      paymentRequest.orderId = rawPaymentRequest.orderId;
    }

    if (propertyIsString(rawPaymentRequest, 'description')) {
      paymentRequest.description = rawPaymentRequest.description;
    }

    if (propertyIsString(rawPaymentRequest, 'promoCode')) {
      paymentRequest.promoCode = rawPaymentRequest.promoCode;
    }

    if (propertyIsObject(rawPaymentRequest, 'customData')) {
      const rawCustomData = rawPaymentRequest.customData;

      if (propertyIsArray(rawCustomData, 'nvPair')) {
        paymentRequest.customData = {
          nvPair: rawCustomData.nvPair.filter((pair): pair is NVPair => {
            if (typeof pair !== 'object' || pair === null) {
              return false;
            }

            if (
              propertyIsString(pair, 'name') &&
              (propertyIsString(pair, 'value') || propertyIsNumber(pair, 'value'))
            ) {
              return true;
            }

            return false;
          })
        };
      }
    }

    if (Object.keys(paymentRequest).length) {
      vOptions.paymentRequest = paymentRequest;
    }
  }

  if (propertyIsString(rawVOptions, 'referenceCallID')) {
    vOptions.referenceCallID = rawVOptions.referenceCallID;
  }

  if (propertyIsString(rawVOptions, 'sourceId')) {
    vOptions.sourceId = rawVOptions.sourceId;
  }

  if (propertyIsObject(rawVOptions, 'settings')) {
    const rawSettings = rawVOptions.settings;
    const settings: VSettings = {};

    if (propertyIsString(rawSettings, 'backgroundImageId')) {
      settings.backgroundImageId = rawSettings.backgroundImageId;
    }

    if (propertyIsString(rawSettings, 'countryCode')) {
      settings.countryCode = rawSettings.countryCode;
    }

    if (propertyIsString(rawSettings, 'currencyFormat')) {
      settings.currencyFormat = rawSettings.currencyFormat;
    }

    if (propertyIsString(rawSettings, 'customerSupportUrl')) {
      settings.customerSupportUrl = rawSettings.customerSupportUrl;
    }

    if (
      propertyIsString(rawSettings, 'dataLevel') &&
      (rawSettings.dataLevel === 'FULL' ||
        rawSettings.dataLevel === 'NONE' ||
        rawSettings.dataLevel === 'SUMMARY')
    ) {
      settings.dataLevel = rawSettings.dataLevel;
    }

    if (propertyIsString(rawSettings, 'displayName')) {
      settings.displayName = rawSettings.displayName;
    }

    if (
      propertyIsBoolean(rawSettings, 'enableUserDataPrefill') ||
      propertyIsBooleanString(rawSettings, 'enableUserDataPrefill')
    ) {
      settings.enableUserDataPrefill = rawSettings.enableUserDataPrefill;
    }

    if (propertyIsString(rawSettings, 'encryptionKey')) {
      settings.encryptionKey = rawSettings.encryptionKey;
    }

    if (
      propertyIsBoolean(rawSettings, 'guestCheckout') ||
      propertyIsBooleanString(rawSettings, 'guestCheckout')
    ) {
      settings.guestCheckout = rawSettings.guestCheckout;
    }

    if (propertyIsString(rawSettings, 'locale')) {
      const normalizedLocale = normalizeLocale(rawSettings.locale);
      if (normalizedLocale) {
        settings.locale = normalizedLocale;
      }
    }

    if (propertyIsString(rawSettings, 'logoUrl')) {
      settings.logoUrl = rawSettings.logoUrl;
    }

    if (propertyIsString(rawSettings, 'newUserWelcomeMessage')) {
      settings.newUserWelcomeMessage = rawSettings.newUserWelcomeMessage;
    }

    if (propertyIsString(rawSettings, 'newUserWelcomeMessageDescription')) {
      settings.newUserWelcomeMessageDescription = rawSettings.newUserWelcomeMessageDescription;
    }

    if (propertyIsString(rawSettings, 'returningUserWelcomeMessage')) {
      settings.returningUserWelcomeMessage = rawSettings.returningUserWelcomeMessage;
    }

    if (propertyIsObject(rawSettings, 'payment')) {
      const rawPaymentSettings = rawSettings.payment;
      settings.payment = {};

      if (propertyIsArray(rawPaymentSettings, 'cardBrands')) {
        settings.payment.cardBrands = rawPaymentSettings.cardBrands.filter(
          (brand): brand is string => typeof brand === 'string'
        );
      }

      if (
        propertyIsBoolean(rawPaymentSettings, 'acceptCanadianVisaDebit') ||
        propertyIsBooleanString(rawPaymentSettings, 'acceptCanadianVisaDebit')
      ) {
        settings.payment.acceptCanadianVisaDebit = rawPaymentSettings.acceptCanadianVisaDebit;
      }

      if (propertyIsArray(rawPaymentSettings, 'billingCountries')) {
        const filteredBillingCountries = rawPaymentSettings.billingCountries.filter(
          (countryCode): countryCode is string => typeof countryCode === 'string'
        );

        if (filteredBillingCountries.length) {
          settings.payment.billingCountries = filteredBillingCountries;
        }
      }
    }

    if (propertyIsObject(rawSettings, 'review')) {
      const rawReviewSettings = rawSettings.review;
      settings.review = {};

      if (propertyIsString(rawReviewSettings, 'message')) {
        settings.review.message = rawReviewSettings.message;
      }

      if (propertyIsString(rawReviewSettings, 'buttonAction')) {
        const uppercaseButtonAction = rawReviewSettings.buttonAction.toUpperCase();

        if (uppercaseButtonAction === 'CONTINUE' || uppercaseButtonAction === 'PAY') {
          settings.review.buttonAction = uppercaseButtonAction;
        }
      }
    }

    if (propertyIsObject(rawSettings, 'shipping')) {
      const rawShippingSettings = rawSettings.shipping;
      settings.shipping = {};

      if (propertyIsArray(rawShippingSettings, 'acceptedRegions')) {
        settings.shipping.acceptedRegions = rawShippingSettings.acceptedRegions.filter(
          (countryCode): countryCode is string => typeof countryCode === 'string'
        );
      }

      if (
        propertyIsBoolean(rawShippingSettings, 'collectShipping') ||
        propertyIsBooleanString(rawShippingSettings, 'collectShipping')
      ) {
        settings.shipping.collectShipping = rawShippingSettings.collectShipping;
      }
    }

    if (propertyIsObject(rawSettings, 'threeDSSetup')) {
      const rawThreeDSSettings = rawSettings.threeDSSetup;
      settings.threeDSSetup = {};

      if (
        propertyIsBoolean(rawThreeDSSettings, 'threeDSActive') ||
        propertyIsBooleanString(rawThreeDSSettings, 'threeDSActive')
      ) {
        settings.threeDSSetup.threeDSActive = rawThreeDSSettings.threeDSActive;
      }

      if (
        propertyIsBoolean(rawThreeDSSettings, 'threeDSSuppressChallenge') ||
        propertyIsBooleanString(rawThreeDSSettings, 'threeDSSuppressChallenge')
      ) {
        settings.threeDSSetup.threeDSSuppressChallenge =
          rawThreeDSSettings.threeDSSuppressChallenge;
      }
    }

    if (propertyIsObject(rawSettings, 'tokenizationSetup')) {
      const rawTokenizationSetup = rawSettings.tokenizationSetup;
      const tokenizationSetup: TokenizationSetup = {};

      if (
        propertyIsBoolean(rawTokenizationSetup, 'enableTokenization') ||
        propertyIsBooleanString(rawTokenizationSetup, 'enableTokenization')
      ) {
        tokenizationSetup.enableTokenization = rawTokenizationSetup.enableTokenization;
      }

      if (propertyIsString(rawTokenizationSetup, 'tokenCryptogramType')) {
        tokenizationSetup.tokenCryptogramType = rawTokenizationSetup.tokenCryptogramType;
      }

      if (Object.keys(tokenizationSetup).length) {
        settings.tokenizationSetup = tokenizationSetup;
      }
    }

    if (propertyIsString(rawSettings, 'websiteUrl')) {
      settings.websiteUrl = rawSettings.websiteUrl;
    }

    if (propertyIsString(rawSettings, 'widgetStyle')) {
      const rawWidgetStyle = rawSettings.widgetStyle.toUpperCase();
      if (
        rawWidgetStyle === 'OVERLAY' ||
        rawWidgetStyle === 'LIGHTBOX' ||
        rawWidgetStyle === 'POPUP'
      ) {
        settings.widgetStyle = rawWidgetStyle;
      }
    }

    if (Object.keys(settings).length) {
      vOptions.settings = settings;
    }
  }

  if (propertyIsObject(rawVOptions, 'sdkLiteMerchantAppInfo')) {
    const rawSDKLiteAppInfo = rawVOptions.sdkLiteMerchantAppInfo;

    if (
      propertyIsString(rawSDKLiteAppInfo, 'scheme') &&
      propertyIsString(rawSDKLiteAppInfo, 'package')
    )
      vOptions.sdkLiteMerchantAppInfo = {
        package: rawSDKLiteAppInfo.package,
        scheme: rawSDKLiteAppInfo.scheme
      };
  }

  return vOptions;
}
