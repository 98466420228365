import {
  DESTROY_NONEXISTANT_ELEMENT,
  UPDATE_NONEXISTANT_ELEMENT
} from 'common/constants/error-messages';
import { RXO_WINDOW_URL } from 'common/constants/window';
import { isEdge, isSafari } from 'common/utils/browser';
import { mark, marks } from 'common/utils/instrumentation';
import logger from 'common/utils/logger';
import { createQueryString } from 'common/utils/query-string';
import {
  IFRAME_SANDBOX_VALUE,
  MODAL_HEIGHT,
  MODAL_TAKEOVER_MIN_HEIGHT,
  MODAL_TAKEOVER_MIN_WIDTH,
  MODAL_WIDTH,
  iframeIdMap
} from 'sdk/constants/iframe';
import debounce from 'sdk/utils/debounce';
import { getViewportHeight, getViewportWidth } from 'sdk/utils/viewport';
import { QueryObj, WindowId } from 'types/sdk';
import { addBodyClass, injectGlobalStyle, removeBodyClass, removeGlobalStyle } from './style';

export type DestroyIframeOptions = {
  id: WindowId;
  type: 'hidden' | 'modal' | 'popover' | 'preload';
  noscript: boolean;
};

export type InjectIframeOptions = {
  attributes?: { [key: string]: string };
  autofocus?: boolean;
  id: WindowId;
  noscript?: boolean;
  onLoad?: () => void;
  getPopoverTarget?: () => HTMLElement | null;
  query?: QueryObj;
  sandbox?: boolean;
  src: string;
  styles?: Partial<CSSStyleDeclaration>;
  type: 'hidden' | 'modal' | 'popover' | 'preload';
};

export type ReloadIframeOptions = {
  id: WindowId;
};

type PositionIframeOptions = {
  iframe: HTMLIFrameElement;
  target: HTMLElement;
};

type RevealPreloadedIframeOptions = {
  autofocus?: boolean;
  id: WindowId;
};

let ariaHiddenSiblings: Array<Element> = [];

function getIframeWrapperId(id: WindowId) {
  // Note: system-sdk hardcodes the legacy "vcop-src" wrapper id and uses it to
  // mutate the style of the lightbox background.
  if (id === 'checkout') {
    return 'vcop-src';
  }

  return `${iframeIdMap[id]}-wrapper`;
}

export function getIframeEl(id: WindowId) {
  return document.getElementById(iframeIdMap[id]) as HTMLIFrameElement;
}

export function getIframeWrapperEl(id: WindowId) {
  const wrapperId = getIframeWrapperId(id);
  return document.getElementById(wrapperId) as HTMLDivElement;
}

function createIframe(options: InjectIframeOptions) {
  const iframe = document.createElement('iframe');
  const query = options.query ? createQueryString(options.query) : '';
  iframe.id = iframeIdMap[options.id];
  iframe.src = `${options.src}${query}`;
  iframe.frameBorder = '0';
  iframe.tabIndex = -1;

  // options.sandbox is based on metadata feature flag "vdcp.addSandboxAttr"
  if (options.sandbox || options.id !== 'checkout') {
    iframe.setAttribute('sandbox', IFRAME_SANDBOX_VALUE);
  }

  return iframe;
}

export function positionIframe({ iframe, target }: PositionIframeOptions) {
  if (!target) {
    return;
  }

  const viewportWidth = getViewportWidth();

  if (viewportWidth <= 480) {
    iframe.style.left = '0';
    iframe.style.top = '0';
    iframe.style.width = '100%';
    iframe.style.minHeight = '100%';
    iframe.style.marginLeft = '0';
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pageRect = document.querySelector('html')!.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    const iframeRect = iframe.getBoundingClientRect();

    /**
     * Since getBoundingClientRect returns positions relative to the viewport,
     * subtract the page's position to get the element's absolute position on the page.
     */
    const targetAbsolutePosition = {
      left: targetRect.left - pageRect.left,
      top: targetRect.top - pageRect.top
    };

    iframe.style.width = '306px';
    iframe.style.minHeight = '';

    /**
     * Position the Learn More box above the Learn More link if there is space available.
     * If not, position it beneath the Learn More link.
     */
    if (targetRect.top > iframeRect.height) {
      const top = targetAbsolutePosition.top - iframeRect.height - 5;
      iframe.style.top = `${top}px`;
    } else {
      const top = targetAbsolutePosition.top + targetRect.height + 5;
      iframe.style.top = `${top}px`;
    }

    /**
     * Center the Learn More box above/below the Learn More link.
     * If part of the learn more box would go off the screen, center it in the window instead.
     */
    const left = targetAbsolutePosition.left - iframeRect.width / 2 + targetRect.width / 2;

    if (left + iframeRect.width > window.innerWidth || left < 0) {
      iframe.style.marginLeft = '-153px';
      iframe.style.left = '50%';
    } else {
      iframe.style.marginLeft = '';
      iframe.style.left = `${left}px`;
    }
  }
}

type ResizeModalOptions = {
  iframe?: HTMLIFrameElement;
};

export function resizeModal({ iframe }: ResizeModalOptions) {
  if (iframe) {
    const viewportWidth = getViewportWidth();
    const viewportHeight = getViewportHeight();

    if (viewportWidth <= MODAL_TAKEOVER_MIN_WIDTH) {
      if (iframe.style.width !== '100%') {
        iframe.style.width = '100%';
      }
    } else if (iframe.style.width !== MODAL_WIDTH) {
      iframe.style.width = MODAL_WIDTH;
    }

    if (viewportHeight < MODAL_TAKEOVER_MIN_HEIGHT || viewportWidth <= MODAL_TAKEOVER_MIN_WIDTH) {
      if (iframe.style.height !== '100%') {
        iframe.style.height = '100%';
      }
    } else if (iframe.style.height !== MODAL_HEIGHT) {
      iframe.style.height = MODAL_HEIGHT;
    }
  }
}

/**
  * This code looks pretty sketchy, but it implements a known technique for
    getting screen reader support for modals. It finds all siblings of the given
    iframe and puts aria-hidden="true" on them. This technique is called out in
    the wai-aria docs on dialogs and modals (search for "aria-hidden"):
    https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html

  * Original implementation and explanation:
    https://stash.trusted.visa.com:7990/projects/THINWALLET/repos/checkout-widget/pull-requests/9133/overview
    https://jira.trusted.visa.com/browse/VCO-7701
  */
function getIframeSiblings(iframe: Node) {
  const siblings = [];

  // We know that iframe.parentNode will always exist.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  let currentSibling = iframe.parentNode!.firstChild as Element;

  while (currentSibling) {
    const nodeName = currentSibling.nodeName.toLowerCase();

    if (
      // not a text node
      currentSibling.nodeType !== 3 &&
      // not a comment node
      currentSibling.nodeType !== 8 &&
      nodeName !== 'script' &&
      currentSibling !== iframe &&
      currentSibling.getAttribute('aria-hidden') !== 'true'
    ) {
      siblings.push(currentSibling);
    }

    currentSibling = currentSibling.nextSibling as Element;
  }

  return siblings;
}

function focusIframe(iframe: HTMLElement) {
  ariaHiddenSiblings = getIframeSiblings(iframe);

  ariaHiddenSiblings.forEach(sibling => {
    sibling.setAttribute('aria-hidden', 'true');
  });
}

function unfocusIframe() {
  ariaHiddenSiblings.forEach(sibling => {
    sibling.removeAttribute('aria-hidden');
  });

  ariaHiddenSiblings = [];
}

// Note: only RXO has the preload feature
export function revealPreloadedIframe(options: RevealPreloadedIframeOptions) {
  const iframeId = iframeIdMap[options.id];

  injectGlobalStyle(
    'rxo-open',
    // VME-17819 -- use both class and ID in selector to supercede Starbucks
    // CSS: '#VmeIframe iframe { width: 1px; height: 1px; }'
    `
      #${iframeId}.${iframeId} {
        height: 100%; /*height: 100vh;*/
        width: 100%;
        width: 100vw;
      }

      body.rxo-open {
        overflow: hidden;
        position: fixed;
        left: 0;
        right: 0;
      }

      body.rxo-ios-android {
        position: fixed;
        width: 100%;
        height: 100%;
      }
    `
  );

  addBodyClass('rxo-open');

  const iframe = getIframeEl(options.id);

  iframe.style.position = 'fixed';
  iframe.style.display = 'block';
  iframe.style.top = '0';
  iframe.style.left = '0';
  iframe.style.height = '100%';
  iframe.style.width = '100%';
  iframe.style.zIndex = '1000000';
  iframe.removeAttribute('role');

  if (options.autofocus) {
    const wrapper = iframe.parentNode as HTMLElement;
    setTimeout(() => focusIframe(wrapper), 100);
  }
}

const iframeResizeListenerMap: Partial<Record<WindowId, EventListener>> = {};

export function injectIframe(options: InjectIframeOptions) {
  const iframeId = iframeIdMap[options.id];
  mark(`${marks.createIframeStart}${iframeId}`);

  const iframe = createIframe(options);
  const { onLoad } = options;

  iframe.addEventListener('load', () => {
    if (options.id === 'checkout') {
      // VCO-12252 - MS Edge and Safari flickers when setting the iframe.src.
      // turn off iframe when setting iframe.src, turn on iframe when loaded.
      iframe.style.visibility = 'visible';
    }

    mark(`${marks.createIframeEnd}${iframeId}`);

    if (onLoad) {
      onLoad();
    }
  });

  const isRXO = options.src.indexOf(RXO_WINDOW_URL) !== -1;

  // VCO-12252 - MS Edge and Safari flickers when setting the iframe.src.
  // turn off iframe when setting iframe.src, turn on iframe when loaded.
  if (options.id === 'checkout' && (isEdge() || isSafari())) {
    iframe.style.visibility = 'hidden';
  }

  if (options.type === 'hidden' || options.type === 'preload') {
    iframe.style.height = '0';
    iframe.style.width = '0';
    iframe.style.display = 'none';
    iframe.style.border = 'none';
    iframe.tabIndex = -1;
    iframe.setAttribute('role', 'presentation');
  } else if (options.type === 'modal') {
    const viewportHeight = getViewportHeight();
    const viewportWidth = getViewportWidth();

    if (isRXO) {
      iframe.style.position = 'fixed';
      iframe.style.top = '0';
      iframe.style.left = '0';
      iframe.style.height = '100%';
      iframe.style.width = '100%';
      iframe.style.zIndex = '1000000';
    } else {
      iframe.style.height =
        viewportHeight < MODAL_TAKEOVER_MIN_HEIGHT || viewportWidth <= MODAL_TAKEOVER_MIN_WIDTH
          ? '100%'
          : MODAL_HEIGHT;
      iframe.style.width = viewportWidth <= MODAL_TAKEOVER_MIN_WIDTH ? '100%' : MODAL_WIDTH;
      iframe.style.display = 'block';
    }

    injectGlobalStyle(
      'v-no-scroll',
      `
        body.vxo-no-scroll {
          overflow: hidden !important;
          width: 100%;
          height:100%;
        }
      `
    );

    addBodyClass('vxo-no-scroll');
  }

  if (options.styles) {
    for (const style in options.styles) {
      iframe.style[style] = options.styles[style] as string;
    }
  }

  if (options.attributes) {
    for (const attribute in options.attributes) {
      iframe.setAttribute(attribute, options.attributes[attribute]);
    }
  }

  if (options.noscript) {
    const noscript = document.createElement('noscript');
    noscript.id = getIframeWrapperId('thm');
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
  } else if (options.type === 'hidden') {
    document.body.appendChild(iframe);
  } else {
    // Non-hidden UI iframes are wrapped in a <div> to avoid an apparently
    // common CSS hack (e.g. Adorama, Crate and Barrel, CB2 integrations).
    // body>iframe { /* hide elements */ }
    const wrapper = document.createElement('div');
    wrapper.id = getIframeWrapperId(options.id);

    if (options.type === 'modal' && !isRXO) {
      wrapper.style.position = 'fixed';
      wrapper.style.top = '0';
      wrapper.style.left = '0';
      wrapper.style.display = 'flex';
      wrapper.style.flexDirection = 'row';
      wrapper.style.alignItems = 'center';
      wrapper.style.justifyContent = 'center';
      wrapper.style.height = '100%';
      wrapper.style.width = '100%';
      wrapper.style.zIndex = '1000000';
    }

    wrapper.appendChild(iframe);
    document.body.appendChild(wrapper);
  }

  const { getPopoverTarget } = options;

  if (options.type === 'popover' && getPopoverTarget) {
    const targetEl = getPopoverTarget();

    if (targetEl) {
      const popoverResizeListener = debounce(() => {
        positionIframe({
          iframe,
          target: targetEl
        });
      }, 30);

      iframeResizeListenerMap[options.id] = popoverResizeListener;
      window.addEventListener('resize', popoverResizeListener);

      positionIframe({
        iframe,
        target: targetEl
      });
    }
  }

  if (options.type === 'modal' && !isRXO) {
    const modalResizeListener = debounce(() => {
      resizeModal({ iframe });
    }, 30);

    iframeResizeListenerMap[options.id] = modalResizeListener;
    window.addEventListener('resize', modalResizeListener);
  }

  if (options.autofocus && options.type !== 'preload') {
    const focusEl = options.type === 'hidden' ? iframe : (iframe.parentNode as HTMLElement);

    setTimeout(() => focusIframe(focusEl), 100);
  }

  return iframe;
}

export function destroyIframe(options: DestroyIframeOptions) {
  const iframe = document.getElementById(iframeIdMap[options.id]) as HTMLIFrameElement | null;

  const resizeListener = iframeResizeListenerMap[options.id];

  if (typeof resizeListener === 'function') {
    window.removeEventListener('resize', resizeListener);
  }

  if (options.type === 'modal') {
    removeGlobalStyle('rxo-open');
    removeBodyClass('rxo-open');
    removeGlobalStyle('v-no-scroll');
    removeBodyClass('vxo-no-scroll');
  }

  if (iframe) {
    unfocusIframe();

    if (options.type === 'hidden' && !options.noscript) {
      document.body.removeChild(iframe);
    } else {
      // If iframe is wrapped in either a <noscript> or <div>, remove the wrapper.
      iframe.parentNode?.parentNode?.removeChild(iframe.parentNode);
    }
  } else {
    logger.error(DESTROY_NONEXISTANT_ELEMENT);
  }
}

export function reloadIframe(options: ReloadIframeOptions) {
  const iframe = document.getElementById(iframeIdMap[options.id]) as HTMLIFrameElement | null;

  if (iframe) {
    iframe.src = iframe.src;
  } else {
    logger.error(UPDATE_NONEXISTANT_ELEMENT);
  }
}
