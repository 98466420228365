import { Action } from 'types/redux';
import { WindowId } from 'types/sdk';

export type WindowConfig = {
  noscript: boolean;
  type: 'hidden' | 'modal' | 'popover' | 'popup';
};

export type WindowState = {
  [key in WindowId]?: WindowConfig | null;
};

const defaultWindowState: WindowState = {};

export default function windowReducer(
  state: WindowState = defaultWindowState,
  action: Action
): WindowState {
  switch (action.type) {
    case '@@window/OPEN_WINDOW':
      return {
        ...state,
        [action.data.id]: {
          noscript: action.data.type === 'popup' ? false : action.data.noscript,
          type: action.data.type
        }
      };
    case '@@window/CLOSE_WINDOW':
      return {
        ...state,
        [action.data.id]: null
      };
    default:
      return state;
  }
}
