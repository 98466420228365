/**
 * Fullscreen_API is pretty much deprecated.  Vestigial API exists in modern browsers, but all methods are unreliable.
 * Fallback is manual measure as reported by browsers; fork based on known/tested parameters
 * @returns {boolean}
 */
export default function isFullscreen(win: Window): boolean {
  const { height, width } = win.screen;
  const { innerHeight, innerWidth, outerHeight, outerWidth } = win;

  if (
    // true for OSX safari + chrome, Win10 chrome, iOS safari
    (height === outerHeight && width === outerWidth) ||
    // true for Win10 edge - strangely, `outerWidth` is a smaller integer than `innerWidth`
    (height === innerHeight && width === innerWidth)
  ) {
    return true;
  }

  return false;
}
