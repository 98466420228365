// correlationId/visitId need to be accessed outside of the context of Redux, so
// we make them available via a singleton pattern. CorrelationId is also briefly
// stored in localStorage during Hybrid 6.x flows.

import createCorrelationId from 'common/utils/correlation-id';
import getIntegrationType from 'common/utils/get-integration-type';
import { localStorage } from 'common/utils/storage';

type IDMap = {
  correlationId: string | null;
  visitId: string | null;
};

// For 6.x native integrations, the SDK is loaded twice, once in the button
// webview, and again in the checkout webview after the button is clicked. This
// means we need to share a correlationId across these views. When the config
// iframe is loaded in the button webview, we message correlationId back to the
// SDK and save it to localStorage. In the checkout webview, we get the
// correlationId from localStorage and clear it on checkout success. (original
// story: VCO-5621)
const integrationType = getIntegrationType();

let correlationId = null;

if (integrationType !== 'web') {
  // In SDK Lite 7+ integrations, the native layer sets correlationId in
  // localStorage when the button webview is created. This is required to make
  // logEvent calls prior to config load.
  const storedCorrelationId = localStorage.getItem('correlationId');

  // validate stored correlationId to avoid injections
  if (storedCorrelationId) {
    const isValid = storedCorrelationId.search(/^[a-zA-Z0-9-_=\/\+]+$/i) !== -1;
    if (isValid) {
      correlationId = storedCorrelationId;
    }

    // remove correlationId from localStorage immediately
    localStorage.removeItem('correlationId');
  }

  // For 6.x native integrations, correlationId is always generated client-side.
  if (!correlationId) {
    correlationId = createCorrelationId();
  }
}

const ids: IDMap = {
  correlationId,
  visitId: null
};

export function get<K extends keyof IDMap>(name: K): IDMap[K] {
  return ids[name];
}

export function set(name: keyof IDMap, id: string) {
  ids[name] = id;
  return id;
}
