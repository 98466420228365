import { CardNetwork } from 'types/enums';
import dedupe from './dedupe';

function isAllowedNetwork(network: string): network is CardNetwork {
  return (
    network === 'VISA' || network === 'MASTERCARD' || network === 'AMEX' || network === 'DISCOVER'
  );
}

// orderCardBrandsParam will look something like "visa,mc, Amex"
export default function getButtonOrderedCardBrands(orderedCardBrandsParam: string): CardNetwork[] {
  const buttonBrandOrder = orderedCardBrandsParam.split(',').map(brand => {
    const normalizedBrand = brand.trim().toUpperCase();
    return normalizedBrand === 'MC' ? 'MASTERCARD' : normalizedBrand;
  });

  return dedupe(buttonBrandOrder.filter(isAllowedNetwork));
}
