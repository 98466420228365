/* eslint-disable no-console */

import {
  CheckoutComplete as HybridCheckoutComplete,
  CheckoutLaunchData as HybridCheckoutLaunchData,
  MerchantData as HybridMerchantData,
  CheckoutResultPayload as HybridResultPayload
} from '@visa/hybrid-plugin';
import { HYBRID_INTERFACE_MISSING } from 'common/constants/error-messages';
import { ROOT_DOMAIN, SDKLITE_LOADER_URL, SDKLITE_URL } from 'common/constants/window';
import HybridSDK from 'common/hybrid';
import { Cookies, setCookie } from 'common/utils/cookies';
import { IntegrationType } from 'common/utils/get-integration-type';
import getMerchantUrl from 'common/utils/get-merchant-url';
import hybridLogger from 'common/utils/hybrid-logger';
import logger from 'common/utils/logger';
import { popupIdMap } from 'sdk/constants/iframe';
import { getVButtons } from 'sdk/dom/button';
import { getIframeEl } from 'sdk/dom/iframe';
import { b64Encode } from 'sdk/utils/base64';
import { HybridAPIVersion } from 'sdk/utils/get-hybrid-api-version';
import logEvent from 'sdk/utils/log-event';
import { CardBrand } from 'types/enums';
import { CheckoutCancel, CheckoutComplete } from 'types/messages/incoming';
import { MobileButtonType, PrefillCallback, VOptions } from 'types/sdk';
import { THMLaunchData } from 'types/thm';

let clickListener: () => void;

type SyncedCookies = {
  _art: Cookies['_art'];
};

type SyncCookiesOptions = {
  cookies: SyncedCookies;
};

export function syncCookies({ cookies }: SyncCookiesOptions) {
  const cookieKeys = Object.keys(cookies) as Array<keyof typeof cookies>;

  for (const key of cookieKeys) {
    setCookie(key, cookies[key], 365);
  }
}

export type HybridButtonEvents = {
  checkoutReady(opts: { alreadyLaunched: boolean }): void;
  launchCheckout(): void;
  onNativeButtonClick(): void;
  openLoader(): void;
  showNativeSpinner(): void;
};

export type HybridButtonInitOptions = {
  apiVersion: HybridAPIVersion;
  correlationId: string;
  events: HybridButtonEvents;
  integrationType: IntegrationType;
  isHybridManualCheckout: boolean;
  onSyncCookies(cookies: SyncedCookies): boolean;
  vOptions: VOptions;
};

export function initButton({
  apiVersion,
  correlationId,
  events: { checkoutReady, launchCheckout, onNativeButtonClick, openLoader, showNativeSpinner },
  integrationType,
  isHybridManualCheckout,
  onSyncCookies,
  vOptions
}: HybridButtonInitOptions) {
  hybridLogger('hybridInit');

  // If the button is clicked before configureVisaCheckout is completed,
  // show the loading screen.
  const handleEarlyClick = function handleEarlyClick() {
    showNativeSpinner();

    if (apiVersion === '7.x') {
      launchCheckout();
    }
  };

  getVButtons().forEach(buttonEl => {
    if (!buttonEl) {
      return;
    }

    buttonEl.removeEventListener('click', clickListener);
    buttonEl.addEventListener('click', handleEarlyClick);
    clickListener = handleEarlyClick;
  });

  // SDKLite handlers will be called only for webviews.
  if (integrationType === 'sdk-lite-cross-app') {
    return;
  }

  HybridSDK.onSyncCookies(onSyncCookies);

  const initParams = {
    pi: vOptions
  };

  const stringInitParams = b64Encode(JSON.stringify(initParams));

  if (apiVersion === '7.x') {
    hybridLogger('hybridInit:isSRCSupported ', { isSRCSupported: true });
    HybridSDK.registerMessagePaths({
      checkoutUrl: `${ROOT_DOMAIN}/checkout-widget/sdklite-crossapp?init=${stringInitParams}`,
      enableTransitionSpinner: true
    });

    /**
      * Note: native code shims window.open. This call effectively preloads the
        popup window in the background prior to click.
      */
    openLoader();

    hybridLogger('hybridInit:Webview Created', {
      name: popupIdMap.checkout,
      url: SDKLITE_LOADER_URL
    });

    if (integrationType === 'sdk-lite') {
      /**
        * SDK Lite will call this callback when the button is clicked. Hybrid
          Plugin uses normal DOM events.
        */
      HybridSDK.onNativeButtonClick().then(shouldLaunch => {
        if (!shouldLaunch) {
          return;
        }

        onNativeButtonClick();
      });
    }

    logEvent({
      correlationId,
      message: {
        event: 'hybrid-init',
        payload: {},
        window_name: 'SRC Webview',
        window_url: SDKLITE_LOADER_URL
      },
      url: SDKLITE_LOADER_URL
    });
  } else if (apiVersion === '6.x') {
    let checkoutUrl = `${SDKLITE_URL}?init=${stringInitParams}`;

    if (isHybridManualCheckout) {
      checkoutUrl += '&manualCheckout';
    }

    HybridSDK.configureVisaCheckout(checkoutUrl)
      .then(alreadyLaunched => {
        checkoutReady({ alreadyLaunched });
      })
      .catch(console.error);

    logEvent({
      correlationId,
      message: {
        event: 'hybrid-init',
        payload: initParams,
        window_name: undefined,
        window_url: checkoutUrl
      },
      url: checkoutUrl
    });
  }
}

export type LaunchNativeSpinnerOptions = {
  cancelCheckout(): void;
};

export function launchNativeSpinner({ cancelCheckout }: LaunchNativeSpinnerOptions) {
  const domain = getMerchantUrl();

  HybridSDK.showLoading(domain)
    .then(loaderWasDismissed => {
      // Loader can either "complete" or be dismissed.
      if (loaderWasDismissed) {
        cancelCheckout();
      }
    })
    .catch(console.error);
}

export type HybridCheckoutReadyEvents = {
  launchCheckout(): void;
};

export type HybridCheckoutReadyOptions = {
  alreadyLaunched?: boolean;
  correlationId: string;
  events: HybridCheckoutReadyEvents;
};

export function checkoutReady({
  alreadyLaunched = false,
  correlationId,
  events: { launchCheckout }
}: HybridCheckoutReadyOptions) {
  getVButtons().forEach(buttonEl => {
    buttonEl.removeEventListener('click', clickListener);
    buttonEl.addEventListener('click', launchCheckout);
    clickListener = launchCheckout;
  });

  if (alreadyLaunched) {
    launchCheckout();
    return;
  }

  // This is the "manual checkout" feature in Native SDK. This will be
  // invoked by merchants who generate a custom checkout button and don't
  // use our supplied checkout button. If this method resolves, launch
  // checkout without waiting for button click.
  HybridSDK.onManualCheckout().then(() => {
    logEvent({
      correlationId,
      message: {
        description: 'SDKLite Button Manual Checkout Click',
        event: 'visa.sdklite.manual-checkout-click'
      }
    });

    getVButtons().forEach(buttonEl => {
      buttonEl.removeEventListener('click', launchCheckout);
    });

    launchCheckout();
  });

  HybridSDK.notifyReady();
}

type SDKLiteLoadButtonOptions = {
  cardBrands: Array<CardBrand> | null;
  integrationType: IntegrationType;
  mobileButtonType: MobileButtonType;
};

export function loadButton({
  cardBrands,
  integrationType,
  mobileButtonType
}: SDKLiteLoadButtonOptions) {
  // window.loadMobileButton only exists for SDK Lite integrations using mobile-button.html
  if (integrationType !== 'hybrid-plugin' && window.loadMobileButton) {
    window.loadMobileButton(mobileButtonType, cardBrands);
  }
}

export type ProfileDeviceOptions = {
  onProfile(thmData: THMLaunchData): void;
};

export function profileDevice({ onProfile }: ProfileDeviceOptions) {
  HybridSDK.onProfileDevice(onProfile);
}

export type DismissCheckoutOptions = {
  onDismiss(): void;
};

export function dismissCheckout({ onDismiss }: DismissCheckoutOptions) {
  HybridSDK.dismiss().then(() => {
    hybridLogger('HybridSDK.dismiss for Hybrid Plugin');
    onDismiss();
  });
}

export type HybridCheckoutLaunchEvents = {
  onCheckoutComplete(result: CheckoutComplete): void;
  onPrefillRequest: PrefillCallback;
};

type HybridCheckoutLaunchOptions = {
  apiVersion: HybridAPIVersion;
  launchData: HybridCheckoutLaunchData;
  events: HybridCheckoutLaunchEvents;
};

export function launchHybridCheckout({
  apiVersion,
  events: { onCheckoutComplete, onPrefillRequest },
  launchData: { merchantConfig, sdkParams, srcConfigs, vInitRequest }
}: HybridCheckoutLaunchOptions) {
  hybridLogger('hybridLaunch');

  const parentUrl = `${getMerchantUrl()}/checkout-widget/vcop`;

  const merchantData = {
    merchantConfig,
    sdkParams,
    srcConfigs,
    vInitRequest: {
      ...vInitRequest,
      intialVinit: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...vInitRequest.intialVinit!,
        parentUrl
      },
      parentUrl
    }
  };

  if (apiVersion === '7.x') {
    HybridSDK.srcLaunch();
  } else {
    HybridSDK.launchVisaCheckout(merchantData)
      .then(onCheckoutComplete)
      .catch(e => {
        onCheckoutComplete({
          data: {},
          error: e,
          type: 'error'
        });
      });
  }

  HybridSDK.onPrefillRequest(onPrefillRequest);
}

// Registers a hybrid message handler that will relay messages from the native
// layer to any handlers within the checkout iframe.
export function setupCheckoutMessageForwarding() {
  if (!window.VisaCheckoutSDK) {
    return;
  }

  window.VisaCheckoutSDK.InboundHybridHandlers?.messageHandlers?.push(message => {
    const iframe = getIframeEl('checkout');

    if (!iframe) {
      return;
    }

    const messageHandlers =
      iframe.contentWindow?.VisaCheckoutSDK?.InboundHybridHandlers?.messageHandlers;

    if (!messageHandlers) {
      logger.error(HYBRID_INTERFACE_MISSING);
      return;
    }

    messageHandlers.forEach(messageHandler => messageHandler(message));
  });
}

type SDKLiteCheckoutInitOptions = {
  onRender: () => Promise<HybridCheckoutComplete>;
};

export function initCheckout({ onRender }: SDKLiteCheckoutInitOptions) {
  /**
    * Checkout is "resolved" as soon as either the native back button is pressed,
      or when a CheckoutComplete message is received and the onRender method
      resolves.
    */
  HybridSDK.onRender(() => {
    const backPressedPromise: Promise<CheckoutCancel> = HybridSDK.onBackPressed().then(() => ({
      data: {
        callid: null,
        error: {}
      },
      type: 'cancel'
    }));

    return Promise.race([backPressedPromise, onRender()]);
  });
}

export function registerDefaultStrings() {
  HybridSDK.registerDefaultStrings();
}

export function registerMerchantData(data: HybridMerchantData) {
  HybridSDK.registerMerchantData(data);
}

export type SendResultOptions = {
  packageName?: string;
  payload: HybridResultPayload;
  scheme?: string;
};

export function sendResultToNative({ packageName, payload, scheme }: SendResultOptions) {
  const _btoaResult = b64Encode(JSON.stringify(payload));
  const nativeAppIntentUrl = `intent://result?VisaCheckoutResult=${_btoaResult}#Intent;scheme=${scheme};package=${packageName};end`;
  window.location.href = nativeAppIntentUrl;
}

export type UpdatePaymentCompleteOptions = {
  paymentRequestChanged: boolean;
};

export function updatePaymentComplete({ paymentRequestChanged }: UpdatePaymentCompleteOptions) {
  HybridSDK.updatePaymentComplete(!paymentRequestChanged);
}
