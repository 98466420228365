import getIntegrationType, { IntegrationType } from 'common/utils/get-integration-type';
import * as IDStorage from 'common/utils/id-storage';
import { DEFAULT_CARD_BRAND_ORDER } from 'sdk/constants/button';
import { CheckoutFlow } from 'types/api/merchant-config';
import { CardNetwork } from 'types/enums';
import { CheckoutComplete } from 'types/messages/incoming';
import { Action } from 'types/redux';
import { PrefillData, VOptions } from 'types/sdk';

export type CheckoutState = {
  buttonCardBrandOrder: CardNetwork[];
  correlationId: string | null;
  initTimestamp: number;
  integrationType: IntegrationType;
  isVSB: boolean;
  isVSBButtonless: boolean;
  finalOptimizelyFlow: CheckoutFlow | null;
  prefill: {
    status: 'unconsented' | 'requested' | 'received';
    data: PrefillData | null;
  };
  response: CheckoutComplete | null;
  sessionId: string | null;
  startPath: 'signIn' | 'signUp' | null;
  status: 'pre-init' | 'ready' | 'queued' | 'active' | 'locked';
  vInit: VOptions | null;
};

// For 6.x native integrations, the SDK is loaded twice, once in the button
// webview, and again in the checkout webview after the button is clicked. This
// means we need to share a correlationId across these views. When the config
// iframe is loaded in the button webview, we message correlationId back to the
// SDK and save it to localStorage. In the checkout webview, we get the
// correlationId from localStorage and clear it on checkout success. (original
// story: VCO-5621)
const integrationType = getIntegrationType();

export const defaultCheckoutState: CheckoutState = {
  // Default order for card networks on the SRC button. Merchant may override
  // this by adding a `?buttonCardBrandOrder=<networks>` param to button.png.
  buttonCardBrandOrder: DEFAULT_CARD_BRAND_ORDER,
  correlationId: IDStorage.get('correlationId'),
  finalOptimizelyFlow: null,
  initTimestamp: 0,
  integrationType,
  isVSB: false,
  isVSBButtonless: false,
  prefill: {
    data: null,
    status: 'unconsented'
  },
  response: null,
  sessionId: null,
  startPath: null,
  status: 'pre-init',
  vInit: null
};

export default function checkoutReducer(
  state: CheckoutState = defaultCheckoutState,
  action: Action
): CheckoutState {
  switch (action.type) {
    case '@@sdk/PREFILL_DATA_REQUESTED':
      return {
        ...state,
        prefill: {
          data: null,
          status: 'requested'
        }
      };
    case '@@sdk/PREFILL_DATA_RECEIVED':
      return {
        ...state,
        prefill: {
          data: action.data,
          status: 'received'
        }
      };
    case '@@sdk/CHECKOUT_VSB_INIT_BUTTONLESS':
      return {
        ...state,
        isVSBButtonless: action.data
      };
    case '@@sdk/CHECKOUT_VSB_INIT':
      return {
        ...state,
        isVSB: true
      };
    case '@@sdk/CHECKOUT_SETUP':
      return {
        ...state,
        initTimestamp: state.initTimestamp === 0 ? Date.now() : state.initTimestamp,
        // If V.init() is called after checkout launch, keep status as "active".
        status: state.status === 'active' ? 'active' : 'ready',
        vInit: action.data
      };
    case '@@sdk/CHECKOUT_QUEUED':
      return {
        ...state,
        response: null,
        startPath: action.data ? action.data.startPath : null,
        status: 'queued'
      };
    case '@@hybrid/CHECKOUT_QUEUED':
      return {
        ...state,
        response: null,
        startPath: null,
        status: 'queued'
      };
    case '@@config/INVALID_INIT_OPTIONS':
    case '@@config/MERCHANT_CONFIG_FAILURE':
    case '@@sdk/UNSUPPORTED_BROWSER':
    case '@@sdk/UNSUPPORTED_HYBRID_VERSION':
    case '@@sdk/CHECKOUT_DISABLED_BY_FORM_FACTOR':
      return {
        ...state,
        status: 'locked'
      };
    case '@@sdk/CHECKOUT_STARTED':
    case '@@hybrid/CHECKOUT_STARTED':
      return {
        ...state,
        status: 'active'
      };
    case '@@sdk/CHECKOUT_COMPLETE':
      const nextState: CheckoutState = {
        ...state,
        response: action.message,
        status: 'ready'
      };

      if (action.sessionId) {
        nextState.sessionId = action.sessionId;
      }

      if (action.correlationId) {
        nextState.correlationId = action.correlationId;
      }

      return nextState;
    case '@@config/CONFIG_DATA_RECEIVED':
      return {
        ...state,
        correlationId: action.data.correlationId,
        sessionId: action.data.srcEnvConfig.sessionId
      };
    case '@@button/CARD_BRAND_ORDER':
      return {
        ...state,
        buttonCardBrandOrder: action.data
      };
    case '@@sdk/OPTIMIZELY_FLOW':
      return {
        ...state,
        finalOptimizelyFlow: action.data
      };
    default:
      return state;
  }
}
