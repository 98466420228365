import * as Message from 'common/utils/postmessage';
import { getWindowState } from 'sdk/selectors';
import { Action, Middleware, State } from 'types/redux';

/**
 * Handles outgoing postmessages.
 */
const postmessageMiddleware: Middleware = store => next => (action: Action) => {
  const ret = next(action);
  let state: State;

  switch (action.type) {
    case '@@window/SEND_GTM_EVENT':
      Message.send({ event: action.data, type: 'visa:gtm:event' }, 'gtm');
      break;
    case '@@window/SYNC_GTM_DATALAYER':
      Message.send({ event: action.data, type: 'visa:gtm:sync' }, 'gtm');

      break;
    case '@@window/SEND_POSTMESSAGE':
      state = store.getState();

      if (typeof action.target === 'string') {
        if (action.target === 'self' || getWindowState(state, action.target)) {
          Message.send(action.message, action.target);
        }
      } else {
        action.target.forEach(targetId => {
          if (getWindowState(state, targetId)) {
            Message.send(action.message, targetId);
          }
        });
      }
      break;
  }

  return ret;
};

export default postmessageMiddleware;
