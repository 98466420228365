import { State } from 'types/redux';
import { ScriptId } from 'types/sdk';

export const getIsScriptInjected = (state: State, id: ScriptId) =>
  Boolean(state.script[id].injected);

export const getScriptLoadedTimestamp = (state: State, id: ScriptId) =>
  state.script[id].loaded ?? 0;

export const getIsScriptLoaded = (state: State, id: ScriptId) => Boolean(state.script[id].loaded);
