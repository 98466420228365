/**
 * Performs a base64 encoding on a string
 * Original source (Solution #4): https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 */
export function b64Encode(str: string) {
  return window.btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(Number(`0x${p1}`))
    )
  );
}

/**
 * Performs a base64 decoding of a string
 */
export function b64Decode(str: string) {
  return decodeURIComponent(
    window
      .atob(str)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
}
