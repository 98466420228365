/* START GENAI@GHCOPILOT */
import { createSelector } from 'reselect';
import { CountryCode } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

import { convertToBoolean, convertToBooleanString } from 'common/utils/boolean-converter';
import { getBrowserProtocol, isBrowserInList, isChromeOnIOS, isSafari } from 'common/utils/browser';
import canLegallyDropCookies from 'common/utils/can-legally-drop-cookies';
import getMerchantUrl from 'common/utils/get-merchant-url';
import isEUMerchantOrUser from 'common/utils/isEUMerchantOrUser';
import cleanObject from 'sdk/utils/clean-object';
import filterCardBrands from 'sdk/utils/filter-card-brands';
import normalizeLocale from 'sdk/utils/normalize-locale';
import {
  CheckoutFlow,
  MappedMetadataProperties,
  MerchantConfig,
  MerchantConfigTiming,
  MerchantConfigWithoutUndefined,
  MerchantResponse,
  MerchantResponseWithoutUndefined,
  MetadataProperty,
  NormalizedMerchantConfig,
  SRCNetworksFutMetadata,
  SRCNetworksMetadata,
  WidgetStyle
} from 'types/api/merchant-config';
import { ConfigData, GTMProps, SDKParams, SRCConfigs, SRCEnvConfig } from 'types/config';
import { BooleanString, BrandingType, CardNetwork, VSBIntegrationType } from 'types/enums';
import { IncomingTrafficSource } from 'types/gtm';
import { CheckoutComplete } from 'types/messages/incoming';
import { OrchestrationConfig } from 'types/orchestration';
import { State } from 'types/redux';
import {
  CheckoutPayloadByType,
  FilteredVInitRequest,
  MobileButtonType,
  PaymentOptions,
  ShippingOptions,
  VInitRequest
} from 'types/sdk';
import {
  getApiKey,
  getButtonCardBrandOrder,
  getCheckoutResponse,
  getCheckoutStatus,
  getCorrelationId,
  getEncryptionKey,
  getExternalProfileId,
  getFinalOptimizelyFlow,
  getInitTimestamp,
  getIntegrationType,
  getIsCheckoutActive,
  getIsCheckoutQueued,
  getIsVSBButtonless,
  getIsVSBInit,
  getStartPath,
  getVInit,
  getVInitBackgroundImageId,
  getVInitCardBrands,
  getVInitCollectShipping,
  getVInitCurrencyCode,
  getVInitLocaleCountry,
  getVInitProperty,
  getVInitSetting,
  getVInitSettings,
  getVInitTotal
} from './checkout';
import {
  getHybridAPIVersion,
  getIsHybrid,
  getIsHybridManualCheckout,
  getMobileEnvironment
} from './hybrid';
import { getOrchestrationStatus } from './orchestration';
import { getScriptLoadedTimestamp } from './script';

export const getConfigData = (state: State) => state.config.configData;
export const getConfigDataProperty = <K extends keyof ConfigData>(state: State, key: K) => {
  const configData = getConfigData(state);

  if (!configData) {
    return null;
  }

  const configProperty = configData[key];

  return configProperty === undefined ? null : configProperty;
};

export const getSRCEnvConfigProperty = <K extends keyof SRCEnvConfig>(state: State, key: K) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const srcEnvConfig = getConfigDataProperty(state, 'srcEnvConfig')!;
  return srcEnvConfig[key];
};

export const getMerchantResponse = (state: State) => state.config.merchantConfigResponse;

export const getMerchantConfig = (state: State): NormalizedMerchantConfig | null => {
  const merchantResponse = getMerchantResponse(state);

  if (!merchantResponse) {
    return null;
  }

  const rawMerchantConfig = merchantResponse.merchantConfig;

  // Unfortunately, SDK v1 mutates the billingConstraints and shippingConstraints objects in
  // merchantConfig. Since that behavior is expected for RXO, and we don't want
  // to mess with that codebase anymore, we are matching that behavior here.
  return {
    ...rawMerchantConfig,
    billingConstraints: {
      ...rawMerchantConfig.billingConstraints,
      acceptedRegions: rawMerchantConfig.billingConstraints?.acceptedRegions.region ?? []
    },
    shippingConstraints: {
      ...rawMerchantConfig.shippingConstraints,
      acceptedRegions: rawMerchantConfig.shippingConstraints?.acceptedRegions.region ?? []
    }
  };
};

export const getMerchantConfigTiming = (state: State): MerchantConfigTiming =>
  state.config?.configTiming ?? { configTiming: '', end: 0, start: 0 };

export const getMerchantResponseProperty = <K extends keyof MerchantResponse>(
  state: State,
  key: K
) => {
  const merchantResponse = getMerchantResponse(state);

  if (!merchantResponse) {
    return null;
  }

  const merchantResponseProperty = merchantResponse[key];

  return merchantResponseProperty === undefined
    ? null
    : (merchantResponseProperty as MerchantResponseWithoutUndefined[K]);
};

export const getMerchantConfigProperty = <K extends keyof MerchantConfig>(state: State, key: K) => {
  const merchantConfig = getMerchantConfig(state);

  if (!merchantConfig) {
    return null;
  }

  const merchantConfigProperty = merchantConfig[key];

  return merchantConfigProperty === undefined
    ? null
    : (merchantConfigProperty as MerchantConfigWithoutUndefined[K]);
};

export const getCustomBackgroundUrl = createSelector(
  getMerchantResponse,
  getVInitBackgroundImageId,
  (merchantConfigResponse, vInitImageId) => {
    if (!merchantConfigResponse) {
      return null;
    }

    const { backgroundImageId: defaultImageId, backgroundImages } =
      merchantConfigResponse.merchantConfig;

    if (!backgroundImages) {
      return null;
    }

    const defaultImageData = backgroundImages.find(image => image.imageId === defaultImageId);

    const defaultImageUrl = defaultImageData ? defaultImageData.imageURL : null;

    if (!vInitImageId) {
      return defaultImageUrl;
    }

    if (vInitImageId.toLowerCase() === 'none') {
      return null;
    }

    const vInitImageData = backgroundImages.find(image => image.imageId === vInitImageId);

    const vInitImageUrl = vInitImageData ? vInitImageData.imageURL : null;

    return vInitImageUrl || defaultImageUrl || null;
  }
);

export const getRememberMeType = (state: State) => {
  if (!state.config.configData) {
    return 'legacy';
  }

  return state.config.configData.rememberMeType;
};

export const getVdcpAbTestingWeightedProbabilityValue = (state: State) => {
  if (!state.config.configData) {
    return 0;
  }

  return state.config.configData.vdcpAbTestingWeightedProbabilityValue || 0;
};

export const getIsInternalIp = (state: State) => {
  if (!state.config.configData) {
    return false;
  }

  return state.config.configData.isInternalIp;
};

export const getIsRXOPopupEnabled = (state: State) => {
  if (!state.config.configData) {
    return false;
  }

  return state.config.configData.rxoPopup;
};

// isCAuthEnabled is a cookie dropped during VMORC flows
export const getIsCAuthEnabled = (state: State) => {
  if (!state.config.configData) {
    return false;
  }

  return state.config.configData.isCAuthEnabled;
};

export const getIsSplunkLoggingEnabled = (state: State) => {
  if (!state.config.configData) {
    return true; // Splunk logging should be on by default
  }

  return state.config.configData.isSplunkLoggingEnabled;
};

export const getIsSRCFlowEnabled = (state: State) => {
  return convertToBoolean(getSRCEnvConfigProperty(state, 'isSRCflowEnabled'));
};

export const getAllowCustomBranding = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return false;
  }

  return state.config.merchantConfigResponse.merchantConfig.allowCustomBranding;
};

export const getBillingConstraints = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return null;
  }

  return state.config.merchantConfigResponse.merchantConfig.billingConstraints;
};

export const getShippingConstraints = (state: State) => {
  return state.config.merchantConfigResponse?.merchantConfig.shippingConstraints ?? null;
};

export const getDisableSVGButton = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return false;
  }

  return state.config.merchantConfigResponse.disableSVGbutton;
};

export const getDisableSVGButtonAnimation = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return false;
  }

  return state.config.merchantConfigResponse.disableSVGbuttonAnimation;
};

export const getPaymentConstraints = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return null;
  }

  return state.config.merchantConfigResponse.merchantConfig.paymentConstraints;
};

// SDK v1 overrides vInit.settings.displayName with merchantConfig.logo.displayName
// https://stash.trusted.visa.com:7990/projects/THINWALLET/repos/checkout-widget/pull-requests/4906
export const getDisplayName = (state: State) => {
  const vInitDisplayName = getVInitSetting(state, 'displayName');
  const merchantConfigLogo = getMerchantConfigProperty(state, 'logo');
  return vInitDisplayName ?? merchantConfigLogo?.displayName ?? null;
};

// SDK v1 overrides vInit.settings.logoUrl with merchantConfig.logo.url
// https://stash.trusted.visa.com:7990/projects/THINWALLET/repos/checkout-widget/pull-requests/4906
export const getLogoUrl = (state: State) => {
  const vInitLogoUrl = getVInitSetting(state, 'logoUrl');
  const merchantConfigLogo = getMerchantConfigProperty(state, 'logo');
  return merchantConfigLogo?.url ?? vInitLogoUrl;
};

export const getWidgetStyle = (state: State): WidgetStyle => {
  const vInitWidgetStyle = getVInitSetting(state, 'widgetStyle');
  const merchantConfigWidgetStyle = getMerchantConfigProperty(state, 'widgetStyle');
  return vInitWidgetStyle ?? merchantConfigWidgetStyle ?? 'OVERLAY';
};

export const getGTMUserType = (state: State) =>
  getConfigDataProperty(state, 'gtmUserType') ?? 'Unrecognized';

export const getAllowSRC = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return true;
  }

  return state.config.merchantConfigResponse.allowSRC;
};

export const getAllowSRCInterop = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return true;
  }

  return state.config.merchantConfigResponse.allowSRCInterop;
};

export const getSRCEnabledNetworks = createSelector(
  (state: State) => getMerchantResponseProperty(state, 'srcInfo'),
  srcInfo => srcInfo?.sdks?.map(sdk => sdk.cardBrand) ?? []
);

export const getAllowEnrollment = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return true;
  }

  return state.config.merchantConfigResponse.allowEnrollment;
};

export const getButtonTypeForGTM = createSelector(
  getIntegrationType,
  getIsHybridManualCheckout,
  getDisableSVGButton,
  (state: State) => getConfigDataProperty(state, 'isRemembered') || false,
  (integrationType, isHybridManualCheckout, disableSVGbutton, isRemembered) => {
    if (integrationType === 'sdk-lite') {
      return isHybridManualCheckout ? 'custom' : 'regular';
    }

    const fileType = disableSVGbutton ? 'PNG' : 'SVG';
    const cardArt = isRemembered ? 'CARD_ART' : 'NO_CARD_ART';
    return `${fileType}_STATIC_${cardArt}`;
  }
);

export const getIsGuestCheckout = (state: State) => {
  const vInitGuestCheckout = getVInitSetting(state, 'guestCheckout');

  if (vInitGuestCheckout !== null) {
    return convertToBoolean(vInitGuestCheckout);
  }

  return state.config.merchantConfigResponse?.merchantConfig.guestCheckout ?? false;
};

export const getEnableUserDataPrefill = (state: State): boolean | BooleanString | null => {
  const merchantConfigPrefillSetting = getMerchantConfigProperty(state, 'enableUserDataPrefill');

  /*
   * US55923 - V.init override for field 'enableUserDataPrefill' is considered
   * only when the 'enableUserDataPrefill' is turned ON in the merchant profile.
   */
  if (!merchantConfigPrefillSetting) {
    return false;
  }

  const vInitPrefillSetting = getVInitSetting(state, 'enableUserDataPrefill');
  return vInitPrefillSetting === null ? null : vInitPrefillSetting;
};

export const getIncomingTrafficeSource = (state: State): IncomingTrafficSource => {
  const integrationType = getIntegrationType(state);

  switch (integrationType) {
    case 'hybrid-plugin':
      return 'hybridplugin';
    case 'sdk-lite':
      return getHybridAPIVersion(state) === '7.x' ? 'sdklite-enhanced' : 'sdklite';
    case 'sdk-lite-cross-app':
      return 'sdklite-cross-app';
  }

  const mobileEnvironment = getMobileEnvironment(state);

  switch (mobileEnvironment) {
    case 'uiwebview':
      return 'hybrid-uiwebview';
    case 'webview':
      return 'hybrid';
  }

  return 'web';
};

export const getSRCSupportedBrowsers = (state: State) => {
  const supportedBrowsers = getSRCEnvConfigProperty(state, 'supportedBrowsers');
  return supportedBrowsers ? supportedBrowsers.split(',') : [];
};

export const getSRCSupportedCountries = (state: State) => {
  const supportedCountries = getSRCEnvConfigProperty(state, 'supportedCountries');
  return supportedCountries ? (supportedCountries.split(',') as Array<CountryCode>) : [];
};

export const getCountryAllowsFingerprint = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return false;
  }

  // Is countryAllowsFingerprint a real config property? Could not find any docs on it.
  return state.config.merchantConfigResponse.countryAllowsFingerprint || false;
};

export const getExternalClientId = (state: State) => {
  const vInitExternalClientId = getVInitProperty(state, 'externalClientId');
  const merchantConfigExternalClientId = getMerchantConfigProperty(state, 'externalClientId');

  return vInitExternalClientId || merchantConfigExternalClientId;
};

export const getMetaDataProperties = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return [];
  }

  return state.config.merchantConfigResponse.metadata.properties;
};

export const getMetadataProperty = <K extends MetadataProperty['name']>(state: State, key: K) => {
  const properties = getMetaDataProperties(state);

  for (const property of properties) {
    if (property.name === key) {
      return property.value as MappedMetadataProperties[K];
    }
  }

  return null;
};

export const getSRCNetworksMetadata = createSelector(
  (state: State) => getMerchantResponseProperty(state, 'metadata'),
  (metadata): SRCNetworksMetadata =>
    metadata?.srcNetworks ?? {
      level: 'global',
      value: 'VISA'
    }
);

export const getSRCNetworksFutMetadata = (state: State): SRCNetworksFutMetadata => {
  const metadata = getMerchantResponseProperty(state, 'metadata');

  return (
    metadata?.srcNetworksFut ?? {
      enabled: false,
      level: 'global'
    }
  );
};

export const getAllowPasswordlessEnrollment = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'rxo.allowPasswordlessEnrollment') {
      return convertToBoolean(property.value);
    }
  }

  return false;
});

export const getSRCBrandingWOWOValue = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'srcBrandingWOWO') {
      return property.value;
    }
  }

  return 'INTERNAL_ONLY';
});

export const getSRCInteropWOWOValue = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'srcInteropWOWO') {
      return property.value;
    }
  }

  return 'INTERNAL_ONLY';
});

export const getSRCBrandingEnabledCountries = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'srcBrandingEnabledCountries') {
      return property.value.split(',') as Array<CountryCode>;
    }
  }

  return [];
});

export const getSRCInteropEnabledCountries = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'srcInteropEnabledCountries') {
      return property.value.split(',') as Array<CountryCode>;
    }
  }

  return [];
});

export const getSRCInteropExperimentCountries = createSelector(
  getMetaDataProperties,
  properties => {
    for (const property of properties) {
      if (property.name === 'srcInteropExperimentCountries') {
        return property.value.split(',') as Array<CountryCode>;
      }
    }

    return [];
  }
);

export const getSRCDisabledCountries = createSelector(getMetaDataProperties, properties => {
  for (const property of properties) {
    if (property.name === 'srcDisabledCountries') {
      return property.value.split(',') as Array<CountryCode>;
    }
  }

  return [];
});

// Returns an array of card brands enabled in both merchantResponse.srcInfo.sdks
// AND merchantResponse.metadata.srcNetworks
export const getEnabledCardBrands = createSelector(
  getSRCNetworksMetadata,
  getSRCEnabledNetworks,
  (srcNetworksMetadata, srcEnabledNetworks): Array<CardNetwork> => {
    if (!srcEnabledNetworks.length || !srcNetworksMetadata.value.length) {
      return ['VISA'];
    }

    const srcNetworksMetadataValue = srcNetworksMetadata.value.trim().toUpperCase();

    if (srcNetworksMetadataValue === 'ALL') {
      return srcEnabledNetworks;
    }

    const metadataNetworks = srcNetworksMetadataValue
      .split(',')
      .map(network => network.trim() as CardNetwork);

    return metadataNetworks.filter(network => srcEnabledNetworks.indexOf(network) !== -1);
  }
);

export const getMerchantEnabledCardBrands = createSelector(
  getVInitCardBrands,
  getPaymentConstraints,
  getEnabledCardBrands,
  (vInitCardBrands, paymentConstraints, enabledBrands): CardNetwork[] => {
    // If no brands are configured in vInit or paymentConstraints, show all
    // enabled brands.
    if (!vInitCardBrands && !paymentConstraints?.paymentTypes.paymentType) {
      return enabledBrands;
    }

    const enabledVInitCardBrands = vInitCardBrands
      ? filterCardBrands(vInitCardBrands, enabledBrands)
      : [];

    if (enabledVInitCardBrands.length) {
      return enabledVInitCardBrands;
    }

    const merchantConfigCardBrands =
      paymentConstraints?.paymentTypes.paymentType?.map(paymentType => paymentType.cardBrand) ?? [];

    // Filter cardBrands configured in merchant config.
    const enabledMerchantConfigCardBrands = filterCardBrands(
      merchantConfigCardBrands,
      enabledBrands
    );

    if (enabledMerchantConfigCardBrands.length) {
      return enabledMerchantConfigCardBrands;
    }

    return ['VISA'];
  }
);

export const getCardBrandOrder = createSelector(
  getButtonCardBrandOrder,
  getMerchantEnabledCardBrands,
  (buttonCardBrandOrder, enabledBrands) => {
    return buttonCardBrandOrder.filter(brand => enabledBrands.indexOf(brand) !== -1);
  }
);

export const getSRCBrandingOverride = (state: State) => {
  if (!state.config.merchantConfigResponse) {
    return 'disabled';
  }

  return state.config.merchantConfigResponse.srcBrandingOverride;
};

export const getCurrencyFormat = (state: State) => {
  return (
    getVInitSetting(state, 'currencyFormat') ?? getMerchantConfigProperty(state, 'currencyFormat')
  );
};

export const getMerchantCountryCode = createSelector(
  (state: State) => getVInitSetting(state, 'countryCode'),
  getVInitLocaleCountry,
  (state: State) => getMerchantConfigProperty(state, 'merchantCountryCode'),
  (state: State) => getMerchantConfigProperty(state, 'partnerCountryCode'),
  (vInitCountry, vInitLocaleCountry, merchantConfigCountry, partnerCountry) => {
    return (
      vInitCountry ||
      vInitLocaleCountry ||
      merchantConfigCountry ||
      partnerCountry ||
      'US'
    ).toUpperCase() as CountryCode;
  }
);

export const getUserLocale = createSelector(
  (state: State) => getConfigDataProperty(state, 'cookieLocale'),
  state => getConfigDataProperty(state, 'browserLocale'),
  state => getVInitSetting(state, 'locale'),
  getMerchantCountryCode,
  (cookieLocale, browserLocale, vInitLocale) => {
    return normalizeLocale(cookieLocale || browserLocale || vInitLocale || 'en_US');
  }
);

export const getCheckoutFlow = createSelector(
  getFinalOptimizelyFlow,
  (state: State) => getMerchantResponseProperty(state, 'uxByFormFactor'),
  getIntegrationType,
  getMobileEnvironment,
  (finalOptimizelyFlow, uxByFormFactor, integrationType, mobileEnvironment): CheckoutFlow => {
    // If the checkout window has already been launched and an optimizely
    // decision was made, reuse the value returned from optimizely.
    if (finalOptimizelyFlow) {
      return finalOptimizelyFlow;
    }

    if (!uxByFormFactor) {
      return 'RXO';
    }

    switch (integrationType) {
      case 'hybrid-plugin':
      case 'sdk-lite':
      case 'sdk-lite-cross-app':
        return uxByFormFactor.sdkLite;
      case 'web':
        if (mobileEnvironment === 'uiwebview' || mobileEnvironment === 'webview') {
          return uxByFormFactor.hybridWeb;
        }

        return mobileEnvironment === 'unknown' ? uxByFormFactor.other : uxByFormFactor.web;
      default:
        return 'RXO';
    }
  }
);

// Determines whether to present the new SRC (or VDCP) app or RXO
export const getCheckoutAppType = createSelector(
  getCheckoutFlow,
  getIsSRCFlowEnabled,
  getSRCSupportedBrowsers,
  getSRCSupportedCountries,
  getMerchantCountryCode,
  state => getConfigDataProperty(state, 'isCAuthEnabled'),
  (
    checkoutFlow,
    isSRCFlowEnabled,
    supportedBrowsers,
    supportedCountries,
    merchantCountryCode,
    isCAuthEnabled
  ) => {
    // NOTE: Adding a iscAuthEnabled=true cookie will cause an SRC button to
    // launch RXO.
    if (isCAuthEnabled) {
      return 'RXO';
    }

    if (!isSRCFlowEnabled) {
      return 'RXO';
    }

    if (checkoutFlow === 'RXO') {
      return 'RXO';
    }

    if (supportedBrowsers.length && !isBrowserInList(supportedBrowsers)) {
      return 'RXO';
    }

    if (supportedCountries.length && supportedCountries.indexOf(merchantCountryCode) === -1) {
      return 'RXO';
    }

    return 'SRC';
  }
);

export const getGTMSRCEligibility = createSelector(
  getIsSRCFlowEnabled,
  getIsCAuthEnabled,
  getIsGuestCheckout,
  state => getConfigDataProperty(state, 'ssiStatus'),
  getIsHybrid,
  (isSRCFlowEnabled, isCAuthEnabled, isGuestCheckout, ssiStatus, isHybrid) => {
    const isSRCEligible = isSRCFlowEnabled && !isCAuthEnabled;

    if (isSRCEligible) {
      return 'SRC Eligible';
    }

    if (!isSRCFlowEnabled) {
      return 'SRC Not Eligible - Global SRC Flag Off';
    }

    if (isGuestCheckout) {
      return 'SRC Not Eligible - Guest Checkout Enabled';
    }

    if (isHybrid) {
      return 'SRC Not Eligible - Hybrid User';
    }

    if (ssiStatus === 'OPTED_IN') {
      return 'SRC Not Eligible - SSI Enabled User';
    }

    return 'SRC Not Eligible - undefined';
  }
);

export const getIsEUMerchantOrUser = createSelector(
  (state: State) => getConfigDataProperty(state, 'isEuroIp') || false,
  (state: State) => getMerchantConfigProperty(state, 'merchantCountryCode'),
  (state: State) => getConfigDataProperty(state, 'cookieCountry'),
  (isEuroIp, merchantCountryCode, userCountryCode) => {
    return isEUMerchantOrUser({
      isEuroIp,
      merchantCountryCode,
      userCountryCode
    });
  }
);

export const getPaymentOptions = (
  state: State
): PaymentOptions & { acceptCanadianVisaDebit?: BooleanString } => {
  const vInitPaymentSettings = getVInitSetting(state, 'payment');
  const billingConstraints = getBillingConstraints(state);

  if (!billingConstraints && !vInitPaymentSettings) {
    return {
      acceptCanadianVisaDebit: 'false'
    };
  }

  let billingCountries = vInitPaymentSettings?.billingCountries;
  if (!billingCountries && billingConstraints?.acceptedRegions.region) {
    billingCountries = billingConstraints?.acceptedRegions.region.map(region => region.countryCode);
  }

  return cleanObject({
    acceptCanadianVisaDebit:
      vInitPaymentSettings?.acceptCanadianVisaDebit === undefined
        ? null
        : convertToBooleanString(vInitPaymentSettings.acceptCanadianVisaDebit),
    billingCountries: billingCountries?.length ? billingCountries : null,
    cardBrands: vInitPaymentSettings?.cardBrands?.length ? vInitPaymentSettings.cardBrands : null
  });
};

export const getShippingOptions = (
  state: State
): ShippingOptions & { collectShipping: BooleanString } => {
  const vInitShippingSettings = getVInitSetting(state, 'shipping');
  const shippingConstraints = getShippingConstraints(state);

  if (!shippingConstraints && !vInitShippingSettings) {
    return {
      collectShipping: 'false'
    };
  }

  let acceptedRegions = vInitShippingSettings?.acceptedRegions;
  if (!acceptedRegions && shippingConstraints?.acceptedRegions.region) {
    acceptedRegions = shippingConstraints?.acceptedRegions.region.map(region => region.countryCode);
  }

  const collectShipping =
    vInitShippingSettings?.collectShipping === undefined
      ? shippingConstraints?.collectShipping
      : vInitShippingSettings.collectShipping;

  return cleanObject({
    acceptedRegions: acceptedRegions?.length ? acceptedRegions : null,
    collectShipping: convertToBooleanString(collectShipping ?? false)
  });
};

export const getHasConsentedToCookies = (state: State): boolean => {
  const { configData } = state.config;

  if (!configData) {
    return false;
  }

  if (!configData.hasConsented) {
    return false;
  }

  return (
    parseInt(configData.hasConsented.policyVersion, 10) === configData.cookiePolicyVersion &&
    configData.hasConsented.consent === true
  );
};

export const getCanLegallyDropCookies = createSelector(
  (state: State) => getConfigDataProperty(state, 'cookiePolicyVersion'),
  state => getConfigDataProperty(state, 'hasConsented'),
  state => getConfigDataProperty(state, 'isCookieConsentEnabled'),
  state => getConfigDataProperty(state, 'isEuroIp'),
  state => getConfigDataProperty(state, 'cookieCountry'),
  state => getMerchantConfigProperty(state, 'merchantCountryCode'),
  (
    cookiePolicyVersion,
    hasConsented,
    isCookieConsentEnabled,
    isEuroIp,
    cookieCountry,
    merchantCountryCode
  ) => {
    return canLegallyDropCookies({
      cookiePolicyVersion,
      hasConsented,
      isCookieConsentEnabled: isCookieConsentEnabled || false,
      isEuroIp: isEuroIp || false,
      merchantCountryCode,
      userCountryCode: cookieCountry
    });
  }
);

export const getShouldShowCookieBanner = (state: State): boolean => {
  const { configData } = state.config;

  if (!configData) {
    return false;
  }

  // isCookieConsentEnabled determines whether consent is required before
  // dropping cookies. Derived from application config. Currently true in all
  // environments.
  if (!configData.isCookieConsentEnabled) {
    return false;
  }

  const isEUMerchantOrUser = getIsEUMerchantOrUser(state);

  if (!isEUMerchantOrUser) {
    return false;
  }

  if (!configData.hasConsented) {
    return false;
  }

  const isPolicyVersionCurrent =
    parseInt(configData.hasConsented.policyVersion, 10) === configData.cookiePolicyVersion;

  if (configData.hasConsented.optedIn && isPolicyVersionCurrent) {
    return false;
  }

  return !isPolicyVersionCurrent || !configData.hasConsented.consent;
};

export const getGTMProps = (state: State): GTMProps => ({
  allowCustomBranding: getAllowCustomBranding(state),
  allowEnrollment: getAllowEnrollment(state),
  collectShipping: getVInitCollectShipping(state),
  currencyCode: getVInitCurrencyCode(state),
  externalClientId: getExternalClientId(state) || '',
  guestCheckout: getIsGuestCheckout(state),
  newUserWelcomeMessage: getVInitSetting(state, 'newUserWelcomeMessage') ? 'custom' : 'default',
  newUserWelcomeMessageDescription: getVInitSetting(state, 'newUserWelcomeMessageDescription')
    ? 'custom'
    : 'default',
  returningUserWelcomeMessage: getVInitSetting(state, 'returningUserWelcomeMessage')
    ? 'custom'
    : 'default',
  total: getVInitTotal(state) || '',
  widget_style: getWidgetStyle(state)
});

export const getIsSRCBranded = (state: State) => getCheckoutFlow(state) === 'SRC';

export const getBrandingType = (state: State): BrandingType =>
  getIsSRCBranded(state) ? 'SRC' : 'VCO';

export const getIsVSBIntegration = (state: State) => {
  return state.config.configData?.isVsbEnabled && getIsVSBInit(state);
};

export const getIsVSBButtonlessIntegration = (state: State) => {
  return state.config.configData?.isVsbEnabled && getIsVSBButtonless(state);
};

export const getInitialVInit = (state: State) => {
  const vOptions = getVInit(state);

  if (!vOptions) {
    return null;
  }

  return {
    ...vOptions,
    ...vOptions.settings,
    parentUrl: getMerchantUrl()
  };
};

export const getClientId = (state: State) => {
  return getVInitProperty(state, 'clientId') ?? getMerchantConfigProperty(state, 'clientId');
};

export const getMerchantIntegrationType = (state: State): VSBIntegrationType => {
  if (getIsVSBIntegration(state)) {
    if (getIsVSBButtonlessIntegration(state)) {
      return 'Integrated-VSB';
    }
    return 'Button-VSB';
  }

  return 'Standard button';
};

export const getVInitRequest = (state: State): VInitRequest => {
  const paymentOptions = getPaymentOptions(state);
  const shippingOptions = getShippingOptions(state);

  const threeDSSetupRaw = getVInitSetting(state, 'threeDSSetup');
  let threeDSSetup = null;

  if (threeDSSetupRaw) {
    const { threeDSActive, threeDSSuppressChallenge } = threeDSSetupRaw;

    threeDSSetup = {
      threeDSActive: threeDSActive ? convertToBooleanString(threeDSActive) : null,
      threeDSSuppressChallenge: threeDSSuppressChallenge
        ? convertToBooleanString(threeDSSuppressChallenge)
        : null
    };
  }

  const allowCustomBranding = getAllowCustomBranding(state);

  const customBrandingAttributes = allowCustomBranding
    ? {
        newUserWelcomeMessage: getVInitSetting(state, 'newUserWelcomeMessage'),
        newUserWelcomeMessageDescription: getVInitSetting(
          state,
          'newUserWelcomeMessageDescription'
        ),
        returningUserWelcomeMessage: getVInitSetting(state, 'returningUserWelcomeMessage')
      }
    : {};

  return {
    allowCustomBranding,
    allowEnrollment: getAllowEnrollment(state),
    allowSRC: convertToBooleanString(getAllowSRC(state)),
    allowSRCInterop: convertToBooleanString(getAllowSRCInterop(state)),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    apikey: getApiKey(state)!,
    backgroundImageId: getVInitBackgroundImageId(state),
    bannerURL: getMerchantConfigProperty(state, 'bannerURL'),
    browserLocale: getConfigDataProperty(state, 'browserLocale'),
    clientId: getClientId(state),
    countryCode: getVInitSetting(state, 'countryCode'),
    currencyFormat: getCurrencyFormat(state),
    customerSupportUrl: getVInitSetting(state, 'customerSupportUrl'),
    dataLevel: getVInitSetting(state, 'dataLevel'),
    displayName: getDisplayName(state),
    enableUserDataPrefill: getEnableUserDataPrefill(state),
    encryptionKey: getEncryptionKey(state),
    externalClientId: getExternalClientId(state),
    externalProfileId: getExternalProfileId(state),
    formFactorMerchantOverride:
      getMerchantResponseProperty(state, 'formFactorMerchantOverride') || false,
    guestCheckout: getIsGuestCheckout(state),
    intialVinit: getInitialVInit(state),
    locale: getVInitSetting(state, 'locale'),
    logoUrl: getLogoUrl(state),
    ...customBrandingAttributes,
    parentUrl: getMerchantUrl(),
    payment: paymentOptions,
    paymentRequest: getVInitProperty(state, 'paymentRequest') ?? {},
    referenceCallID: getVInitProperty(state, 'referenceCallID'),
    review: getVInitSetting(state, 'review'),
    // SDK v1 mutates vInit.settings object with data from merchant config response
    settings: {
      ...getVInitSettings(state),
      logoUrl: getLogoUrl(state) ?? undefined,
      payment: getPaymentOptions(state),
      shipping: shippingOptions,
      widgetStyle: getWidgetStyle(state)
    },
    shipping: shippingOptions,
    sourceId: getVInitProperty(state, 'sourceId'),
    srcBrandMerchantWhitelist: getMetadataProperty(state, 'srcBrandMerchantWhitelist') ?? '',
    srcBrandingEnabledCountries: getMetadataProperty(state, 'srcBrandingEnabledCountries') ?? '',
    srcBrandingOverride: getSRCBrandingOverride(state),
    srcBrandingWOWO: getSRCBrandingWOWOValue(state),
    srcDisabledCountries: getMetadataProperty(state, 'srcDisabledCountries') ?? '',
    srcInfo: getMerchantResponseProperty(state, 'srcInfo'),
    srcInteropEnabledCountries: getMetadataProperty(state, 'srcInteropEnabledCountries') ?? '',
    srcInteropExperimentCountries:
      getMetadataProperty(state, 'srcInteropExperimentCountries') ?? '',
    srcInteropWOWO: getSRCInteropWOWOValue(state),
    srcNetworks: getSRCNetworksMetadata(state),
    srcNetworksFut: getSRCNetworksFutMetadata(state),
    startPath: getStartPath(state),
    threeDSSetup,
    tokenizationSetup: getVInitSetting(state, 'tokenizationSetup'),
    uxByFormFactor: getMerchantResponseProperty(state, 'uxByFormFactor'),
    websiteUrl: getVInitSetting(state, 'websiteUrl')
  };
};

export const getVInitRequestForMerchantPayload = (state: State): FilteredVInitRequest => {
  return cleanObject({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...getVInit(state)!,
    bannerURL: getMerchantConfigProperty(state, 'bannerURL'),
    browserLocale: getConfigDataProperty(state, 'browserLocale'),
    clientId: getMerchantConfigProperty(state, 'clientId'),
    currencyFormat: getCurrencyFormat(state),
    displayName: getDisplayName(state),
    enableUserDataPrefill: getEnableUserDataPrefill(state),
    externalClientId: getExternalClientId(state),
    guestCheckout: getIsGuestCheckout(state),
    logoUrl: getLogoUrl(state),
    parentUrl: getMerchantUrl(),
    settings: {
      ...getVInitSettings(state),
      logoUrl: getLogoUrl(state),
      payment: getPaymentOptions(state),
      shipping: getShippingOptions(state),
      widgetStyle: getWidgetStyle(state)
    }
  });
};

export const getMerchantPayload = <ResponseType extends CheckoutComplete>(state: State) => {
  const checkoutResponse = getCheckoutResponse(state);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const filteredResponse = {
    ...checkoutResponse?.data,
    vInitRequest: getVInitRequestForMerchantPayload(state)
  } as CheckoutPayloadByType[ResponseType['type']];

  if (getIsVSBIntegration(state)) {
    delete filteredResponse.unbindAppInstance;
  }

  return filteredResponse;
};

export const getSDKParams = (state: State): SDKParams => ({
  allow_enrollment: getAllowEnrollment(state),
  buttonCardBrands: getCardBrandOrder(state),
  button_type: getButtonTypeForGTM(state),
  card_art_source: 'VCO',
  channel: 'merchant',
  cookieAttributes: {
    can_drop_nonessential_cookies: getCanLegallyDropCookies(state),
    cookie_banner_displayed: getShouldShowCookieBanner(state),
    cookie_policy_accepted: getHasConsentedToCookies(state),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    correlation_id: getCorrelationId(state)!,
    event: 'cookie attributes',
    xo_visitid: getConfigDataProperty(state, 'visitId')
  },
  disable_svg_button: getDisableSVGButton(state),
  disable_svg_button_animation: getDisableSVGButtonAnimation(state),
  external_client_id: getExternalClientId(state),
  flow: 'RXO',
  gtm: getGTMProps(state),
  guest_checkout: getIsGuestCheckout(state),
  incoming_traffic_source: getIncomingTrafficeSource(state),
  merchant_profile_name: getExternalProfileId(state) || 'default',
  partner_name: 'undefined',
  rememberme_type: getRememberMeType(state),
  src_eligibility: getGTMSRCEligibility(state),
  user_type: getGTMUserType(state),
  username_remembered: getConfigDataProperty(state, 'alwaysRemember') || false,
  vInitRequest: getVInitRequest(state),
  vcopParams: {
    browser_protocol: getBrowserProtocol(),
    buttonType: getButtonTypeForGTM(state),
    button_state: 'enabled',
    cardArtSource: 'VCO',
    coBrandDisplayed: false,
    coBrandSupport: false,
    finger_print_auth_enabled: getCountryAllowsFingerprint(state),
    known_user: getConfigDataProperty(state, 'isRemembered') || false,
    partnerName: 'undefined',
    stay_signed_in: getConfigDataProperty(state, 'ssiStatus') || 'undefined',
    vcopEnabledDevice: false
  }
});

export const getSRCConfigs = (state: State): SRCConfigs => ({
  buttonCardBrands: getCardBrandOrder(state) || [],
  cert: getSRCEnvConfigProperty(state, 'cert'),
  cookieCountry: getConfigDataProperty(state, 'cookieCountry'),
  cookieLocale: getConfigDataProperty(state, 'cookieLocale'),
  correlationId: getCorrelationId(state),
  countryCodeFromIp: getConfigDataProperty(state, 'countryCodeFromIp') || null,
  formFactorMerchantOverride:
    getMerchantResponseProperty(state, 'formFactorMerchantOverride') || false,
  isCookieConsentEnabled: convertToBoolean(
    getSRCEnvConfigProperty(state, 'isCookieConsentEnabled')
  ),
  isInternalTraffic: getIsInternalIp(state),
  isInteropEligible: getCheckoutFlow(state) === 'SRC',
  isSRCflowEnabled: getIsSRCFlowEnabled(state),
  isSplunkLoggingEnabled: getIsSplunkLoggingEnabled(state),
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  merchantConfig: getMerchantConfig(state)!,
  policyVersion: getSRCEnvConfigProperty(state, 'policyVersion'),
  rememberme_type: getRememberMeType(state),
  sdkParams: getSDKParams(state),
  sessionId: getSRCEnvConfigProperty(state, 'sessionId'),
  srcInfo: getMerchantResponseProperty(state, 'srcInfo'),
  supportedBrowsers: getSRCEnvConfigProperty(state, 'supportedBrowsers'),
  supportedCountries: getSRCEnvConfigProperty(state, 'supportedCountries'),
  uxByFormFactor: getMerchantResponseProperty(state, 'uxByFormFactor'),
  vInitRequest: getVInitRequest(state),
  visitId: getConfigDataProperty(state, 'visitId'),
  xoFlow: getCheckoutFlow(state)
});

export const getOrchestrationConfig = (state: State): OrchestrationConfig => ({
  ...cleanObject(getSRCConfigs(state)),
  initialBranding: getBrandingType(state),
  initializeVsbStartTime: getInitTimestamp(state),
  isTokenizationEnabled: getConfigDataProperty(state, 'isTokenizationEnabled') ?? false,
  isVsb: state.checkout.isVSB,
  isVsbButtonless: state.checkout.isVSBButtonless,
  isVsbEnabled: getConfigDataProperty(state, 'isVsbEnabled') ?? false,
  merchantIntegrationType: getMerchantIntegrationType(state),
  vsbSDKLoadEnd:
    ((getScriptLoadedTimestamp(state, 'orchestration') as number) - getInitTimestamp(state)) / 1000
});

export const getCheckoutWindowType = createSelector(
  getWidgetStyle,
  getIsRXOPopupEnabled,
  (widgetStyle, rxoPopupEnabled): 'IFRAME' | 'POPUP' => {
    const isPopupOnlyBrowser = isSafari() || isChromeOnIOS();
    // Original popup logic implemented as part of US45657. Counter-intuitively,
    // widgetStyle: 'POPUP' is used to *disable* the popup feature, not enable
    // it.
    return rxoPopupEnabled && widgetStyle !== 'POPUP' && isPopupOnlyBrowser ? 'POPUP' : 'IFRAME';
  }
);

export const getIsValidVInitCallStack = (state: State) => {
  const { vInitCallStack } = state.gtmNsmi;
  return (
    vInitCallStack.indexOf('onVisaCheckoutReady') !== -1 ||
    vInitCallStack.indexOf('onVmeReady') !== -1
  );
};

export const getVInitClickSpySelected = (state: State) => state.gtmNsmi.vInitClickSpySelected;

export const shouldPreloadApp = (state: State) => {
  return (
    !getIsCheckoutQueued(state) &&
    !getIsCheckoutActive(state) &&
    !getIsHybrid(state) &&
    getCheckoutStatus(state) !== 'locked' &&
    getMerchantResponse(state) &&
    getCheckoutWindowType(state) === 'IFRAME' &&
    getCheckoutAppType(state) === 'RXO' &&
    convertToBoolean(getMetadataProperty(state, 'rxo.preload') || false)
  );
};

export const getIsCheckoutReadyToLaunch = (state: State) => {
  if (!getMerchantResponse(state)) {
    return false;
  }

  if (getCheckoutStatus(state) === 'locked') {
    return false;
  }

  const checkoutFlow = getCheckoutFlow(state);
  // RXO does not have to wait on orchestration script
  if (checkoutFlow === 'RXO' || checkoutFlow === 'DISABLED') {
    return true;
  }

  if (getOrchestrationStatus(state) !== 'loaded') {
    return false;
  }

  return true;
};

export const getMobileButtonType = (state: State): MobileButtonType => {
  const xoFlow = getCheckoutFlow(state);
  const isSRCBranded = getIsSRCBranded(state);
  return xoFlow === 'DISABLED' ? 'disabled' : isSRCBranded ? 'isSRC' : 'isLegacy';
};
