let timerId: NodeJS.Timeout;
const msInterval = 200;

export function stop() {
  clearTimeout(timerId);
}

// detect closing of a popup window up to a given msLimit
export function start(msLimit: number, popupWindow: Window, cb: () => void) {
  function detectClose(msRemaining: number, onClose: () => void) {
    if (popupWindow.closed) {
      onClose();
      return;
    }

    if (msRemaining > 0) {
      timerId = setTimeout(() => {
        detectClose(msRemaining - msInterval, onClose);
      }, msInterval);
    }
  }

  detectClose(msLimit, cb);
}
