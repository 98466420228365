import { CHECKOUT_IN_PROGRESS } from 'common/constants/error-messages';
import { mark, marks } from 'common/utils/instrumentation';
import logger from 'common/utils/logger';
import checkoutHybrid from 'sdk/lifecycle/checkout/hybrid';
import checkoutWeb from 'sdk/lifecycle/checkout/web';
import { getIntegrationType, getIsCheckoutActive } from 'sdk/selectors';
import { Store } from 'types/redux';
import { PrefillCallback } from 'types/sdk';

export type CheckoutOptions = {
  onPrefillRequest: PrefillCallback;
  store: Store;
};

export default function checkout({ onPrefillRequest, store }: CheckoutOptions) {
  mark(marks.buttonClickEnd);

  const state = store.getState();
  const integrationType = getIntegrationType(state);

  // Button is disabled during a checkout flow. Do not initiate more than once.
  if (getIsCheckoutActive(state)) {
    logger.error(CHECKOUT_IN_PROGRESS);
    return null;
  }

  switch (integrationType) {
    case 'hybrid-plugin':
    case 'sdk-lite':
    case 'sdk-lite-cross-app':
      // For SDK Lite 6 and Hybrid Plugin 6 integrations, the SDK is loaded in
      // both the button/merchant webview (mobile-button.html for SDK Lite and
      // the merchant's third-party webview for Hybrid Plugin) AND the checkout
      // webview (sdklite.html). Checkout completion messages are relayed to the
      // merchant webview when the promise returned by HybridSDK.onRender(() =>
      // Promise<CheckoutComplete>) callback is resolved.
      return checkoutHybrid({ store });
    default:
      return checkoutWeb({ onPrefillRequest, store });
  }
}
