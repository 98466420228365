import HybridSDK, { getIsSdkLiteCrossApp } from 'common/hybrid';
import {
  isAndroidMobile,
  isAndroidWebview,
  isIOSWebview,
  isMobileSafari
} from 'common/utils/browser';

export default function getCorrelationAppName() {
  if (HybridSDK.isHybridShimConfirmed()) {
    if (isAndroidWebview()) {
      return 'ANDROID_SDK_CHECKOUT-WIDGET';
    } else if (isIOSWebview()) {
      return 'IOS_SDK_CHECKOUT-WIDGET'; // SDK: Native Mobile SDK.
    }
  } else if (isAndroidWebview()) {
    return 'ANDROID_WV_CHECKOUT-WIDGET'; // WV: WebView
  } else if (isIOSWebview()) {
    return 'IOS_WV_CHECKOUT-WIDGET'; // WV: WebView
  } else if (getIsSdkLiteCrossApp() && isAndroidMobile()) {
    return 'ANDROID_TWA_CHECKOUT-WIDGET'; // TWA : Trusted Web Activity
  } else if (isAndroidMobile()) {
    return 'ANDROID_CHECKOUT-WIDGET';
  } else if (isMobileSafari()) {
    return 'IOS_CHECKOUT-WIDGET';
  }

  return 'CHECKOUT-WIDGET';
}
