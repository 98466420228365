import { ROOT_DOMAIN } from 'common/constants/window';
import * as IDStorage from 'common/utils/id-storage';
import { getApiKey, getExternalProfileId, getIsHybrid } from 'sdk/selectors';
import { ConfigData } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function configData(store: MiddlewareAPI, message: ConfigData) {
  IDStorage.set('correlationId', message.data.correlationId);
  IDStorage.set('visitId', message.data.visitId);

  const state = store.getState();

  if (getIsHybrid(state)) {
    store.dispatch({
      data: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        apiKey: getApiKey(state)!,
        correlationId: message.data.correlationId,
        envHost: ROOT_DOMAIN,
        profile: getExternalProfileId(state)
      },
      type: '@@hybrid/REGISTER_MERCHANT_DATA'
    });
  }

  store.dispatch({
    data: message.data,
    type: '@@config/CONFIG_DATA_RECEIVED'
  });
}
