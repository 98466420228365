import HybridPlugin from '@visa/hybrid-plugin';

const HybridSDK = HybridPlugin.SDK();

// In Native Cross App integrations, isSdkLiteCrossApp is true in both
// mobile-button.html and sdk-lite.html. In Hybrid Plugin Cross App
// integrations, window.isSdkLiteCrossApp is false in the merchant webview, but
// true in sdk-lite.html.
export const getIsSdkLiteCrossApp = () =>
  Boolean(window.isSdkLiteCrossApp || HybridSDK.IS_CHROME_CUSTOM_TAB);

export default HybridSDK;
