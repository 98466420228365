import getWindowById from 'common/utils/get-window-by-id';
import hybridLogger from 'common/utils/hybrid-logger';
import { getHybridCheckoutWindowName } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';

export default function sdkliteInitComplete(store: MiddlewareAPI) {
  hybridLogger('sdklite:init:complete');

  function logWindowEvents() {
    hybridLogger('registerWebViewEvents:logWindowEvents');

    const state = store.getState();
    const checkoutWindow = getWindowById('checkout');

    store.dispatch({
      message: {
        event: 'visa.sdklite.close-checkout-webview',
        window_name: getHybridCheckoutWindowName(state),
        window_url: checkoutWindow && checkoutWindow.location.href
      },
      type: '@@hybrid/LOG_EVENT'
    });
  }

  const checkoutWindow = getWindowById('checkout');

  if (checkoutWindow) {
    checkoutWindow.onpagehide = logWindowEvents;
    checkoutWindow.onbeforeunload = logWindowEvents;
    checkoutWindow.onunload = logWindowEvents;
  }
}
