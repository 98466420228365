import { CONFIG_WINDOW_URL, GTM_WINDOW_URL } from 'common/constants/window';
import { gtmLoaded } from 'common/utils/instrumentation';
import {
  getConfigIframeQuery,
  getGTMIframeQuery,
  getHybridAPIVersion,
  getIsCheckoutReadyToLaunch,
  getIsHybridButtonClicked,
  getMerchantResponse
} from 'sdk/selectors';
import observeStore from 'sdk/utils/observe-store';
import { Store } from 'types/redux';
import { PrefillCallback, VOptions } from 'types/sdk';

type InitHybridButtonOptions = {
  onPrefillRequest: PrefillCallback;
  store: Store;
  vOptions: VOptions;
};

let unsubscribeCheckoutReady = () => {};

export default function initHybrid6Button({
  store,
  vOptions,
  onPrefillRequest
}: InitHybridButtonOptions): void {
  store.dispatch({
    data: vOptions,
    type: '@@sdk/CHECKOUT_SETUP'
  });

  const state = store.getState();

  store.dispatch({
    type: '@@hybrid/REGISTER_DEFAULT_STRINGS'
  });

  store.dispatch({
    events: { onPrefillRequest },
    type: '@@hybrid/BUTTON_INIT'
  });

  /**
    * If merchant config is already available, load the button immediately. This
      is the case for e.g. Walmart's integration where the button can be
      removed and reloaded with V.init multiple times.
    */
  if (getMerchantResponse(state)) {
    store.dispatch({
      type: '@@button/BUTTON_LOAD'
    });
  }

  store.dispatch({
    data: {
      id: 'gtm',
      onLoad: gtmLoaded,
      query: getGTMIframeQuery(),
      src: GTM_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });

  store.dispatch({
    data: {
      id: 'config',
      query: getConfigIframeQuery(state),
      src: CONFIG_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });

  const hybridAPIVersion = getHybridAPIVersion(store.getState());

  if (hybridAPIVersion === '7.x') {
    unsubscribeCheckoutReady = observeStore(store, getIsCheckoutReadyToLaunch, isReady => {
      // Note: @@hybrid/CHECKOUT_READY does not need to fire in the case of
      // an early click.
      if (isReady) {
        store.dispatch({
          alreadyLaunched: getIsHybridButtonClicked(store.getState()),
          events: {
            launchCheckout() {
              store.dispatch({
                onProfile(thmData) {
                  store.dispatch({
                    data: thmData,
                    type: '@@thm/LAUNCH'
                  });
                },
                type: '@@hybrid/PROFILE_DEVICE'
              });

              store.dispatch({
                events: { onPrefillRequest },
                type: '@@hybrid/CHECKOUT_LAUNCH'
              });

              store.dispatch({
                type: '@@hybrid/CHECKOUT_QUEUED'
              });
            }
          },
          type: '@@hybrid/CHECKOUT_READY'
        });
      }

      unsubscribeCheckoutReady();
    });
  }
}
