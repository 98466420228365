import { createSelector } from 'reselect';
import getCountryFromLocale from 'sdk/utils/country-from-locale';
import { FormFactorFlag } from 'types/api/merchant-config';
import { State } from 'types/redux';
import {
  VOptions,
  VOptionsWithoutUndefined,
  VSettings,
  VSettingsWithoutUndefined
} from 'types/sdk';
import { getMobileEnvironment } from './hybrid';

export const getButtonCardBrandOrder = (state: State) => state.checkout.buttonCardBrandOrder;
export const getFinalOptimizelyFlow = (state: State) => state.checkout.finalOptimizelyFlow;
export const getIntegrationType = (state: State) => state.checkout.integrationType;
export const getIsVSBInit = (state: State) => state.checkout.isVSB;
export const getIsVSBButtonless = (state: State) => state.checkout.isVSBButtonless;
export const getPrefillStatus = (state: State) => state.checkout.prefill.status;
export const getStartPath = (state: State) => state.checkout.startPath;
export const getCorrelationId = (state: State) => state.checkout.correlationId;
export const getSessionId = (state: State) => state.checkout.sessionId;
export const getVInit = (state: State) => state.checkout.vInit;
export const getCheckoutStatus = (state: State) => state.checkout.status;
export const getCheckoutResponse = (state: State) => state.checkout.response;
export const getInitTimestamp = (state: State) => state.checkout.initTimestamp;
export const getFormFactorFlag = createSelector(
  getIntegrationType,
  getMobileEnvironment,
  (integrationType, mobileEnvironment): FormFactorFlag => {
    switch (integrationType) {
      case 'hybrid-plugin':
        return 'HYBRIDWEB';
      case 'sdk-lite':
      case 'sdk-lite-cross-app':
        return 'SDKLITE';
      default:
        return mobileEnvironment === 'unknown' ? 'OTHER' : 'WEB';
    }
  }
);

export const getVInitProperty = <K extends keyof VOptions>(state: State, key: K) => {
  const vInit = getVInit(state);

  if (!vInit) {
    return null;
  }

  const value = vInit[key];

  return value === undefined ? null : (value as VOptionsWithoutUndefined[K]);
};

export const getVInitSettings = (state: State) => state.checkout.vInit?.settings ?? null;

export const getVInitSetting = <K extends keyof VSettings>(state: State, key: K) => {
  const vInit = getVInit(state);

  if (!vInit || !vInit.settings) {
    return null;
  }

  const settingValue = vInit.settings[key];

  return settingValue === undefined ? null : (settingValue as VSettingsWithoutUndefined[K]);
};

export const getApiKey = (state: State) => {
  return state.checkout.vInit?.apikey ?? null;
};

export const getEncryptionKey = (state: State) => {
  return (
    getVInitProperty(state, 'encryptionKey') ?? getVInitSetting(state, 'encryptionKey') ?? null
  );
};

export const getExternalProfileId = (state: State) => {
  if (!state.checkout.vInit) {
    return null;
  }

  return state.checkout.vInit.externalProfileId || null;
};

export const getVInitBackgroundImageId = (state: State) => {
  if (!state.checkout.vInit) {
    return null;
  }

  if (state.checkout.vInit.backgroundImageId) {
    return state.checkout.vInit.backgroundImageId;
  }

  return getVInitSetting(state, 'backgroundImageId');
};

export const getVInitCardBrands = createSelector(getVInit, vInit => {
  return vInit?.settings?.payment?.cardBrands ?? null;
});

export const getVInitLocaleCountry = createSelector(
  (state: State) => getVInitSetting(state, 'locale'),
  vInitLocale => getCountryFromLocale(vInitLocale, '_')
);

export const getVInitCurrencyCode = createSelector(getVInit, vInit => {
  return vInit?.paymentRequest?.currencyCode ?? null;
});

export const getVInitTotal = createSelector(getVInit, vInit => {
  return vInit?.paymentRequest?.total ?? null;
});

export const getVInitCollectShipping = createSelector(getVInit, vInit => {
  if (!vInit || !vInit.settings || !vInit.settings.shipping) {
    return true;
  }

  const { collectShipping } = vInit.settings.shipping;

  if (collectShipping === undefined) {
    return true;
  }

  return collectShipping === 'true' || collectShipping === true;
});

export const getIsCheckoutActive = (state: State) => state.checkout.status === 'active';

export const getIsCheckoutQueued = (state: State) => state.checkout.status === 'queued';
