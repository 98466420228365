/* START GENAI@GHCOPILOT */
import { CountryCode, Locale } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

import { COOKIE_PARSE_FAILED } from 'common/constants/error-messages';
import logger from 'common/utils/logger';
import { BooleanString } from 'types/enums';
import { ERMCookieValue, HasConsentedValue, RememberMeValue, SSICookieValue } from 'types/storage';

export type Cookies = {
  _art: string;
  alwaysRemember: BooleanString;
  country_preference: CountryCode;
  ermEnabled: ERMCookieValue;
  hasConsented: HasConsentedValue;
  iscAuthEnabled: BooleanString;
  locale: Locale;
  remember_me: RememberMeValue;
  ssiDeclined: string;
  ssiEnabled: SSICookieValue;
  visited: BooleanString;
};

const parseFunction = {
  ermEnabled: (value: string) => JSON.parse(decodeURIComponent(value)) as ERMCookieValue,
  hasConsented: (value: string) => JSON.parse(decodeURIComponent(value)) as HasConsentedValue,
  remember_me: (value: string) => JSON.parse(window.atob(value)) as RememberMeValue,
  ssiEnabled: (value: string) => JSON.parse(decodeURIComponent(value)) as SSICookieValue
};

type ParseableCookies = keyof typeof parseFunction;

function shouldParse(name: keyof Cookies): name is ParseableCookies {
  return Boolean(parseFunction[name as ParseableCookies]);
}

export function getCookie<K extends keyof Cookies>(name: K): Cookies[K] | null {
  const pattern = RegExp(`${name}=.[^;]*`);
  const matched = document.cookie.match(pattern);

  if (matched) {
    const cookieValue = matched[0].split('=')[1];

    if (cookieValue) {
      let parsed = cookieValue as Cookies[K];
      if (shouldParse(name)) {
        try {
          parsed = parseFunction[name](cookieValue) as Cookies[K];
        } catch (e) {
          logger.error(COOKIE_PARSE_FAILED, { name });
        }
      }

      return parsed;
    }
  }

  return null;
}

export function setCookie<K extends keyof Cookies>(name: K, value: Cookies[K], days: number): void {
  const date = new Date();
  const windowHostname = window.location && window.location.hostname;
  const hostname =
    windowHostname === 'localhost' || windowHostname === '10.0.2.2'
      ? ''
      : '.secure.checkout.visa.com';
  date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days);
  const encodedName = encodeURIComponent(name);
  const encodedValue = encodeURIComponent(String(value));

  document.cookie = `${encodedName}=${encodedValue};domain=${hostname};path=/;expires=${date.toUTCString()}`;
}
