import { createSelector } from 'reselect';
import { DEFAULT_CARD_BRAND_ORDER } from 'sdk/constants/button';
import { ButtonQuery } from 'types/sdk';
import {
  getDisableSVGButton,
  getDisableSVGButtonAnimation,
  getIsSRCBranded,
  getMerchantEnabledCardBrands
} from './config';

/**
 * Intentionally not using getCardBrandOrder selector here because it is derived
 * from state.checkout.buttonCardBrandOrder, which is linked to the first
 * button.png with an orderedCardBrands query parameter defined. Since we want
 * to support configuring orderedCardBrands independently for each button, we
 * read the query param from each button and intersect it with the value from
 * this selector while looping over the buttons in loadButton
 * (sdk/dom/button.ts).
 */
export const getCardBrandsQueryParam = createSelector(
  getIsSRCBranded,
  getMerchantEnabledCardBrands,
  (isSRCBranded, enabledBrands) => {
    if (!isSRCBranded) {
      return null;
    }

    const cardBrandOrder = DEFAULT_CARD_BRAND_ORDER.filter(
      brand => enabledBrands.indexOf(brand) !== -1
    );

    return cardBrandOrder.length ? cardBrandOrder : null;
  }
);

export const getButtonQuery = createSelector(
  getDisableSVGButton,
  getDisableSVGButtonAnimation,
  getIsSRCBranded,
  getCardBrandsQueryParam,
  (disableSVGbutton, disableSVGButtonAnimation, isSRCBranded, cardBrandsQueryParam) => {
    const query: ButtonQuery = {
      animation: !disableSVGButtonAnimation,
      legacy: !isSRCBranded,
      svg: !disableSVGbutton
    };

    if (cardBrandsQueryParam) {
      query.orderedCardBrands = cardBrandsQueryParam;
    }

    return query;
  }
);
