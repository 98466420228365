import { CONFIG_WINDOW_URL, GTM_WINDOW_URL } from 'common/constants/window';
import { gtmLoaded } from 'common/utils/instrumentation';
import { getActiveLearnMoreLink, getLocaleFromLink } from 'sdk/dom/learn-more';
import {
  getCheckoutStatus,
  getConfigIframeQuery,
  getGTMIframeQuery,
  getIsScriptLoaded,
  getLearnMoreIframeQuery,
  getLearnMoreUrl,
  getMerchantResponse,
  getOrchestrationConfig,
  getWindowState
} from 'sdk/selectors';
import { Store } from 'types/redux';
import { VOptions } from 'types/sdk';

type InitWebOptions = {
  store: Store;
  vOptions: VOptions;
};

function launchLearnMore(store: Store, e: MouseEvent | KeyboardEvent) {
  const state = store.getState();

  if (getWindowState(state, 'learn')) {
    return;
  }

  const locale = getLocaleFromLink(e.currentTarget as HTMLAnchorElement);

  store.dispatch({
    data: {
      attributes: {
        title: 'Learn more about Visa Checkout'
      },
      autofocus: true,
      getPopoverTarget: getActiveLearnMoreLink,
      id: 'learn',
      query: getLearnMoreIframeQuery(state, locale),
      src: getLearnMoreUrl(state),
      styles: {
        borderRadius: '2px',
        boxShadow: 'rgba(0, 0, 0, 0.40) 5px 5px 16px',
        height: '410px',
        position: 'absolute',
        width: '306px',
        zIndex: '999999'
      },
      type: 'popover'
    },
    type: '@@window/OPEN_WINDOW'
  });

  store.dispatch({
    data: {
      event: 'learnMore Link Click',
      event_action: 'learnMore Click',
      event_label: 'learnMore Link Click'
    },
    type: '@@window/SEND_GTM_EVENT'
  });
}

export default function initWeb({ store, vOptions }: InitWebOptions): void {
  let state = store.getState();
  const initAlreadyCalled = getCheckoutStatus(state) !== 'pre-init';

  store.dispatch({
    data: vOptions,
    type: '@@sdk/CHECKOUT_SETUP'
  });

  store.dispatch({
    type: '@@button/BUTTON_INIT'
  });

  state = store.getState();

  // If merchant config is already available, load the button immediately. This
  // is the case for e.g. Walmart's integration where the button can be
  // removed and reloaded with V.init multiple times.
  if (getMerchantResponse(state)) {
    store.dispatch({
      type: '@@button/BUTTON_LOAD'
    });

    // Re-initialize orchestration layer when vOptions are updated.
    if (getIsScriptLoaded(state, 'orchestration')) {
      store.dispatch({
        data: getOrchestrationConfig(store.getState()),
        type: '@@orchestration/INIT'
      });
    }
  }

  store.dispatch({
    events: {
      click: (e: MouseEvent) => launchLearnMore(store, e),
      enter: (e: KeyboardEvent) => launchLearnMore(store, e)
    },
    type: '@@learn/INIT'
  });

  // If V.init() has already been called, it is not necessary to re-open the
  // config/gtm iframes or set up another postmessage listener.
  if (initAlreadyCalled) {
    return;
  }

  store.dispatch({
    data: {
      id: 'gtm',
      onLoad: gtmLoaded,
      query: getGTMIframeQuery(),
      src: GTM_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });

  store.dispatch({
    data: {
      id: 'config',
      query: getConfigIframeQuery(store.getState()),
      src: CONFIG_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });
}
