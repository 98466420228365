import { getMerchantConfig, getVInitRequest } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';

export default function handleGetMerchantConfig(
  store: MiddlewareAPI
  // message: RXOGetMerchantConfig
) {
  const state = store.getState();

  store.dispatch({
    message: {
      data: {
        merchantConfig: getMerchantConfig(state),
        vInitRequest: getVInitRequest(state)
      },
      type: 'setMerchantConfig'
    },
    target: 'checkout',
    type: '@@window/SEND_POSTMESSAGE'
  });
}
