type NonNullableRecursive<O> = {
  [K in keyof O]: O[K] extends void
    ? never
    : O[K] extends Record<string, unknown>
    ? NonNullableRecursive<O[K]>
    : NonNullable<O[K]>;
};

/**
 * Returns a new object with all null and undefined values recursively removed.
 * @param {object} obj
 * @returns {object}
 */
export default function cleanObject<O extends Record<string, unknown>>(obj: O) {
  const cleanObj: Record<string, unknown> = {};

  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      continue;
    } else if (
      typeof obj[key] === 'object' &&
      Object.prototype.toString.call(obj[key]) !== '[object Array]'
    ) {
      cleanObj[key] = cleanObject(obj[key] as O);
    } else {
      cleanObj[key] = obj[key];
    }
  }

  return cleanObj as NonNullableRecursive<O>;
}
