import { Unsubscribe } from 'redux';
import { Selector } from 'reselect';
import { State, Store } from 'types/redux';

type ChangeHandler<StatePartial> = (
  currentState: StatePartial,
  previousState: StatePartial
) => void;

// If no selector is passed, call onChange with full state on every update.
export default function observeStore(store: Store, onChange: ChangeHandler<State>): Unsubscribe;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function observeStore<S extends Selector<State, any>>(
  store: Store,
  selector: S,
  onChange: ChangeHandler<ReturnType<S>>
): Unsubscribe;

// Listens for changes to a portion of state returned by a selector.
// https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
export default function observeStore(
  store: Store,
  selector: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange?: any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
  if (!onChange) {
    onChange = selector;
    selector = (state: State) => state;
  }

  let currentState: unknown = selector(store.getState());

  function handleChange() {
    const nextState = selector(store.getState());

    if (nextState !== currentState) {
      const previousState = currentState;
      currentState = nextState;
      onChange(currentState, previousState);
    }
  }

  return store.subscribe(handleChange);
}
