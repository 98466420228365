import initHybridButton from 'sdk/lifecycle/init/hybrid-button';
import initHybridCheckout from 'sdk/lifecycle/init/hybrid-checkout';
import initWeb from 'sdk/lifecycle/init/web';
import { getIntegrationType } from 'sdk/selectors';
import { getHybridAPIVersion, getHybridWebviewType } from 'sdk/selectors/hybrid';
import { Store } from 'types/redux';
import { PrefillCallback, VOptions } from 'types/sdk';

export type InitOptions = {
  store: Store;
  vOptions: VOptions;
  onPrefillRequest: PrefillCallback;
};

export default function init({ store, vOptions, onPrefillRequest }: InitOptions) {
  const state = store.getState();
  const integrationType = getIntegrationType(state);
  const isCheckoutWebview = getHybridWebviewType(state) === 'checkout';

  if (integrationType === 'web') {
    return initWeb({ store, vOptions });
  }

  const hybridAPIVersion = getHybridAPIVersion(state);

  // For SDK Lite 6 and Cross App integrations, the SDK is loaded in both the
  // button/merchant webview (mobile-button.html for SDK Lite and the
  // merchant's third-party webview for Hybrid Plugin) AND the checkout
  // webview (sdklite.html). Checkout completion messages are relayed to the
  // merchant webview when the promise returned by HybridSDK.onRender(() =>
  // Promise<CheckoutComplete>) callback is resolved.
  if (
    isCheckoutWebview &&
    (integrationType === 'sdk-lite-cross-app' || hybridAPIVersion === '6.x')
  ) {
    return initHybridCheckout({ onPrefillRequest, store, vOptions });
  }

  // For non-cross-app SDK Lite 7 integrations, the SDK is only loaded once, in the
  // button/merchant webview (mobile-button.html). The checkout webview URL
  // is the same as the web integration (rxo.jsp or vcop.jsp). Messages from
  // checkout webview are relayed to the SDK via postmessages from a native
  // bridge.
  return initHybridButton({ onPrefillRequest, store, vOptions });
}
