import { getConfigDataProperty } from 'sdk/selectors';
import { THMData, THMLaunch } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function launchTHM(store: MiddlewareAPI, message: THMData | THMLaunch) {
  store.dispatch({
    data: {
      ...message.data,
      orgId: message.data.orgId ?? getConfigDataProperty(store.getState(), 'orgId') ?? undefined
    },
    type: '@@thm/LAUNCH'
  });
}
