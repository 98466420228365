import { ORCH_SCRIPT_URL, RXO_WINDOW_URL } from 'common/constants/window';
import { getBrowserProtocol } from 'common/utils/browser';
import getMerchantUrl from 'common/utils/get-merchant-url';
import { mark, marks } from 'common/utils/instrumentation';
import {
  getAllowEnrollment,
  getApiKey,
  getButtonTypeForGTM,
  getCanLegallyDropCookies,
  getCardBrandOrder,
  getCheckoutFlow,
  getConfigDataProperty,
  getCorrelationId,
  getCountryAllowsFingerprint,
  getCustomBackgroundUrl,
  getDisableSVGButton,
  getDisableSVGButtonAnimation,
  getExternalClientId,
  getExternalProfileId,
  getGTMSRCEligibility,
  getGTMUserType,
  getHasConsentedToCookies,
  getIncomingTrafficeSource,
  getInitTimestamp,
  getIntegrationType,
  getIsGuestCheckout,
  getIsSRCBranded,
  getIsVSBButtonlessIntegration,
  getIsVSBIntegration,
  getIsValidVInitCallStack,
  getMerchantConfigProperty,
  getMerchantConfigTiming,
  getMerchantIntegrationType,
  getOrchestrationConfig,
  getRXOWindowQuery,
  getRememberMeType,
  getShouldShowCookieBanner,
  getVInitClickSpySelected,
  getVInitSetting,
  shouldPreloadApp
} from 'sdk/selectors';
import {
  getHybridWebviewType,
  getIsHybrid,
  getIsHybridCheckoutWebview
} from 'sdk/selectors/hybrid';
import getBrowserLocale from 'sdk/utils/get-browser-locale';
import isFullscreen from 'sdk/utils/is-fullscreen';
import sdkSrcSearchScriptCount from 'sdk/utils/sdk-src-search-script-count';
import vButtonCheck from 'sdk/utils/vbutton-check';
import { CheckoutFlow } from 'types/api/merchant-config';
import { MerchantConfig } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function merchantConfig(store: MiddlewareAPI, message: MerchantConfig) {
  if (message.response.status !== 200) {
    store.dispatch({
      status: message.response.status,
      type: '@@config/MERCHANT_CONFIG_FAILURE'
    });

    if (getIsVSBButtonlessIntegration(store.getState())) {
      store.dispatch({
        data: {
          error: {
            reason: 'Invalid_Request'
          }
        },
        type: '@@orchestration/VSB_INIT_COMPLETE'
      });
    }

    if (message.response.status === 401) {
      store.dispatch({
        type: '@@button/BUTTON_HIDE'
      });

      store.dispatch({
        type: '@@learn/HIDE'
      });

      store.dispatch({
        message: {
          data: {},
          error: { code: 401, message: 'Invalid API Key' },
          type: 'error'
        },
        type: '@@sdk/CHECKOUT_COMPLETE'
      });
    }

    return;
  }

  let state = store.getState();
  const isHybrid = getIsHybrid(state);

  if (isHybrid && getHybridWebviewType(state) === 'checkout') {
    store.dispatch({
      type: '@@hybrid/SETUP_MESSAGE_FORWARDING'
    });
  }

  store.dispatch({
    data: message.response.data,
    type: '@@config/MERCHANT_CONFIG_SUCCESS'
  });

  const checkoutFlow: CheckoutFlow = getCheckoutFlow(store.getState());

  if (!isHybrid && checkoutFlow === 'DISABLED') {
    store.dispatch({
      type: '@@sdk/CHECKOUT_DISABLED_BY_FORM_FACTOR'
    });

    return;
  }

  state = store.getState();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const correlationId = getCorrelationId(state)!;

  // VCO-13975 - send `flow` GTM as merchant config thrash is over
  store.dispatch({
    data: {
      correlation_id: correlationId,
      event: 'flow',
      flow: checkoutFlow,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  // VCO-13975 - Merchant Config Loaded requires `flow` CD
  const { configTiming, end, start } = getMerchantConfigTiming(state);
  store.dispatch({
    data: {
      apikey: getApiKey(store.getState()) || 'undefined',
      elapsed_time: start && end ? end - start : 'undefined',
      event: 'Visa Checkout Button State',
      event_action: 'Merchant Config Loaded',
      event_category: 'Merchant Site',
      event_label: `Merchant Config loaded ${configTiming} Button Click`,
      flow: checkoutFlow
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  const integrationType = getIntegrationType(state);
  const isHybridCheckoutWebview = getIsHybridCheckoutWebview(state);

  // Note: The checkout window in Hybrid implementations (sdk-lite.html) does
  // not contain a button.
  if (!isHybridCheckoutWebview) {
    if (integrationType === 'web') {
      store.dispatch({
        type: '@@button/BUTTON_LOAD'
      });
    } else {
      store.dispatch({
        type: '@@hybrid/BUTTON_LOAD'
      });
    }

    /**
      * Note: In the checkout window of Hybrid 7.x Cross App implementations
        (sdk-lite.html), the orchestration script is loaded immediately upon
        init. For all other integrations, orchestration is injected once merchant
        config is resolved.
      */
    if (checkoutFlow !== 'RXO' && integrationType !== 'sdk-lite-cross-app') {
      mark(marks.orchScriptLoadStart);

      // Web and Hybrid 7.x integrations only.
      store.dispatch({
        data: {
          id: 'orchestration',
          onError(e) {
            store.dispatch({
              error: e.toString(),
              type: '@@orchestration/ERROR'
            });

            const state = store.getState();

            if (getIsVSBIntegration(state)) {
              const initTimestamp = getInitTimestamp(state);

              store.dispatch({
                data: {
                  event: 'VSB_Merchant_SDK_Load',
                  event_action: 'VSB Merchant SDK Load',
                  event_category: 'VSB Merchant SDK Load',
                  event_label: 'VSB Merchant SDK Load – Unsuccessful',
                  time_to_load_vsb_sdk: (Date.now() - initTimestamp) / 1000
                },
                type: '@@window/SEND_GTM_EVENT'
              });
              if (getIsVSBButtonlessIntegration(state)) {
                store.dispatch({
                  data: { isInitCompleted: false },
                  type: '@@orchestration/VSB_INIT_COMPLETE'
                });
              }
            }
          },
          onLoad() {
            mark(marks.orchScriptLoadEnd);
            const state = store.getState();

            if (getIsVSBIntegration(state)) {
              const initTimestamp = getInitTimestamp(state);

              store.dispatch({
                data: {
                  event: 'VSB_Merchant_SDK_Load',
                  event_action: 'VSB Merchant SDK Load',
                  event_category: 'VSB Merchant SDK Load',
                  event_label: 'VSB Merchant SDK Load – Successful',
                  time_to_load_vsb_sdk: (Date.now() - initTimestamp) / 1000
                },
                type: '@@window/SEND_GTM_EVENT'
              });
            }

            store.dispatch({
              data: getOrchestrationConfig(state),
              type: '@@orchestration/INIT'
            });
          },
          src: ORCH_SCRIPT_URL
        },
        type: '@@window/INJECT_SCRIPT'
      });
    }
  }

  state = store.getState();
  const customBackgroundUrl = getCustomBackgroundUrl(state);

  if (customBackgroundUrl) {
    store.dispatch({
      data: {
        url: customBackgroundUrl
      },
      type: '@@window/PRELOAD_IMAGE'
    });
  }

  const isRemembered = getConfigDataProperty(state, 'isRemembered') || false;
  const ssiStatus = getConfigDataProperty(state, 'ssiStatus');

  store.dispatch({
    data: {
      can_drop_nonessential_cookies: getCanLegallyDropCookies(state),
      cookie_banner_displayed: getShouldShowCookieBanner(state),
      cookie_policy_accepted: getHasConsentedToCookies(state),
      correlation_id: correlationId,
      event: 'cookie attributes',
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  store.dispatch({
    data: {
      correlation_id: correlationId,
      event: 'Fullscreen',
      event_action: `Fullscreen - ${isFullscreen(window)}`,
      event_category: 'Merchant Site',
      event_label: `Fullscreen - ${isFullscreen(window)}`,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  const isSRCBranded = getIsSRCBranded(state);

  store.dispatch({
    data: {
      allow_enrollment: getAllowEnrollment(state),
      api_key: getApiKey(state),
      auth_type: 'undefined',
      browser_protocol: getBrowserProtocol(),
      button_state: 'enabled',
      button_type: getButtonTypeForGTM(state),
      card_art_source: 'VCO',
      channel: 'merchant',
      correlation_id: correlationId,
      disableSVGButton: getDisableSVGButton(state),
      disableSVGButtonAnimation: getDisableSVGButtonAnimation(state),
      disable_svg_button: getDisableSVGButton(state),
      disable_svg_button_animation: getDisableSVGButtonAnimation(state),
      event: 'Visa Checkout Button Impression',
      event_action: 'Visa Checkout Impression',
      event_category: 'Merchant Site',
      event_label: 'Visa Checkout Button Impression',
      external_client_id: getExternalClientId(state),
      finger_print_auth_enabled: getCountryAllowsFingerprint(state),
      flow: checkoutFlow,
      guest_checkout: getIsGuestCheckout(state),
      incoming_traffic_source: getIncomingTrafficeSource(state),
      knownuser: isRemembered,
      logo_order: isSRCBranded ? getCardBrandOrder(state)?.join(',') ?? '' : '',
      merchant_integration_type: getMerchantIntegrationType(state),
      merchant_profile_name: getExternalProfileId(state) || 'default',
      merchant_site: getMerchantUrl(true),
      partner_name: 'undefined',
      rememberme_type: getRememberMeType(state),
      src_eligibility: getGTMSRCEligibility(state),
      stay_signed_in: ssiStatus ? ssiStatus : 'undefined',
      user_type: getGTMUserType(state),
      username_remembered: getConfigDataProperty(state, 'alwaysRemember') || false,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  store.dispatch({
    data: {
      correlation_id: correlationId,
      event: 'User type',
      event_action: 'User type',
      event_label: `${getGTMUserType(state)} VISA`,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  store.dispatch({
    data: {
      correlation_id: correlationId,
      event: 'Remember me',
      event_action: `Remember me ${getRememberMeType(state)}`,
      event_label: `${isRemembered} VISA`,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  store.dispatch({
    data: {
      correlation_id: correlationId,
      event: 'SSI Status',
      event_action: 'SSI Status',
      event_label: `${ssiStatus} VISA`,
      xo_visitid: getConfigDataProperty(state, 'visitId')
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  state = store.getState();

  // Preload RXO in a hidden iframe if not already queued to launch
  if (shouldPreloadApp(state)) {
    store.dispatch({
      data: {
        attributes: {
          title: 'Visa Checkout'
        },
        id: 'checkout',
        onLoad: () => {
          store.dispatch({
            message: {
              data: Date.now(),
              type: 'rxo:render'
            },
            target: 'checkout',
            type: '@@window/SEND_POSTMESSAGE'
          });
        },
        query: getRXOWindowQuery(state),
        src: RXO_WINDOW_URL,
        type: 'preload'
      },
      type: '@@window/OPEN_WINDOW'
    });
  }

  state = store.getState();
  const merchantCountryCode = getMerchantConfigProperty(state, 'merchantCountryCode');
  const partnerCountryCode = getMerchantConfigProperty(state, 'partnerCountryCode');
  const countryCode = getVInitSetting(state, 'countryCode');
  const locale = getVInitSetting(state, 'locale');
  const SDK_SCRIPT_SEARCH_STR = 'checkout-widget/resources/js/integration/v1/sdk.js';
  const VISA_SDK_SCRIPT_SEARCH_STR = 'checkout-widget/resources/js/src-i-adapter/visaSdk.js';

  if (!getIsValidVInitCallStack(state)) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[1] V.init() called outside of onVisaCheckoutReady/onVmeReady',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'V.init() called outside of onVisaCheckoutReady/onVmeReady'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (self !== top) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[2] Merchant SDK in iframe',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'Merchant SDK in iframe'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (getVInitClickSpySelected(state)) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[3] V.init invoked after button click',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'V.init invoked after button click'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (!vButtonCheck()) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[9] Button missing v-button class',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'Button missing v-button class'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (!vButtonCheck(true)) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[10] Button src does not match expected URL',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'Button src does not match expected URL'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (sdkSrcSearchScriptCount(SDK_SCRIPT_SEARCH_STR) > 1) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[11] Multiple loads of Merchant SDK',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'Multiple loads of Merchant SDK'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  if (sdkSrcSearchScriptCount(VISA_SDK_SCRIPT_SEARCH_STR) > 1) {
    store.dispatch({
      data: {
        event: 'NSMI',
        event_action: '[12] Two VisaSDK initialized',
        event_category: 'Non-standard Merchant Integration',
        event_label: 'Two VisaSDK initialized'
      },
      type: '@@window/SEND_GTM_EVENT'
    });
  }

  const countryLocaleEventLabel = [
    `browser.locale: ${getBrowserLocale()}`,
    `browser.domain: ${document.domain}`,
    `Vinit.countryCode: ${countryCode}`,
    `Vinit.locale: ${locale}`,
    `merchantConfig.merchantCountryCode: ${merchantCountryCode}`,
    `merchantConfig.partnerCountryCode: ${partnerCountryCode}`
  ].join('; ');

  store.dispatch({
    data: {
      event: 'NSMI',
      event_action: '[7] Country/locale SDK data',
      event_category: 'Non-standard Merchant Integration',
      event_label: `Country/locale SDK data -- ${countryLocaleEventLabel}`
    },
    type: '@@window/SEND_GTM_EVENT'
  });
}
