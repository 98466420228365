import { isMobileSafari } from 'common/utils/browser';
import createCorrelationId from 'common/utils/correlation-id';
import hybridLogger from 'common/utils/hybrid-logger';
import * as IDStorage from 'common/utils/id-storage';
import createSessionId from 'common/utils/session-id';
import { getHybridWebviewType, getIntegrationType, getSRCEnvConfigProperty } from 'sdk/selectors';
import { SDKLiteResult } from 'types/messages/incoming';
import { Store } from 'types/redux';
import { PrefillCallback } from 'types/sdk';

export default function sdkliteResult(
  store: Store,
  message: SDKLiteResult,
  onPrefillRequest: PrefillCallback
) {
  hybridLogger(`'visa.sdklite.result' : ${JSON.stringify(message.data)}`);

  // Generate a new correlationId/sessionId on success/cancel.
  const correlationId = createCorrelationId();
  const sessionId = createSessionId(getSRCEnvConfigProperty(store.getState(), 'sessionIdPrefix'));

  store.dispatch({
    correlationId,
    message:
      message.data.type === 'payment.success'
        ? { data: message.data.result, type: 'success' }
        : { data: message.data.result, type: 'cancel' },
    sessionId,
    type: '@@sdk/CHECKOUT_COMPLETE'
  });

  IDStorage.set('correlationId', correlationId);

  // Update THM with new sessionId.
  store.dispatch({
    message: {
      data: {
        reInitSessionId: sessionId
      },
      type: 'src:thm:updateTHMIframe'
    },
    target: 'config',
    type: '@@window/SEND_POSTMESSAGE'
  });

  const state = store.getState();
  const integrationType = getIntegrationType(state);
  const isMerchantWebview = getHybridWebviewType(state) === 'merchant';

  if (integrationType !== 'web' && isMerchantWebview && !isMobileSafari()) {
    store.dispatch({
      onDismiss() {
        store.dispatch({
          events: {
            onPrefillRequest
          },
          type: '@@hybrid/BUTTON_INIT'
        });
      },
      type: '@@hybrid/DISMISS'
    });
  }
}
