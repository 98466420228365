import { CardNetwork } from 'types/enums';

export default function filterCardBrands(
  brands: string[],
  enabledBrands: CardNetwork[]
): CardNetwork[] {
  const filteredBrands: CardNetwork[] = [];

  for (const brand of brands) {
    const uppercaseBrand = brand.trim().toUpperCase() as CardNetwork;

    if (enabledBrands.indexOf(uppercaseBrand) !== -1) {
      filteredBrands.push(uppercaseBrand);
    }
  }

  return filteredBrands;
}
