import getCorrelationAppName from './get-correlation-app-name';

function generateRandomNumberString(length: number) {
  let result = '';
  for (let i = length; i > 0; --i) {
    result += Math.round(Math.random() * 9);
  }
  return result;
}

export default function create(appName = getCorrelationAppName()): string {
  //1499106833_988_47_l73d114_CHECKOUT-WIDGET
  const dateString = Date.now().toString();
  const randNum = generateRandomNumberString(2);
  const base64Hostname = window.btoa(location.hostname);
  const date1 = dateString.substring(0, 10);
  const date2 = dateString.substring(10, dateString.length);
  return `${date1}_${date2}_${randNum}_${base64Hostname}_${appName}`;
}
