export default function hasParent(el: Element | null, selector: string) {
  if (!el) {
    return false;
  }

  do {
    if (el.matches(selector)) {
      return true;
    }

    el = (el.parentElement || el.parentNode) as Element;
  } while (el !== null && el.nodeType === 1);

  return false;
}
