import HybridSDK from 'common/hybrid';
import getWindowById from 'common/utils/get-window-by-id';
import hybridLogger from 'common/utils/hybrid-logger';
import { iframeIdMap } from 'sdk/constants/iframe';
import { getHybridCheckoutWindowName, getIsNativeSpinnerActive } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';
import { JSONObject } from 'types/util';

export default function sdkliteFetchDeviceInfo(store: MiddlewareAPI) {
  hybridLogger('fetchDeviceInfo');

  HybridSDK.get('deviceInfo')
    .then(payload => {
      hybridLogger('@sdk:fetchDeviceInfo:promiseResolved');

      const finalPayload: JSONObject = {
        ...payload,
        nativeSpinnerShown: getIsNativeSpinnerActive(store.getState()),
        networkInformation: null
      };

      store.dispatch({
        message: {
          data: finalPayload || {},
          type: 'src:sdklite:fetched-device-info'
        },
        target: 'checkout',
        type: '@@window/SEND_POSTMESSAGE'
      });

      if (window.navigator) {
        const connectionType =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Property 'connection' does not exist on type 'Navigator'.
          window.navigator.connection?.type || 'navigator.connection is not available';
        const connectionSpeed =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Property 'effectiveType' does not exist on type 'NetworkInformation'.
          window.navigator.connection?.effectiveType || 'navigator.connection is not available';

        const networkInformation = {
          connectionSpeed,
          connectionType,
          isOnline: navigator.onLine
        };

        finalPayload.networkInformation = networkInformation;
      }

      const state = store.getState();
      const checkoutWindow = getWindowById('checkout');

      store.dispatch({
        message: {
          event: 'src:sdklite:fetched-device-info',
          iframeId: iframeIdMap.checkout,
          payload: finalPayload,
          window_name: getHybridCheckoutWindowName(state),
          window_url: checkoutWindow && checkoutWindow.location.href
        },
        type: '@@hybrid/LOG_EVENT'
      });
    })
    .catch(() => {
      hybridLogger('fetchDeviceInfo:promiseResolved:error');

      store.dispatch({
        message: {
          data: {},
          type: 'src:sdklite:fetched-device-info'
        },
        target: 'checkout',
        type: '@@window/SEND_POSTMESSAGE'
      });

      const state = store.getState();
      const checkoutWindow = getWindowById('checkout');

      store.dispatch({
        logLevel: 'ERROR',
        message: {
          event: 'src:sdklite:fetched-device-info',
          iframeId: iframeIdMap.checkout,
          window_name: getHybridCheckoutWindowName(state),
          window_url: checkoutWindow && checkoutWindow.location.href
        },
        status: 400,
        type: '@@hybrid/LOG_EVENT'
      });
    });
}
