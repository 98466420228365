import { HybridPlugin } from '../core/hybrid-plugin';
import { CheckoutComplete, Cookies, DeviceDataKey, JSONValue, Set, THMLaunchData } from '../types';
import { fetchCardArt } from '../utils';
import * as Version from '../utils/plugin-version';

export class HybridRXO {
  plugin = new HybridPlugin();

  clearRememberedUserData = () => {
    this.set('userInfo', null);
    this.set('refresh_token', null, { requireAuth: true });
  };

  /**
   * Gets a value from device storage with a specified key.
   * A promise with the value of the stored key will be returned.
   * If a value was set with the `requireAuth` option set to true, the
   * user will be prompted with a native biometric prompt before the value
   * can be returned.
   *
   * @param key - The key used to retrieve the value from the device.
   */
  get = (key: DeviceDataKey) => this.plugin.sendMessage({ data: key, name: 'get' });

  isHybridShimConfirmed = () => Version.API > 0;

  isLegacy = () => Version.API === 1;

  isSDKLite = () => Version.API > 1;

  isSRCSupported = () => Version.API > 2;

  /**
   * Waits for a response from the native code when the user explicitly taps
   * a "back" action. This will be used to honor that request by cleaning up
   * then completing the checkout experience with a "cancel" event.
   */
  onBackPressed = () => this.plugin.sendMessage({ name: 'onBack' });

  onRender = (callback: () => Promise<CheckoutComplete>) => this.plugin.on('render', callback);

  /**
   * Notifies the native device that card art has been updated for a user. This
   * is used for the "custom button" feature for native merchants.
   *
   * @param url The url which to fetch the latest card art. This will then be
   * converted to a base64 image data.
   */
  postCardArt = (url: string) => {
    return fetchCardArt(url)
      .then(imgData => {
        this.plugin.sendMessage({
          data: imgData,
          name: 'newCardArt'
        });
      })
      .catch(() => {});
  };

  profileDevice = (thmData: THMLaunchData) => this.plugin.forwardEvent('profileDevice', thmData);

  /**
   * Forwards a prefill request to the SDK webview. The Promise returned
   * here will either come from the hybrid merchant's V.init or from
   * a native merchant's PurchaseInfo callback.
   */
  requestPrefillData = () => this.plugin.forwardEvent('purchaseInfo.prefill');

  /**
   * Sets a value into device storage.
   *
   * @param key - The key of the value set on the device.
   * @param value - The value of the key set on the device.
   * @param options - The options used to define how the data is saved.
   */
  set = (key: DeviceDataKey, value: JSONValue, options?: Set['options']) =>
    this.plugin.sendMessage({
      data: { key, value },
      name: 'set',
      options
    });

  /**
   * Forwards a cookie to another web view in order to sync them between the
   * calling and receiving webview.
   *
   * @param cookies The cookies to sync.
   */
  syncCookies = (cookies: Cookies) =>
    this.plugin.forwardEvent('cookie', cookies).then(isLegacy => {
      if (isLegacy) {
        // Only post cardArt for legacy button, SRC button doesn't support cardArt.
        this.postCardArt('/wallet-services-web/xo/button.png?cardArtOnly=true&legacy=true');
      }
    });
}

let rxoWrapper: HybridRXO;

export default () => {
  if (!rxoWrapper) {
    rxoWrapper = new HybridRXO();
  }

  return rxoWrapper;
};
