import { Action } from 'types/redux';
import { ScriptId } from 'types/sdk';

export type ScriptState = Record<
  ScriptId,
  {
    injected: number | false;
    loaded: number | false;
  }
>;

const defaultScriptState: ScriptState = {
  orchestration: { injected: false, loaded: false },
  thm: { injected: false, loaded: false }
};

export default function scriptReducer(
  state: ScriptState = defaultScriptState,
  action: Action
): ScriptState {
  switch (action.type) {
    case '@@window/INJECT_SCRIPT':
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          injected: Date.now()
        }
      };
    case '@@window/SCRIPT_LOADED':
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          loaded: Date.now()
        }
      };
    default:
      return state;
  }
}
