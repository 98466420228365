import { JSONValue } from 'types/util';

type RequestOptions =
  | string
  | {
      body?: JSONValue;
      headers: Record<string, string>;
      method?: 'GET' | 'POST';
      url: string;
    };

type ResponseCallback = (response: {
  data: JSONValue;
  getResponseHeader(name: string): string | null;
  status: number;
}) => unknown;

export default function request(options: RequestOptions, cb?: ResponseCallback) {
  let body;
  let headers;
  let method = 'GET';
  let url;

  if (typeof options === 'string') {
    url = options;
  } else {
    body = options.body;
    method = options.method || 'GET';
    headers = options.headers;
    url = options.url;
  }

  const xhr = new XMLHttpRequest();
  xhr.open(method, url, true);
  xhr.setRequestHeader('Accept', 'application/json');
  xhr.setRequestHeader('Content-type', 'application/json');

  if (headers) {
    for (const header in headers) {
      xhr.setRequestHeader(header, headers[header]);
    }
  }

  if (cb) {
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        cb({
          data: xhr.status === 200 ? JSON.parse(xhr.responseText) : null,
          getResponseHeader: xhr.getResponseHeader.bind(xhr),
          status: xhr.status
        });
      }
    };
  }

  if (typeof body === 'undefined') {
    xhr.send();
  } else {
    xhr.send(JSON.stringify(body));
  }
}
