function generateSessionIdString() {
  if (window.crypto) {
    const randomNumbers = window.crypto.getRandomValues(new Uint8Array(7));

    return Array.from(randomNumbers, val => (val < 10 ? `0${val}` : val.toString(16)))
      .join('')
      .substring(0, 7);
  }

  return Math.random().toString(36).substring(2, 9);
}

export default function createSessionId(sessionIdPrefix: string): string {
  return `${sessionIdPrefix}${generateSessionIdString()}`;
}
