import { ROOT_DOMAIN } from 'common/constants/window';
import getIntegrationType from 'common/utils/get-integration-type';
import getWebviewType from 'common/utils/get-webview-type';
import request from 'common/utils/xhr';

export type LogEventData = {
  correlationId: string;
  logLevel?: 'ERROR' | 'INFO';
  message: string | Record<string, unknown>;
  url?: string;
  status?: 200 | 400;
};

export default function logEvent(options: LogEventData) {
  const integrationType = getIntegrationType();
  const webviewType = getWebviewType();
  // The logEvent API should ONLY be called in Hybrid Integrations in which the
  // SDK runs in a first-party Visa iframe. In this context, requests to Visa
  // endpoints are permitted. For normal web integrations and Hybrid Plugin
  // integrations in the merchant webview, calling logEvent will result in a
  // CORS error.
  if (
    integrationType === 'web' ||
    (integrationType === 'hybrid-plugin' && webviewType === 'merchant')
  ) {
    return;
  }

  const loggingEndpoint = `${ROOT_DOMAIN}/logging/logEvent`;

  request({
    body: {
      CO: 'SDKLITE',
      E: 'sdk_lite_events',
      LL: options.logLevel || 'INFO',
      MSG: typeof options.message === 'object' ? JSON.stringify(options.message) : options.message,
      O: 'SDKLITE',
      RTY: 'OUT',
      S: options.status || 200,
      T: 'E',
      TS: Date.now(),
      U: options.url || ROOT_DOMAIN
    },
    headers: {
      'X-CORRELATION-ID': options.correlationId
    },
    method: 'POST',
    url: loggingEndpoint
  });
}
