import getWindowId from 'common/utils/get-window-id';
import { mark, marks } from 'common/utils/instrumentation';
import * as Message from 'common/utils/postmessage';
import { Store } from 'types/redux';

import { PrefillCallback } from 'types/sdk';
import checkoutComplete from './handlers/checkout-complete';
import configData from './handlers/config-data';
import configGTMMetrics from './handlers/config-metrics';
import getMerchantConfig from './handlers/get-merchant-config';
import closeLearn from './handlers/learn-close';
import learnFitContent from './handlers/learn-fit-content';
import launchFromLearn from './handlers/learn-launch-checkout';
import merchantConfig from './handlers/merchant-config';
import popupOverlayText from './handlers/popup-overlay-text';
import prefillConsentAccepted from './handlers/prefill-consent';
import pushGTMData from './handlers/push-gtm-data';
import rxoABDataRequest from './handlers/rxo-ab-data-request';
import rxoApplyStyles from './handlers/rxo-apply-styles';
import sdkParamsRequest from './handlers/sdk-params-request';
import sdkParamsUpdate from './handlers/sdk-params-update';
import sdkliteAppReady from './handlers/sdklite-app-ready';
import sdkliteBackPressed from './handlers/sdklite-back-pressed';
import sdkliteFetchDeviceInfo from './handlers/sdklite-fetch-device-info';
import sdkliteFetchUserInfo from './handlers/sdklite-fetch-user-info';
import sdkliteInitComplete from './handlers/sdklite-init-complete';
import sdkliteResult from './handlers/sdklite-result';
import sdkliteSyncCookies from './handlers/sdklite-sync-cookies';
import sdkliteUpdatePaymentInfo from './handlers/sdklite-update-payment-info';
import srcOptimizelyDecision from './handlers/src-optimizely-decision';
import thmLaunch from './handlers/thm-launch';
import thmResponse from './handlers/thm-response';
import updateLocaleCookie from './handlers/update-locale-cookie';
/**
 * Handles incoming postmessages.
 */
const postmessageEffects = (store: Store, onPrefillRequest: PrefillCallback) => {
  Message.listen((e, message) => {
    mark(`${marks.pmRecv}${message.type}`);

    store.dispatch({
      message,
      source: getWindowId(e.source),
      type: '@@window/POSTMESSAGE_RECEIVED'
    });

    switch (message.type) {
      // from config iframe
      case 'visa:config:config_data':
        return configData(store, message);
      case 'visa:config:gtm_metrics':
        return configGTMMetrics(store, message);
      case 'visa:config:merchant_config_response':
        return merchantConfig(store, message);
      case 'visa:config:thm_data':
        return thmLaunch(store, message);
      // from learn more iframe
      case 'close':
        return closeLearn(store);
      case 'launchFromLearn':
        return launchFromLearn(store, message);
      case 'fitContent':
        return learnFitContent(store, message);
      case 'ghostLayerText':
        return popupOverlayText(store, message);
      case 'getMerchantConfig':
        return getMerchantConfig(store);
      case 'getSDKParams':
        return sdkParamsRequest(store);
      case 'storeAdditionalXoParams':
        return sdkParamsUpdate(store, message);
      case 'applyRxoStyles':
        return rxoApplyStyles(store);
      case 'getABData':
        return rxoABDataRequest(store);
      case 'src:sdklite:fetch-device-info':
        return sdkliteFetchDeviceInfo(store);
      case 'src:sdklite:fetch-user-info':
        return sdkliteFetchUserInfo(store);
      case 'src:sdklite:sync-cookies':
        return sdkliteSyncCookies(store, message);
      case 'sdklite:appready': // only sent in Hybrid 6.x integrations
        return sdkliteAppReady(store);
      case 'consentAccepted':
        return prefillConsentAccepted(store);
      case 'visa.orch.ino-decision':
        return srcOptimizelyDecision(store, message);
      case 'success':
      case 'cancel':
      case 'error':
        return checkoutComplete(store, message, onPrefillRequest);
      // from Hybrid native code
      case 'sdklite:init:complete':
        return sdkliteInitComplete(store);
      case 'visa.sdklite.onbackpressed':
        return sdkliteBackPressed(store, onPrefillRequest);
      case 'visa.sdklite.result': // hybrid-plugin integration only
        return sdkliteResult(store, message, onPrefillRequest);
      case 'visa.sdklite.update-payment-info':
        return sdkliteUpdatePaymentInfo(store, message);
      // from Visa SDK
      case 'launchThmIframe':
        return thmLaunch(store, message);
      // from THM iframe
      case 'visa:thm:response':
        return thmResponse(store, message);
      // from various (config/RXO/SRC)
      case 'pushGtmData':
        return pushGTMData(store, message);
      case 'src:country:locale:cookies:set':
        return updateLocaleCookie(store, message);
    }

    return null;
  });

  return store;
};

export default postmessageEffects;
