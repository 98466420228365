/* START GENAI@GHCOPILOT */
import { CountryCode } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

import { HasConsentedValue } from 'types/storage';
import isEUMerchantOrUser from './isEUMerchantOrUser';

type CanLegallyDropCookiesOptions = {
  cookiePolicyVersion?: number | null;
  hasConsented: HasConsentedValue | null;
  isCookieConsentEnabled: boolean;
  isEuroIp: boolean;
  merchantCountryCode: CountryCode | null;
  userCountryCode: CountryCode | null;
};

export default function canLegallyDropCookies({
  cookiePolicyVersion,
  hasConsented,
  isCookieConsentEnabled,
  isEuroIp,
  merchantCountryCode,
  userCountryCode
}: CanLegallyDropCookiesOptions) {
  // isCookieConsentEnabled determines whether consent is required before
  // dropping cookies. Derived from application config. Currently true in all
  // environments.
  if (!isCookieConsentEnabled) {
    return true;
  }

  const isEU = isEUMerchantOrUser({
    isEuroIp,
    merchantCountryCode,
    userCountryCode
  });

  if (isEU) {
    return Boolean(
      hasConsented &&
        hasConsented.optedIn &&
        parseInt(hasConsented.policyVersion, 10) === cookiePolicyVersion
    );
  } else if (hasConsented) {
    // even when consent is not required, respect decision of users who have
    // previously opted out, regardless of cookiePolicyVersion
    return hasConsented.optedIn;
  }

  return true;
}
