/**
 * Throw an error to return a stack trace via the "stack" property on Error object.
 * We use this to determine if V.init is called within "onVisaCheckoutReady" OR "onVmeReady".
 * @returns {string}
 */
const throwError = (): Error => {
  try {
    throw new Error();
  } catch (err) {
    return err as Error;
  }
};

const getErrorStack = (): string => {
  const error = throwError();
  if (error.stack) {
    return error.stack;
  }

  return '';
};

export default getErrorStack;
