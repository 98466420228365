import { combineReducers } from 'redux';
import checkout from './checkout';
import config from './config';
import gtmNsmi from './gtm-nsmi';
import hybrid from './hybrid';
import orchestration from './orchestration';
import script from './script';
import thm from './thm';
import window from './window';

export default combineReducers({
  checkout,
  config,
  gtmNsmi,
  hybrid,
  orchestration,
  script,
  thm,
  window
});
