import { ROOT_DOMAIN, THM_WINDOW_URL } from 'common/constants/window';
import { WindowId } from 'types/sdk';

export default function getOriginById(windowId: WindowId | 'self') {
  switch (windowId) {
    case 'self':
      return window.location.origin;
    case 'checkout':
    case 'config':
    case 'gtm':
    case 'learn':
    case 'src-system':
      return ROOT_DOMAIN;
    case 'thm':
      return THM_WINDOW_URL.split('/', 3).join('/');
  }

  return null;
}
