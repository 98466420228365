import { getTHMLaunchData } from 'sdk/selectors/thm';
import { THMResponse } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function thmResponse(store: MiddlewareAPI, message: THMResponse) {
  const launchData = getTHMLaunchData(store.getState());

  if (!launchData) {
    return;
  }

  const ssiStatus = launchData.ssiStatus === 'OPTED_IN' ? 'SSI' : 'Non SSI';

  store.dispatch({
    data: {
      api_response: message.response.profilingStatus,
      elapsed_time: Date.now() - launchData.profilingStartTime,
      error_code: message.response.sessionId,
      error_message: 'undefined',
      event: 'THM Profiling',
      event_action: 'THM Profiling Response',
      event_label: `THM Response Received-${ssiStatus}`
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  store.dispatch({
    message: {
      type: 'rxo:thm:complete'
    },
    target: ['checkout', 'config'],
    type: '@@window/SEND_POSTMESSAGE'
  });
}
