// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPromise(p: any): p is Promise<any> {
  return p && typeof p.then === 'function';
}

export default function resolvePromise<D>(d: D | Promise<D>, cb: (d: D) => unknown): void {
  if (isPromise(d)) {
    // eslint-disable-next-line no-console
    d.then(cb).catch(console.error);
    return;
  }

  cb(d);
}
