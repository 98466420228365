/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-this-alias, babel/no-invalid-this */

export default function debounce<F extends (...args: Array<any>) => void>(
  func: F,
  threshold = 100,
  immediate = false
): F {
  let timeout: NodeJS.Timeout | null;

  return function debounced(this: any, ...args: Array<any>) {
    const self = this;

    function delayed() {
      if (!immediate) {
        func.apply(self, args);
      }
      timeout = null;
    }

    if (timeout) {
      clearTimeout(timeout);
    } else if (immediate) {
      func.apply(self, args);
    }

    timeout = setTimeout(delayed, threshold);
  } as F;
}
