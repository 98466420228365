import { getSDKParams } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';

export default function sdkParams(store: MiddlewareAPI) {
  store.dispatch({
    message: {
      data: getSDKParams(store.getState()),
      type: 'setSDKParams'
    },
    target: 'checkout',
    type: '@@window/SEND_POSTMESSAGE'
  });
}
