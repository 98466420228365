import { VInitData as MobileVInitData } from '@visa/hybrid-plugin';
import normalizeTotal from 'sdk/utils/normalize-total';
import {
  PaymentOptions,
  PaymentRequest,
  ReviewOptions,
  ShippingOptions,
  ThreeDSSetupOptions,
  VOptions,
  VSettings
} from 'types/sdk';

function generateShipping(
  profile: MobileVInitData['profile'],
  purchaseInfo: MobileVInitData['purchaseInfo']
) {
  const shipping: ShippingOptions = {
    collectShipping: purchaseInfo.shippingRequired ? 'true' : 'false'
  };

  // Don't set if array is empty, this was causing issues(500 internal server error) on certain environments.
  if (profile.acceptedShippingCountries && profile.acceptedShippingCountries.length > 0) {
    shipping.acceptedRegions = profile.acceptedShippingCountries;
  }

  return shipping;
}

function generateReview(purchaseInfo: MobileVInitData['purchaseInfo']): ReviewOptions {
  return {
    buttonAction: purchaseInfo.reviewAction,
    message: purchaseInfo.reviewMessage
  };
}

function generatePayment(profile: MobileVInitData['profile']) {
  const payment: PaymentOptions = {};

  if (profile.acceptedCardBrands) {
    const cardBrands = profile.acceptedCardBrands;
    payment.cardBrands = cardBrands;
    if (cardBrands.indexOf('VISA') > -1) {
      payment.acceptCanadianVisaDebit = profile.acceptCanadianDebitCards ? 'true' : 'false';
    }
  }

  // Don't set if array is empty, this was causing issues(500 internal server error) on certain environments.
  if (profile.acceptedBillingCountries && profile.acceptedBillingCountries.length > 0) {
    payment.billingCountries = profile.acceptedBillingCountries;
  }

  return payment;
}

function generateThreeDS(purchaseInfo: MobileVInitData['purchaseInfo']): ThreeDSSetupOptions {
  return {
    threeDSActive: purchaseInfo.threeDSActive ? 'true' : 'false',
    threeDSSuppressChallenge: purchaseInfo.threeDSSuppressChallenge ? 'true' : 'false'
  };
}

function generatePaymentRequest(purchaseInfo: MobileVInitData['purchaseInfo']): PaymentRequest {
  return {
    currencyCode: purchaseInfo.currency,
    customData: purchaseInfo.customData,
    description: purchaseInfo.customDescription,
    discount: purchaseInfo.discount,
    giftWrap: purchaseInfo.giftWrapCharges,
    merchantRequestId: purchaseInfo.requestId,
    misc: purchaseInfo.miscellaneousCharges,
    orderId: purchaseInfo.orderId,
    promoCode: purchaseInfo.promoCode,
    shippingHandling: purchaseInfo.shippingAndHandlingCharges,
    subtotal: normalizeTotal(purchaseInfo.subtotal) || undefined,
    tax: purchaseInfo.tax,
    total: normalizeTotal(purchaseInfo.total) || undefined
  };
}

function generateSettings(
  profile: MobileVInitData['profile'],
  purchaseInfo: MobileVInitData['purchaseInfo']
): VSettings {
  const vSettings: VSettings = {
    countryCode: profile.countryCode,
    currencyFormat: purchaseInfo.currencyFormat,
    customerSupportUrl: profile.customerSupportUrl,
    dataLevel: profile.datalevel,
    displayName: profile.displayName,
    enableUserDataPrefill: purchaseInfo.enableUserDataPrefill,
    encryptionKey: profile.encryptionKey,
    locale: profile.locale,
    newUserWelcomeMessage: profile.welcomeMessage,
    newUserWelcomeMessageDescription: profile.welcomeMessageDescription,
    payment: generatePayment(profile),
    returningUserWelcomeMessage: profile.returningUserWelcomeMessage,
    review: generateReview(purchaseInfo),
    shipping: generateShipping(profile, purchaseInfo),
    threeDSSetup: generateThreeDS(purchaseInfo),
    websiteUrl: profile.websiteUrl
  };

  if (typeof profile.enableTokenization === 'boolean') {
    vSettings.tokenizationSetup = {
      enableTokenization: profile.enableTokenization
    };
  }

  return vSettings;
}

export function translate(vInitInfo: MobileVInitData): VOptions {
  const profile = vInitInfo.profile;
  const purchaseInfo = vInitInfo.purchaseInfo;
  return {
    apikey: profile.apiKey,
    clientId: profile.clientId,
    encryptionKey: profile.encryptionKey,
    externalClientId: profile.externalClientId,
    externalProfileId: profile.profileName,
    paymentRequest: generatePaymentRequest(purchaseInfo),
    referenceCallID: purchaseInfo.referenceCallId,
    settings: generateSettings(profile, purchaseInfo)
  };
}

export type PartialPaymentRequest = Pick<PaymentRequest, 'currencyCode' | 'subtotal' | 'total'>;

export function generateUpdatedPaymentRequest(
  purchaseInfo: MobileVInitData['purchaseInfo']
): PartialPaymentRequest {
  return {
    currencyCode: purchaseInfo.currency,
    subtotal: normalizeTotal(purchaseInfo.subtotal) || undefined,
    total: normalizeTotal(purchaseInfo.total) || undefined
  };
}
