import { MiddlewareAPI } from 'types/redux';

export default function closeLearn(
  store: MiddlewareAPI
  // message: CloseLearn
) {
  store.dispatch({
    data: {
      id: 'learn'
    },
    type: '@@window/CLOSE_WINDOW'
  });
}
