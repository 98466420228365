import { iframeIdMap } from 'sdk/constants/iframe';
import { get as getPopupWindow } from 'sdk/utils/popup-reference';
import { WindowId } from 'types/sdk';

export default function getWindowById(windowId: WindowId | 'self'): Window | null {
  if (windowId === 'self') {
    return window;
  }

  const iframe = document.getElementById(iframeIdMap[windowId]) as HTMLIFrameElement | null;

  if (iframe?.contentWindow) {
    return iframe.contentWindow;
  }

  if (windowId === 'checkout') {
    // In VSB integrations, the DCF iframe will have id="vcop-dcf"
    const dcfIframe = document.getElementById('vcop-dcf') as HTMLIFrameElement | null;

    if (dcfIframe?.contentWindow) {
      return dcfIframe.contentWindow;
    }

    const popupWindow = getPopupWindow();

    if (popupWindow) {
      return popupWindow;
    }
  }

  return null;
}
