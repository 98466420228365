import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { isSafari } from 'common/utils/browser';
import getMerchantUrl from 'common/utils/get-merchant-url';
import buttonMiddleware from 'sdk/middleware/button';
import hybridMiddleware from 'sdk/middleware/hybrid';
import learnMoreMiddleware from 'sdk/middleware/learn-more';
import orchestrationMiddleware from 'sdk/middleware/orchestration';
import postmessageMiddleware from 'sdk/middleware/postmessage';
import windowMiddleware from 'sdk/middleware/window';
import rootReducer from 'sdk/reducers';
import { getIsHybrid } from 'sdk/selectors';
import { State, Store } from 'types/redux';

export default (initialState?: Partial<State>): Store => {
  // These middlewares handle *most* application side effects, e.g. DOM
  // manipulation, window creation, postmessaging, and interfacing with other
  // scripts on the window. Side effects based on changes to state (e.g. button
  // updates) are handled by the store listeners in sdk/effects/. Some less
  // impactful side effects are handled via utils, e.g. error logging and
  // user agent detection.
  const middlewares = [
    buttonMiddleware,
    hybridMiddleware,
    learnMoreMiddleware,
    postmessageMiddleware,
    windowMiddleware,
    // orchestrationMiddleware must be placed after windowMiddleware since it
    // relies on window side effects (specifically, when the checkout window is
    // opened, we pass the newly-created window reference to orchestration)
    orchestrationMiddleware
  ];

  // Add logger middleware to Hybrid and Safari since we can't use Redux DevTools
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'test' &&
    (getIsHybrid(initialState as State) || isSafari())
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { createLogger } = require('redux-logger');
    middlewares.push(
      createLogger({
        collapsed: false
      })
    );
  }

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools({
      name: `Visa SDK (${getMerchantUrl()})`,
      trace: true
    })(applyMiddleware(...middlewares))
  );
};
