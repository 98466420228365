/* eslint-disable no-console */

type MessageData = { [key: string]: string };

function error(message: string): void;
function error<D extends MessageData>(message: (d: D) => string, data: D): void;
function error(message: string | ((data?: MessageData) => string), data?: MessageData) {
  if (process.env.NODE_ENV !== 'production') {
    console.trace('Visa:', typeof message === 'function' ? message(data) : message);
  }
}

function warn(message: string): void;
function warn<D extends MessageData>(message: (d: D) => string, data: D): void;
function warn(message: string | ((data?: MessageData) => string), data?: MessageData) {
  if (process.env.NODE_ENV !== 'production') {
    console.warn('Visa:', typeof message === 'function' ? message(data) : message);
  }
}

export default {
  error,
  warn
};
