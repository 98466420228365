type Styles = {
  [key: string]: string;
};

export default function createStyleString(styles: Styles): string {
  const styleKeys = Object.keys(styles);

  let styleString = '';

  for (const key of styleKeys) {
    styleString += `${key}:${styles[key]};`;
  }

  return styleString;
}
