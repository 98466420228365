/* START GENAI@GHCOPILOT */
import { CountryCode, Locale, LocaleUnderscore } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

export default function getCountryFromLocale(
  locale: Locale | null,
  delim?: '-'
): CountryCode | null;
export default function getCountryFromLocale(
  locale: LocaleUnderscore | null,
  delim: '_'
): CountryCode | null;
export default function getCountryFromLocale(
  locale: Locale | LocaleUnderscore | null,
  delim: '-' | '_' = '-'
) {
  return locale ? (locale.split(delim)[1] as CountryCode | undefined) || null : null;
}
