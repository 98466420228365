import getRootDomain from 'common/utils/get-root-domain';

export const ROOT_DOMAIN = getRootDomain();

// Iframe URLs
export const CONFIG_WINDOW_URL = `${ROOT_DOMAIN}${__CONFIG_WINDOW_URL__}`;
export const GTM_WINDOW_URL = `${ROOT_DOMAIN}${__GTM_WINDOW_URL__}`;
export const LEARN_MORE_RXO_WINDOW_URL = `${ROOT_DOMAIN}${__LEARN_MORE_RXO_WINDOW_URL__}`;
export const LEARN_MORE_SRC_WINDOW_URL = `${ROOT_DOMAIN}${__LEARN_MORE_SRC_WINDOW_URL__}`;
export const RXO_WINDOW_URL = `${ROOT_DOMAIN}${__RXO_WINDOW_URL__}`;
export const SDK_LOADER_URL = `${ROOT_DOMAIN}${__SDK_LOADER_URL__}`;
export const SDKLITE_LOADER_URL = `${ROOT_DOMAIN}${__SDKLITE_LOADER_URL__}`;
export const SDKLITE_URL = `${ROOT_DOMAIN}${__SDKLITE_URL__}`;
export const THM_WINDOW_URL = __THM_WINDOW_URL__;
export const VCOP_WINDOW_URL = `${ROOT_DOMAIN}${__VCOP_WINDOW_URL__}`;

export const ORCH_SCRIPT_URL = `${ROOT_DOMAIN}${__ORCH_SCRIPT_URL__}`;
export const THM_ORIGIN = 'https://thm.visa.com';
