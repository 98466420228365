export const defaultSdkLiteStrings = {
  accessibility: {
    button: 'Click to pay with payment icon',
    fingerprint_icon: 'Fingerprint icon',
    locked_button: 'Payment service by Visa. Unavailable.',
    spinner: 'Loading',
    spinner_close: 'Cancel and return to merchant'
  },
  logging: {
    already_launched: 'The checkout flow has already been launched.',
    api_check:
      'Devices running on API level __apilevel__ and above can use Visa Checkout functionality.',
    corr_id_session: 'CorrelationID for this session is __correlationid__',
    internal_error: "Visa Checkout had an internal error and can't complete the request.",
    invalid_params: 'Parameters are invalid or nil.',
    lock_reason:
      'Disabling button because this environment is not supported, please try a newer OS.',
    no_network: 'There is no network connection so payment info was not updated.',
    not_initialized: 'The SDK has not yet been initialized.',
    objc_bridge_warning:
      'Warning -- Objective-C cannot bridge the array values being set on __property__, please use the .rawValue property on the __enum__ enum.'
  },
  ui: {
    biometric_cancel: 'Cancel',
    biometric_signin: 'Sign in',
    fingerprint_confirm: 'Confirm your fingerprint now.',
    fingerprint_continue: 'Confirm fingerprint to continue',
    fingerprint_recognized: 'Fingerprint recognized',
    fingerprint_unrecognized: 'Fingerprint not recognized. Try again',
    touch_sensor: 'Touch sensor'
  }
};
