import { isHybridManualCheckout } from 'common/utils/browser';
import getWebviewType from 'common/utils/get-webview-type';
import { popupIdMap } from 'sdk/constants/iframe';
import getHybridAPIVersion, { HybridAPIVersion } from 'sdk/utils/get-hybrid-api-version';
import getMobileEnvironment, { MobileEnvironment } from 'sdk/utils/get-mobile-environment';
import { Action } from 'types/redux';
import { HybridWebviewType } from 'types/sdk';

export type HybridState = {
  checkoutWebviewName: string | null;
  checkoutWebviewUrl: string | null;
  isAppReady: boolean;
  isButtonClicked: boolean;
  isManualCheckout: boolean;
  hybridAPIVersion: HybridAPIVersion;
  mobileEnvironment: MobileEnvironment;
  nativeSpinnerActive: boolean;
  // SDK is loaded in both mobile-button.html AND hybrid.html/sdk-lite.html.
  // In mobile-button.html, webviewType === 'merchant'
  // In hybrid.html/sdk-lite.html, webviewType === 'checkout'
  webviewType: HybridWebviewType;
};

export const defaultHybridState: HybridState = {
  checkoutWebviewName: null,
  checkoutWebviewUrl: null,
  hybridAPIVersion: getHybridAPIVersion(),
  isAppReady: false,
  isButtonClicked: false,
  isManualCheckout: isHybridManualCheckout(),
  mobileEnvironment: getMobileEnvironment(),
  nativeSpinnerActive: false,
  webviewType: getWebviewType()
};

function hybridReducer(state = defaultHybridState, action: Action): HybridState {
  switch (action.type) {
    // only relevant to Hybrid 6.x integrations
    case '@@hybrid/APP_READY':
      return { ...state, isAppReady: true };
    case '@@hybrid/NATIVE_BUTTON_CLICK': // 7.x
    case '@@hybrid/LAUNCH_NATIVE_SPINNER': // 6.x
      return {
        ...state,
        isButtonClicked: true,
        nativeSpinnerActive: true
      };
    case '@@hybrid/CHECKOUT_LAUNCH':
      return { ...state, nativeSpinnerActive: false };
    case '@@window/OPEN_WINDOW':
      if (action.data.id === 'checkout') {
        return {
          ...state,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          checkoutWebviewName: popupIdMap.checkout!,
          checkoutWebviewUrl: action.data.src
        };
      }

      return state;
    case '@@hybrid/RESET':
      return {
        ...state,
        checkoutWebviewName: null,
        checkoutWebviewUrl: null,
        isButtonClicked: false,
        nativeSpinnerActive: false
      };
    default:
      return state;
  }
}

export default hybridReducer;
