import { CardNetwork } from 'types/enums';

export const BUTTON_SIZE_SMALL = 154;
export const BUTTON_SIZE_NORMAL = 213;
export const BUTTON_SIZE_LARGE = 425;

export const LOCKED_BUTTON_SRC_SMALL =
  'checkout-widget/resources/img/integration/v1/locked-button-s.png';
export const LOCKED_BUTTON_SRC_MEDIUM =
  'checkout-widget/resources/img/integration/v1/locked-button-m.png';
export const LOCKED_BUTTON_SRC_LARGE =
  'checkout-widget/resources/img/integration/v1/locked-button-l.png';

export const DEFAULT_CARD_BRAND_ORDER: CardNetwork[] = ['VISA', 'MASTERCARD', 'AMEX', 'DISCOVER'];
