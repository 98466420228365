/* START GENAI@GHCOPILOT */
import { createSelector } from 'reselect';
import { Locale, LocaleUnderscore } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

import {
  LEARN_MORE_RXO_WINDOW_URL,
  LEARN_MORE_SRC_WINDOW_URL,
  RXO_WINDOW_URL,
  VCOP_WINDOW_URL
} from 'common/constants/window';
import getMerchantUrl from 'common/utils/get-merchant-url';
import { WindowConfig } from 'sdk/reducers/window';
import { State } from 'types/redux';
import { QueryObj, WindowId, WindowType } from 'types/sdk';
import {
  getApiKey,
  getCorrelationId,
  getExternalProfileId,
  getFormFactorFlag,
  getIntegrationType,
  getSessionId,
  getVInitCollectShipping,
  getVInitSetting
} from './checkout';
import {
  getBrandingType,
  getCanLegallyDropCookies,
  getCheckoutAppType,
  getCheckoutFlow,
  getCheckoutWindowType,
  getConfigDataProperty,
  getCustomBackgroundUrl,
  getExternalClientId,
  getIsSRCBranded,
  getIsSplunkLoggingEnabled,
  getMerchantResponseProperty,
  getSRCBrandingOverride,
  getUserLocale,
  getVdcpAbTestingWeightedProbabilityValue,
  shouldPreloadApp
} from './config';
import { getIsHybrid } from './hybrid';

export const getWindowState = (state: State, windowId: WindowId) => state.window[windowId] || null;

export const getWindowType = (state: State, windowId: WindowId): WindowType | null => {
  const windowState = getWindowState(state, windowId);

  if (!windowState) {
    return null;
  }

  return windowState.type;
};

export const getWindowOption = <K extends keyof WindowConfig>(
  state: State,
  windowId: WindowId,
  key: K
) => {
  const windowState = getWindowState(state, windowId);
  return windowState ? windowState[key] : null;
};

export const getConfigIframeQuery = (state: State): QueryObj => {
  const configQuery: QueryObj = {
    apikey: getApiKey(state),
    countryCode: getVInitSetting(state, 'countryCode'),
    externalClientId: getExternalClientId(state),
    externalProfileId: getExternalProfileId(state),
    formFactor: getFormFactorFlag(state),
    locale: getVInitSetting(state, 'locale'),
    parentUrl: getMerchantUrl()
  };

  // Hybrid integrations generate correlationId differently than web
  // integrations. In SDK Lite 7+, the native layer creates a correlationId and
  // adds it to localStorage when the button webview is created. In older hybrid
  // versions, we generate the correlationId client-side when the sdk
  // initializes. For web integrations, we do not have a correlationId until
  // config.jsp creates one and postmessages it back to the SDK in config data.
  if (getIntegrationType(state) !== 'web') {
    configQuery.correlationId = getCorrelationId(state);
  }

  return configQuery;
};

export const getGTMIframeQuery = (): QueryObj => ({
  parentUrl: getMerchantUrl()
});

export const getLearnMoreUrl = (state: State) => {
  return getConfigDataProperty(state, 'showSrcTmm')
    ? LEARN_MORE_SRC_WINDOW_URL
    : LEARN_MORE_RXO_WINDOW_URL;
};

export const getLearnMoreIframeQuery = (
  state: State,
  locale: Locale | LocaleUnderscore | null
): QueryObj => {
  const xoFlow = getCheckoutFlow(state);
  const showSrcTmm = getConfigDataProperty(state, 'showSrcTmm');
  return {
    apikey: getApiKey(state),
    isLegacy: !getIsSRCBranded(state) && ((showSrcTmm && xoFlow !== 'DISABLED') || !showSrcTmm),
    locale: locale ? locale.replace('_', '-') : 'en',
    parentUrl: getMerchantUrl(),
    xoFlow
  };
};

export const getCheckoutWindowUrl = createSelector(
  getCheckoutAppType,
  getCheckoutFlow,
  getLearnMoreUrl,
  (appType, checkoutFlow, learnMoreUrl) => {
    if (checkoutFlow === 'DISABLED') {
      return learnMoreUrl;
    }

    return appType === 'RXO' ? RXO_WINDOW_URL : VCOP_WINDOW_URL;
  }
);

export const getSRCWindowQuery = (state: State): QueryObj => ({
  // We know that apikey is defined by this point in the SDK flow.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apikey: getApiKey(state)!,
  backgroundURL: getCustomBackgroundUrl(state),
  browserLocale: getConfigDataProperty(state, 'browserLocale'),
  cdc: getCanLegallyDropCookies(state),
  collectShipping: getVInitCollectShipping(state),
  correlationId: getCorrelationId(state),
  country: getVInitSetting(state, 'countryCode'),
  countryCode: getVInitSetting(state, 'countryCode'),
  euIP: getConfigDataProperty(state, 'isEuroIp') || false,
  externalClientId: getExternalClientId(state),
  externalProfileId: getExternalProfileId(state),
  formFactorMerchantOverride: getMerchantResponseProperty(state, 'formFactorMerchantOverride'),
  initialBranding: getBrandingType(state),
  isCrossApp: getIntegrationType(state) === 'sdk-lite-cross-app',
  isHybrid: getIsHybrid(state),
  isSplunkLoggingEnabled: getIsSplunkLoggingEnabled(state),
  locale: getVInitSetting(state, 'locale') ?? '',
  parentUrl: getMerchantUrl(),
  postmessage: true,
  sessionId: getSessionId(state),
  showPopup: getCheckoutWindowType(state) === 'POPUP',
  srcBrandingOverride: getSRCBrandingOverride(state),
  vabwp: getVdcpAbTestingWeightedProbabilityValue(state),
  visitId: getConfigDataProperty(state, 'visitId'),
  xoFlow: getCheckoutFlow(state)
});

export const getRXOWindowQuery = (state: State): QueryObj => ({
  // We know that apikey is defined by this point in the SDK flow.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  apikey: getApiKey(state)!,
  backgroundURL: getCustomBackgroundUrl(state),
  browserLocale: getConfigDataProperty(state, 'browserLocale'),
  cdc: getCanLegallyDropCookies(state),
  collectShipping: getVInitCollectShipping(state),
  country: getVInitSetting(state, 'countryCode'),
  countryCode: getVInitSetting(state, 'countryCode'),
  euIP: getConfigDataProperty(state, 'isEuroIp') || false,
  externalClientId: getExternalClientId(state),
  externalProfileId: getExternalProfileId(state),
  initialBranding: getBrandingType(state),
  isCrossApp: getIntegrationType(state) === 'sdk-lite-cross-app',
  isHybrid: getIsHybrid(state),
  isRXOFrame: true,
  locale: getVInitSetting(state, 'locale'),
  parentUrl: getMerchantUrl(),
  postmessage: true,
  showPopup: getCheckoutWindowType(state) === 'POPUP',
  skipPreload: !shouldPreloadApp(state)
});

export const getCheckoutWindowQuery = (state: State) => {
  if (getCheckoutFlow(state) === 'DISABLED') {
    const locale = getUserLocale(state);
    return getLearnMoreIframeQuery(state, locale);
  }

  return getCheckoutAppType(state) === 'SRC' ? getSRCWindowQuery(state) : getRXOWindowQuery(state);
};
