import { POSTMESSAGE_SEND_ERROR } from 'common/constants/error-messages';
import { ROOT_DOMAIN, THM_ORIGIN } from 'common/constants/window';
import { isHybridSDKLite, isNativeWebview, isSDKLite } from 'common/utils/browser';
import getOriginById from 'common/utils/get-origin-by-id';
import getWindowById from 'common/utils/get-window-by-id';
import getWindowId from 'common/utils/get-window-id';
import isArrayMessage from 'common/utils/is-array-message';
import logger from 'common/utils/logger';
import parseMessage, { DATA_DELIM } from 'common/utils/parse-message';
import { IncomingMessage, OutgoingMessage } from 'types/messages';
import { THMResponse } from 'types/messages/incoming';
import { WindowId } from 'types/sdk';

type LegacyMessage = {
  data?: Record<string, unknown> | number;
  error?: Record<string, unknown>;
  sdkOptions?: Record<string, unknown>;
  type: string;
};

function convertToLegacyMessage(message: LegacyMessage) {
  return [
    message.type,
    JSON.stringify(message.data || {}),
    JSON.stringify(message.error),
    JSON.stringify(message.sdkOptions)
  ]
    .filter(Boolean)
    .join(DATA_DELIM);
}

export function send(message: OutgoingMessage, targetId: WindowId | 'self'): void {
  const targetWindow = getWindowById(targetId);
  const targetOrigin = getOriginById(targetId);

  let formattedMessage: string | OutgoingMessage = message;

  // TODO: Ensure that all recipients can accept JSON-style postmessages and
  // remove the legacy style entirely.
  if (
    targetId === 'checkout' ||
    targetId === 'learn' ||
    // "self" in this case applies to orchestration/VSB script
    targetId === 'self' ||
    targetId === 'src-system'
  ) {
    formattedMessage = convertToLegacyMessage(message);
  }

  if (targetOrigin && targetWindow) {
    targetWindow.postMessage(formattedMessage, targetOrigin);
  } else {
    logger.error(POSTMESSAGE_SEND_ERROR, { targetId });
  }
}

export function isVisaOrigin(e: MessageEvent) {
  const windowId = getWindowId(e.source);

  // Hybrid implementations may send messages directly from the webview. For
  // instance, /sdklite-disabled sends a cancel message from the same window as
  // the sdk.
  if ((isNativeWebview() || isSDKLite() || isHybridSDKLite()) && windowId === 'self') {
    return true;
  }

  return (
    e.origin === ROOT_DOMAIN &&
    (typeof e.data === 'string' || typeof e.data === 'object') &&
    // Accepting messages from self causes crashes when using redux-devtools.
    windowId !== 'self'
  );
}

export type ListenCallback = (
  e: MessageEvent,
  message: IncomingMessage,
  removeListener: () => void
) => unknown;

export function listen(cb: ListenCallback) {
  function removeListener() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    window.removeEventListener('message', listener);
  }

  function listener(e: MessageEvent) {
    if (
      e.origin === THM_ORIGIN &&
      e.data &&
      typeof e.data === 'string' &&
      e.data.indexOf(':') > 0
    ) {
      const [profilingStatus, sessionId] = e.data.split(':');
      const thmMessage: THMResponse = {
        response: {
          profilingStatus,
          sessionId
        },
        type: 'visa:thm:response'
      };

      cb(e, thmMessage, removeListener);
      return;
    }

    if (!isVisaOrigin(e)) {
      return;
    }

    const message = parseMessage(e) as IncomingMessage;

    if (!message) {
      return;
    }

    // fire callback multiple times for batch messages
    if (isArrayMessage(message)) {
      message.forEach(msg => {
        cb(e, msg, removeListener);
      });

      return;
    }

    cb(e, message, removeListener);
  }

  window.addEventListener('message', listener);

  return removeListener;
}

export { parseMessage as parse };
