/* START GENAI@GHCOPILOT */
import { CountryCode } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

export const EU_COUNTRY_CODES: Array<CountryCode> = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'EL',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IS',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'UK'
];
