import { QueryObj } from 'types/sdk';

export function createQueryObject(queryString = '') {
  if (queryString.indexOf('?') !== -1) {
    queryString = queryString.split('?')[1];
  }

  if (!queryString) {
    return {};
  }

  return queryString.split('&').reduce((queryObj: QueryObj, param) => {
    const [key, value] = param.split('=');
    queryObj[key] = value;
    return queryObj;
  }, {});
}

export function createQueryString(queryObj: QueryObj) {
  const paramKeys = Object.keys(queryObj);
  const query = [];

  for (const param of paramKeys) {
    const value = queryObj[param];

    if (value !== undefined && value !== null) {
      query.push(`${encodeURIComponent(param)}=${encodeURIComponent(value.toString())}`);
    }
  }

  return `?${query.join('&')}`;
}

export function updateQueryString(url: string, queryObj: QueryObj) {
  const prevQuery = createQueryObject(url);
  const nextQuery = createQueryString({ ...prevQuery, ...queryObj });
  return `${url.split('?')[0]}${nextQuery}`;
}
