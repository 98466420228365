import {
  deactivateLearnLink,
  hideLearnMore,
  initLearnMore,
  resizeLearnContent
} from 'sdk/dom/learn-more';
import { Action, Middleware } from 'types/redux';

/**
 * Handles manipulating the "Learn More" link and iframe on the merchant page.
 */
const learnMoreMiddleware: Middleware = () => next => (action: Action) => {
  switch (action.type) {
    case '@@learn/INIT':
      initLearnMore(action.events);
      break;
    case '@@learn/FIT_CONTENT':
      resizeLearnContent(action.data);
      break;
    case '@@window/CLOSE_WINDOW':
      if (action.data.id === 'learn') {
        deactivateLearnLink();
      }
      break;
    case '@@learn/HIDE':
      hideLearnMore();
      break;
  }

  return next(action);
};

export default learnMoreMiddleware;
