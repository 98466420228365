import { RXO_WINDOW_URL } from 'common/constants/window';
import { isMobileSafari } from 'common/utils/browser';
import createCorrelationId from 'common/utils/correlation-id';
import * as IDStorage from 'common/utils/id-storage';
import createSessionId from 'common/utils/session-id';
import {
  getCheckoutFlow,
  getHybridWebviewType,
  getIntegrationType,
  getIsHybrid,
  getRXOWindowQuery,
  getSRCEnvConfigProperty,
  getWindowState,
  shouldPreloadApp
} from 'sdk/selectors';
import * as PopupCloseTimer from 'sdk/utils/popup-close-timer';
import { CheckoutStatusGTMValue } from 'types/gtm';
import { CheckoutComplete } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';
import { PrefillCallback } from 'types/sdk';

export default function checkoutComplete(
  store: MiddlewareAPI,
  message: CheckoutComplete,
  onPrefillRequest: PrefillCallback
) {
  let state = store.getState();
  const isHybrid = getIsHybrid(state);
  const integrationType = getIntegrationType(state);
  const webviewType = getHybridWebviewType(state);
  let checkoutStatusGTMValue: CheckoutStatusGTMValue;

  PopupCloseTimer.stop();

  // In SDK Lite integrations, Learn More iframe sends a 'cancel' event on close instead of 'close'.
  if (getWindowState(state, 'learn')) {
    store.dispatch({
      data: {
        id: 'learn'
      },
      type: '@@window/CLOSE_WINDOW'
    });
  }

  if (message.type === 'success') {
    checkoutStatusGTMValue = 'checkout_success';

    /**
      * Hybrid 6.x and Cross App implementations use localStorage to sync
        correlationId between the two instances of sdk.js, one in the button
        webview (mobile-button.html) and one in the checkout webview
        (sdk-lite.html). The value is set in the button webview on button click,
        and removed when the checkout complete message is received.
      */
    if (webviewType === 'checkout') {
      store.dispatch({
        data: {
          key: 'correlationId'
        },
        type: '@@window/REMOVE_LOCALSTORAGE'
      });
    }
  } else if (message.type === 'cancel') {
    checkoutStatusGTMValue = 'checkout_cancel';
  } else {
    checkoutStatusGTMValue = 'checkout_failure';
  }

  const { dataLayer } = message.data;

  if (dataLayer) {
    const filteredDataLayer = { ...dataLayer };
    delete filteredDataLayer.event;
    delete filteredDataLayer.event_action;
    delete filteredDataLayer.event_category;
    delete filteredDataLayer.event_label;

    store.dispatch({
      data: filteredDataLayer,
      type: '@@window/SYNC_GTM_DATALAYER'
    });
  }

  store.dispatch({
    data: {
      event: checkoutStatusGTMValue,
      event_action: checkoutStatusGTMValue,
      event_category: checkoutStatusGTMValue,
      event_label: checkoutStatusGTMValue
    },
    type: '@@window/SEND_GTM_EVENT'
  });

  if (message.type === 'error') {
    store.dispatch({
      message,
      type: '@@sdk/CHECKOUT_COMPLETE'
    });
  } else {
    // Generate a new correlationId/sessionId on success/cancel.
    const correlationId = createCorrelationId();
    const sessionId = createSessionId(getSRCEnvConfigProperty(store.getState(), 'sessionIdPrefix'));

    IDStorage.set('correlationId', correlationId);

    store.dispatch({
      correlationId,
      message,
      sessionId,
      type: '@@sdk/CHECKOUT_COMPLETE'
    });

    const checkoutFlow = getCheckoutFlow(store.getState());

    /**
     * If response.unbindAppInstance is true, visaSdk.js will reload THM via the
     * launchThmIframe postmessage. For RXO, reload THM now.
     *
     * TODO: When RXO is sunset, this whole block can be removed as visaSdk.js
     * will handle THM lifecycle.
     */
    if (checkoutFlow === 'RXO' || !message.data.unbindAppInstance) {
      // Update THM with new sessionId.
      store.dispatch({
        message: {
          data: {
            reInitSessionId: sessionId
          },
          type: 'src:thm:updateTHMIframe'
        },
        target: 'config',
        type: '@@window/SEND_POSTMESSAGE'
      });
    }
  }

  if (isHybrid) {
    store.dispatch({ type: '@@hybrid/RESET' });

    const isMerchantWebview = webviewType === 'merchant';

    if (integrationType !== 'web' && isMerchantWebview && !isMobileSafari()) {
      store.dispatch({
        onDismiss() {
          store.dispatch({
            events: {
              onPrefillRequest
            },
            type: '@@hybrid/BUTTON_INIT'
          });
        },
        type: '@@hybrid/DISMISS'
      });
    }

    if (message.type !== 'error' && integrationType === 'sdk-lite-cross-app') {
      store.dispatch({
        data:
          message.type === 'success'
            ? { result: message.data, type: 'payment.success' }
            : { result: message.data, type: 'payment.cancel' },
        type: '@@hybrid/SEND_RESULT'
      });
    }
  } else {
    state = store.getState();

    store.dispatch({
      data: { id: 'checkout' },
      type: '@@window/CLOSE_WINDOW'
    });

    if (shouldPreloadApp(state)) {
      store.dispatch({
        data: {
          attributes: {
            title: 'Visa Checkout'
          },
          id: 'checkout',
          onLoad: () => {
            store.dispatch({
              message: {
                data: Date.now(),
                type: 'rxo:render'
              },
              target: 'checkout',
              type: '@@window/SEND_POSTMESSAGE'
            });
          },
          query: getRXOWindowQuery(state),
          src: RXO_WINDOW_URL,
          type: 'preload'
        },
        type: '@@window/OPEN_WINDOW'
      });
    }
  }
}
