import { defaultCheckoutState } from 'sdk/reducers/checkout';
import hasDebugCookie from './has-debug-cookie';

const debug = hasDebugCookie();
const { integrationType } = defaultCheckoutState;

export default function hybridLogger(message: string, data?: Record<string, unknown>) {
  if (integrationType !== 'web' || debug) {
    let strData = '';

    try {
      strData = JSON.stringify(data);
    } catch (err) {
      // ignore data if stringify fails due to cyclic data structures
    }

    message = strData ? `${message} : ${strData}` : message;

    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line no-console
      console.log(`@sdk:${message}`);
    }
  }
}
