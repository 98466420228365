import { Action } from 'types/redux';
import { THMLaunchData } from 'types/thm';

export type THMState =
  | (THMLaunchData & {
      profilingStartTime: number;
    })
  | null;

const defaultTHMState: THMState = null;

export default function thmReducer(state: THMState = defaultTHMState, action: Action): THMState {
  switch (action.type) {
    case '@@thm/LAUNCH':
      return {
        ...action.data,
        orgId: action.data.orgId ?? state?.orgId,
        profilingStartTime: Date.now(),
        sessionId: action.data.sessionId ?? state?.sessionId
      };
    default:
      return state;
  }
}
