import HybridSDK from 'common/hybrid';

export type HybridAPIVersion = '5.x' | '6.x' | '7.x' | null;

export default function getHybridAPIVersion(): HybridAPIVersion {
  switch (HybridSDK.API_LEVEL) {
    case 0:
      return null;
    case 1:
      return '5.x';
    case 2:
      return '6.x';
    case 3:
    default:
      return '7.x';
  }
}
