import { isIOS as isIOSBrowser } from 'common/utils/browser';
import { mark, marks } from 'common/utils/instrumentation';
import { createQueryString } from 'common/utils/query-string';
import { PopupWindowId, popupIdMap } from 'sdk/constants/iframe';
import visaLogoURI from 'sdk/constants/logo-uri';
import styleString from 'sdk/utils/create-style-string';
import hasParent from 'sdk/utils/has-parent';
import * as PopupCloseTimer from 'sdk/utils/popup-close-timer';
import * as popupRef from 'sdk/utils/popup-reference';
import { QueryObj } from 'types/sdk';

const isIOS = isIOSBrowser();
const popupOverlayId = 'vcoGhostLayer';
let onCancelCheckout: () => void;

function focusOverlayLogo(e: FocusEvent) {
  const focusedEl = e.target as HTMLElement | null;

  if (!hasParent(focusedEl, `#${popupOverlayId}`)) {
    const logo = document.getElementById('visa-overlay-logo') as HTMLAnchorElement | null;

    if (logo) {
      logo.focus();
    }
  }
}

function closePopupOverlay() {
  const popupOverlay = document.getElementById(popupOverlayId);

  if (popupOverlay) {
    document.body.removeChild(popupOverlay);
  }

  document.removeEventListener('focus', focusOverlayLogo, true);
}

export function closePopup() {
  const popupWindow = popupRef.get();

  if (popupWindow) {
    popupWindow.close();
  }

  popupRef.set(null);

  closePopupOverlay();
}

export type OpenPopupOptions = {
  id: PopupWindowId;
  type: 'popup';
  onLoad?: () => void;
  onCancelCheckout?: () => void;
  // overlay: false is used in SDK Lite flows to preload the SRC loader in the
  // background. Then we use @@window/NAVIGATE to transition that window to the
  // app when it is ready.
  overlay?: boolean;
  replaceUrl?: boolean;
  src: string;
  query?: QueryObj;
};

export function openPopup(options: OpenPopupOptions) {
  const showOverlay = options.overlay ?? true;
  if (!onCancelCheckout && options.onCancelCheckout) {
    onCancelCheckout = options.onCancelCheckout;
  }

  if (showOverlay) {
    // add open sans font
    const openSansLoaded = Boolean(
      performance
        .getEntriesByType('resource')
        .find(resource => resource.name.indexOf('Open+Sans') !== -1)
    );

    if (!openSansLoaded) {
      const style = document.createElement('style');
      style.innerHTML = "@import url('https://fonts.googleapis.com/css?family=Open+Sans');";
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }

  const query = options.query ? createQueryString(options.query) : '';

  let popupWindow = popupRef.get();

  // window already exists? Replace URL
  if (popupWindow && options.replaceUrl) {
    popupWindow.location.href = `${options.src}${query}`;
  } else {
    // 600 for max-height of RXO + additional to account for what browser window will add for menu and address bars, etc.
    const height = Math.min(screen.height, 640);
    const width = Math.min(screen.width, 400);

    const windowOptions = [
      'directories=no',
      'location=yes',
      'menubar=no',
      'scrollbars=yes',
      'status=no',
      'toolbar=no',
      'resizable=no',
      `width=${width}`,
      `height=${height}`,
      // Fun fact: Chrome positions popups incorrectly when using multiple monitors:
      // https://bugs.chromium.org/p/chromium/issues/detail?id=137681
      `top=${screen.height / 2 - height / 2}`,
      `left=${screen.width / 2 - width / 2}`
    ];

    popupWindow = window.open(
      `${options.src}${query}`,
      popupIdMap[options.id],
      windowOptions.join(',')
    );

    if (popupWindow) {
      popupRef.set(popupWindow);

      popupWindow.addEventListener('load', () => {
        mark(`${marks.popupLoad}${popupIdMap[options.id]}`);

        if (options.onLoad) {
          options.onLoad();
        }
      });
    }

    if (showOverlay) {
      const overlay = document.createElement('div');

      overlay.id = popupOverlayId;
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.background = '#000';
      overlay.style.opacity = '0.75';
      overlay.style.filter = 'progid:DXImageTransform.Microsoft.Alpha(Opacity=75)';
      overlay.style.minWidth = '100%';
      overlay.style.minHeight = '100%';
      overlay.style.zIndex = '999999';

      document.body.appendChild(overlay);
    }

    const eventName = isIOS ? 'beforeunload' : 'pagehide';

    // close popup if parent window unloads
    window.addEventListener(eventName, function closePopupAndRemoveListener() {
      window.removeEventListener(eventName, closePopupAndRemoveListener);
      closePopup();
    });
  }

  if (popupWindow) {
    /**
     * Detect immediate window close prior to app load. In this scenario we do
     * not receive a checkout complete event.
     * Also detect if popup is closed using default window close button (VCO-15100)
     * https://stash.trusted.visa.com:7990/projects/THINWALLET/repos/checkout-widget/pull-requests/12289/overview
     */
    if (onCancelCheckout) {
      PopupCloseTimer.start(Infinity, popupWindow, () => {
        onCancelCheckout();
        closePopup();
      });
    }

    popupWindow.focus();
  }
}

export type PopupOverlayData = {
  cancel_and_return: string;
  find_button: string;
  message: string;
  message_iOS_safari: string;
  restart_button: string;
};

export type PopupOverlayEvents = {
  onCancelClick: (e: Event) => void;
  onFindClick: (e: Event) => void;
  onRestartClick: (e: Event) => void;
};

export function renderOverlayText(data: PopupOverlayData, events: PopupOverlayEvents) {
  const popupOverlay = document.getElementById(popupOverlayId);

  if (!popupOverlay) {
    return;
  }

  if (popupOverlay.firstChild) {
    popupOverlay.removeChild(popupOverlay.firstChild);
  }

  // focus overlay logo when switching focus back to merchant window
  document.addEventListener('focus', focusOverlayLogo, true);

  const overlayContent = document.createElement('div');
  overlayContent.setAttribute('role', 'dialog');

  overlayContent.style.color = '#fff';
  overlayContent.style.textAlign = 'center';
  overlayContent.style.zIndex = '999999';
  overlayContent.style.position = 'fixed';
  overlayContent.style.width = '100%';
  overlayContent.style.top = '35%';
  overlayContent.style.fontSize = '10px';
  overlayContent.style.fontFamily = 'Open sans';

  const logoStyles = {
    'background-image': `url(${visaLogoURI})`,
    'background-repeat': 'no-repeat',
    height: '18px',
    margin: '0px auto 20px',
    width: '100px'
  };

  const userSelectStyles = {
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none',
    'user-select': 'none'
  };

  const logoTextStyles = {
    ...userSelectStyles,
    opacity: '0'
  };

  const textStyles = {
    ...userSelectStyles,
    'font-family': 'Open Sans',
    'font-size': '11px',
    'font-weight': 'bold',
    margin: '0px auto',
    'margin-bottom': '20px',
    'max-width': '290px'
  };

  const reopenButtonStyles = {
    ...userSelectStyles,
    background: 'none',
    border: '1px solid #FED931',
    color: '#FED931',
    padding: '5px 20px'
  };

  const cancelLinkStyles = {
    ...userSelectStyles,
    color: '#FED931',
    cursor: 'pointer',
    fontSize: '10px'
  };

  // prettier-ignore
  overlayContent.innerHTML = `
    <p style="${styleString(logoStyles)}">
      <a
        aria-label="Visa Checkout"
        id="visa-overlay-logo"
        style="${styleString(logoTextStyles)}"
        tabindex="0"
      >
        Visa Checkout Logo
      </a>
    </p>
    <p style="${styleString(textStyles)}" tabindex="0">
      ${isIOS ? data.message_iOS_safari : data.message}
    </p>
    <button
      id="visa-overlay-find-button"
      style="${styleString(reopenButtonStyles)}"
      tabindex="0"
    >
      ${isIOS ? data.restart_button : data.find_button}
    </button>
    <p style="margin-top:20px;">
      <a
        id="visa-overlay-cancel-link"
        style=${styleString(cancelLinkStyles)}
        tabindex="0"
      >
        ${data.cancel_and_return}
      </a>
    </p>
  `;

  popupOverlay.appendChild(overlayContent);

  const findButton = overlayContent.querySelector('#visa-overlay-find-button');

  if (findButton) {
    findButton.addEventListener('click', e => {
      e.stopPropagation();
      e.preventDefault();

      if (isIOS) {
        events.onRestartClick(e);
      } else {
        events.onFindClick(e);

        const popupWindow = popupRef.get();
        popupWindow?.focus();
      }
    });
  }

  const cancelLink = overlayContent.querySelector('#visa-overlay-cancel-link');

  if (cancelLink) {
    cancelLink.addEventListener('click', e => {
      e.stopPropagation();
      e.preventDefault();

      events.onCancelClick(e);
    });
  }
}

export type NavigatePopupOptions = {
  id: PopupWindowId;
  query: QueryObj;
  src: string;
};

export function navigatePopup(options: NavigatePopupOptions) {
  const popupEl = popupRef.get();

  if (popupEl) {
    const queryString = createQueryString(options.query);
    popupEl.location.href = `${options.src}${queryString}`;
  }
}
