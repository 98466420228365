import { mark, marks } from 'common/utils/instrumentation';
import onCheckoutComplete from 'sdk/effects/postmessage/handlers/checkout-complete';
import {
  getCheckoutAppType,
  getCheckoutWindowQuery,
  getCheckoutWindowType,
  getCheckoutWindowUrl,
  getWindowState
} from 'sdk/selectors';
import { Store } from 'types/redux/store';
import { CheckoutOptions } from './index';

function onCheckoutWindowLoaded(store: Store) {
  mark(marks.srcIframeLoaded);

  if (getCheckoutAppType(store.getState()) === 'RXO') {
    store.dispatch({
      message: {
        data: Date.now(),
        type: 'rxo:render'
      },
      target: 'checkout',
      type: '@@window/SEND_POSTMESSAGE'
    });
  }
}

export default function checkoutWeb({ onPrefillRequest, store }: CheckoutOptions): void {
  const state = store.getState();

  store.dispatch({
    type: '@@sdk/CHECKOUT_STARTED'
  });

  if (getWindowState(state, 'learn')) {
    store.dispatch({
      data: {
        id: 'learn'
      },
      type: '@@window/CLOSE_WINDOW'
    });
  }

  store.dispatch({
    data: {
      autofocus: true,
      id: 'checkout',
      onCancelCheckout() {
        onCheckoutComplete(
          store,
          {
            data: { callid: null },
            type: 'cancel'
          },
          onPrefillRequest
        );
      },
      onLoad: () => onCheckoutWindowLoaded(store),
      query: getCheckoutWindowQuery(state),
      /**
        * If window already exists, replace the URL instead of opening again.
        * This is the case for early click scenarios in which the popup is
          launched with a loader URL.
        */
      replaceUrl: Boolean(getWindowState(state, 'checkout')),
      src: getCheckoutWindowUrl(state),
      type: getCheckoutWindowType(state) === 'POPUP' ? 'popup' : 'modal'
    },
    type: '@@window/OPEN_WINDOW'
  });
}
