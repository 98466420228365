import { SRCOptimizelyDecision } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

// After launching the /vcop iframe, we load optimizely, which may change the
// final routing decision of RXO, SRC, or a non-INO VDCP flow. Once the
// optimizely decision is made, we postmessage the value back to the Visa
// adapter script (system-sdk) and the merchant SDK, which should use this final
// value for any subsequent flow-based logic.
export default function srcOptimizelyDecision(
  store: MiddlewareAPI,
  message: SRCOptimizelyDecision
) {
  store.dispatch({
    data: message.data.appFlow,
    type: '@@sdk/OPTIMIZELY_FLOW'
  });
}
