import hybridLogger from 'common/utils/hybrid-logger';
import { MiddlewareAPI } from 'types/redux';
import { PrefillCallback } from 'types/sdk';
import checkoutComplete from './checkout-complete';

export default function sdkliteBackPressed(
  store: MiddlewareAPI,
  onPrefillRequest: PrefillCallback
) {
  hybridLogger('visa.sdklite.onbackpressed');

  checkoutComplete(
    store,
    {
      data: { callid: null },
      type: 'cancel'
    },
    onPrefillRequest
  );
}
