import { CENTRAL_HANDLER, MESSAGE_HANDLERS_PATH, RECEIVE_MESSAGE_PATH } from '../constants/message';
import {
  ForwardMessage,
  ForwardResult,
  ForwardResultData,
  IncomingForwardMessage,
  IncomingMessage,
  JSONValue,
  Message,
  MessageEvent,
  MessageHandler,
  MessageListenerCallback,
  MessageResponse,
  OutgoingMessage,
  ReceiveMessageFn,
  ResultData
} from '../types';
import { PendingMessage, cleanJSON, isPromiseLike, setDeep } from '../utils';

const globalMessageHandlers: ReceiveMessageFn[] =
  window.VisaCheckoutSDK?.InboundHybridHandlers?.messageHandlers ?? [];
setDeep(window, MESSAGE_HANDLERS_PATH, globalMessageHandlers);

/** A globally available wrapper around each HybridPlugin instance's
 * receiveMessage function. Instances are good, but a webview needs a
 * global space to funnel into.
 *
 * @param message - The incoming message from the native layer.
 */
function _receiveWindowMessage(message: IncomingMessage) {
  // These are being set in HybridPlugin() constructor
  // via globalMessageHandlers.push().
  globalMessageHandlers.forEach(receiveHandler => {
    receiveHandler(message);
  });
}

setDeep(window, RECEIVE_MESSAGE_PATH, _receiveWindowMessage);

export class HybridPlugin {
  pendingMessages: Record<string, PendingMessage>;
  forwardListeners: Record<string, MessageListenerCallback>;

  constructor() {
    this.pendingMessages = {};
    this.forwardListeners = {};
    globalMessageHandlers.push(this.receiveMessage);
  }

  /**
   * The central message that coordinates the format of the message sent to the
   * native layer listener. Everything should pass through the 'visaMessage'
   * interface, and the layer will determine what logic to perform based on
   * `message.name`.
   *
   * @param message - The message sent to the native layer. Other root level
   * options may be added to communicate with the intended receiver of the
   * message.
   */
  sendMessage<T extends Message>(message: T): Promise<ResultData<T>>;
  sendMessage(message: Message): Promise<ResultData<Message>> {
    return new Promise((resolve, reject) => {
      const globalOutHandler = HybridPlugin._handler(CENTRAL_HANDLER);
      if (!globalOutHandler) {
        return reject(new Error('There is no native message handler setup'));
      }

      // The callback that is responsible for rejecting/resolving the Promise.
      // It will be invoked within the `receiveMessage` method.
      const pendingMessage = new PendingMessage((response: MessageResponse) =>
        response.error ? reject(response.error) : resolve(response.data ?? null)
      );

      // Don't store forwarded results since they are resolved with the original
      // forward event.
      const isForwardedResult = 'eventName' in message && message.eventName === 'result';
      if (!isForwardedResult) {
        this.pendingMessages[pendingMessage.id] = pendingMessage;
      }

      return globalOutHandler({ ...message, id: pendingMessage.id });
    });
  }

  /**
   * Receives a message from the native layer. The response will be
   * either a forward message, which needs to be responded to, or it
   * will be a "result" from a previous `sendMessage` which will end the
   * ack cycle of send/receive.
   *
   * @param message The message received from the native layer.
   */
  receiveMessage = (message: IncomingMessage) => {
    switch (message.name) {
      case 'result':
        return this._receiveMessageResponse(message);
      case 'forward':
        if (message.eventName === 'result') {
          return this._receiveMessageResponse(message.data);
        }
        return this._receiveForwardEvent(message);
    }

    return undefined;
  };

  /**
   * A response received after sending a sendMessage message to
   * the native layer. For native-only messages, this response will
   * be generated by the native layer. Otherwise, it may be generated
   * by other webviews through `receivedForwardEvent()`.
   *
   * @param response The response received from the native layer.
   */
  _receiveMessageResponse = (response: MessageResponse) => {
    const pendingMessage = this.pendingMessages[response.id];
    if (!pendingMessage) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.pendingMessages[pendingMessage.id];
    pendingMessage.callback(response);
  };

  /**
   * Sends a message to connected webviews that are in a different context.
   *
   * @param eventName - An event name which other receivers will listen for
   * (using the `on()` method).
   * @param data - Extra data sent to the receivers of the forwarded message.
   */
  forwardEvent<T extends MessageEvent>(
    eventName: T,
    data?: JSONValue
  ): Promise<ForwardResultData<T>>;
  forwardEvent(
    eventName: MessageEvent,
    data?: JSONValue
  ): Promise<ForwardResultData<MessageEvent>> {
    return this.sendMessage({
      data,
      eventName,
      name: 'forward'
    });
  }

  /**
   * Sends a result to connected webviews that are in a different context.
   *
   * @param data - The data returned from a forwarded message listener.
   */
  forwardResult = (data: ForwardResult['data']) => {
    return this.sendMessage({
      data,
      eventName: 'result',
      name: 'forward'
    });
  };

  /**
   * A response received from a webview context that called `forwardEvent()`.
   * This method internally handles these events and will pass the message
   * information to any listeners added using the `on()` method.
   *
   * @param message - The incoming forward message forwarded from
   * the native layer.
   */
  _receiveForwardEvent = (message: IncomingForwardMessage) => {
    const id = message.id;
    const reject = this._rejectForward;
    const resolve = this._resolveForward;

    const listener = this.forwardListeners[message.eventName];

    if (!listener) {
      return;
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = listener(message.data as any);
      if (isPromiseLike(result)) {
        result.then(d => resolve(id, d)).catch(e => reject(id, e));
      } else if (result instanceof Error) {
        reject(id, result);
      } else {
        resolve(id, result);
      }
    } catch (error) {
      reject(id, error);
    }
  };

  _resolveForward = (id: string, data: ForwardResultData<ForwardMessage['eventName']>) => {
    this.forwardResult({ data, id });
  };

  _rejectForward = (id: string, err: unknown) => {
    const error = err instanceof Error ? err.message : `${err}`;
    this.forwardResult({ error, id });
  };

  /**
   * Listens for forwarded events from other webviews for cross-webview
   * communication. There can be a maximum of only one (1) listener for each
   * `event` type.
   *
   * @param event - The value of the `eventName` parameter passed to
   * `forwardEvent()`.
   * @param listener - A listening callback with that will be invoked when
   * the event is received. If you return a `Promise`, the result of that
   * `Promise` will be passed to the calling webview. If you return an `Error`
   * or your listener throws an exception, a `Promise` rejection will be
   * issued. Lastly, if you return any other type, including `undefined`, that
   * value will be resolved immediately.
   */
  on = <E extends MessageEvent>(event: E, listener: MessageListenerCallback<E>) => {
    this.forwardListeners[event] = listener;
    return this;
  };

  removeListener = (event: MessageEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.forwardListeners[event];
  };

  /**
   * This method will obtain the interface function provided by the iOS/Android
   * webview.
   * For iOS, this is currently `window.webkit.messageHandlers.visaMessage`.
   * For Android, this is currently `window.VisaAndroid.visaMessage`.
   */
  static _handler = (name: MessageHandler) => {
    const androidHandler = window.VisaAndroid?.[name]?.bind(window.VisaAndroid);
    if (androidHandler) {
      // Android requires a JSON string
      return (message: OutgoingMessage) => androidHandler(JSON.stringify(message));
    }

    const iosHandler = window.webkit?.messageHandlers?.[name]?.postMessage.bind(
      window.webkit?.messageHandlers?.[name]
    );

    if (iosHandler) {
      // iOS doesn't like invalid JSON values
      return (message: OutgoingMessage) => iosHandler(cleanJSON(message));
    }

    return undefined;
  };
}
