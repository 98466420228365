import HybridSDK from 'common/hybrid';
import { BrowserProtocol } from 'types/gtm';
import getMerchantUrl from './get-merchant-url';

export function getUserAgent() {
  const ua = window.navigator.userAgent;
  return ua ? ua.toLowerCase() : '';
}

function getIOSVersion() {
  const ua = getUserAgent();
  const matches = /(?:ipad|iphone) os ([0-9]+)/.exec(ua);
  return matches ? matches[1] : '';
}

function getAndroidVersion() {
  const ua = getUserAgent();
  const matches = /android ([0-9.]+)/.exec(ua);
  return matches ? matches[1] : '';
}

/**
 * Compares two software version numbers (e.g. "1.7.1" > "0.8.10")
 * Credit: https://gist.github.com/TheDistantSea/8021359
 *
 * @param {string} v1 The first version to be compared.
 * @param {string} v2 The second version to be compared.
 * @returns {number|NaN}
 */
function compareVersion(v1: string, v2: string): number {
  let v1parts: Array<string | number> = v1.split('.');
  let v2parts: Array<string | number> = v2.split('.');

  while (v1parts.length < v2parts.length) v1parts.push('0');
  while (v2parts.length < v1parts.length) v2parts.push('0');

  v1parts = v1parts.map(Number);
  v2parts = v2parts.map(Number);

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

export function getBrowserProtocol(): BrowserProtocol {
  return getMerchantUrl().split(':')[0] as 'http' | 'https' | 'undefined';
}

export function isAndroidMobile() {
  const ua = getUserAgent();
  return (
    ua.indexOf('android') > -1 && ua.indexOf('mozilla/5.0') > -1 && ua.indexOf('applewebkit') > -1
  );
}

export function isSafari() {
  const ua = getUserAgent();
  return (
    ua.indexOf('safari') > -1 &&
    ua.indexOf('chrome') === -1 &&
    ua.indexOf('crios') === -1 &&
    ua.indexOf('fxios') === -1
  );
}

export function isChrome() {
  const ua = getUserAgent();
  // Some versions of Edge will have a window.chrome object
  return Boolean(window.chrome) && ua.indexOf('edge') === -1;
}

export function getChromeVersion() {
  const ua = getUserAgent();
  const raw = ua.match(/Chrom(e|ium)\/([0-9]+)\./i);
  return raw ? parseInt(raw[2], 10) : false;
}

export function isMobileSafari() {
  const ua = getUserAgent();
  const iOS = /iphone|ipod|ipad/i.test(ua);
  const webkit = /WebKit/i.test(ua);
  const isFirefox = /Firefox|FxiOS/i.test(ua);
  return iOS && webkit && !isFirefox && !/CriOS/i.test(ua);
}

export function isSamsungBrowser() {
  const ua = getUserAgent();
  return ua.match(/SamsungBrowser/i);
}

// US57100
// https://bugs.chromium.org/p/chromium/issues/detail?id=760780
export function isChromeOnIOS() {
  const ua = getUserAgent();
  return /iphone|ipod|ipad/i.test(ua) && /CriOS/i.test(ua);
}

export function isIOSOrAndroid() {
  const ua = getUserAgent();
  return /iPad|iPhone|iPod|Android/.test(ua) && !window.MSStream;
}

export function isIOS() {
  return isMobileSafari() || isChromeOnIOS();
}

export function isIE() {
  const ua = getUserAgent();
  // Regex taken from:
  // https://github.com/lancedikson/bowser/blob/master/src/parser-browsers.js
  // Note that Opera includes MSIE in their UA string:
  // https://github.com/lancedikson/bowser/blob/master/test/acceptance/useragentstrings.yml
  // There are potentially other false positives as well.
  return !/opera/i.test(ua) && /msie|trident/i.test(ua);
}

export function isEdge() {
  const ua = getUserAgent();
  // Regex taken from:
  // https://github.com/lancedikson/bowser/blob/master/src/parser-browsers.js
  return /\sedg\//i.test(ua) || /edg([ea]|ios)/i.test(ua);
}

export function isBlacklistedBrowser() {
  const iOSVersion = getIOSVersion();
  const androidVersion = getAndroidVersion();

  return (
    isIE() ||
    (iOSVersion && compareVersion(iOSVersion, '11') === -1) ||
    (androidVersion && compareVersion(androidVersion, '4.4.3') === -1) ||
    !document.querySelectorAll || // Firefox 3.5+
    !window.JSON || // IE8+, Safari 4+
    !window.postMessage
  );
}

export function isBrowserInList(list: Array<string>) {
  const ua = getUserAgent();

  for (const browserString of list) {
    if (ua.indexOf(browserString.toLowerCase()) !== -1) {
      return true;
    }
  }

  return false;
}

export function isSDKLite() {
  const ua = getUserAgent();

  return (
    ua.indexOf('vco_sdk') !== -1 ||
    // For SDK Lite 6.x integrations, only the checkout webview has vco_sdk in
    // the user agent string. For the button webview, we must must use a
    // different heuristic.
    (HybridSDK.isHybridShimConfirmed() &&
      window.location.href.indexOf('/checkout-widget/mobile-button?') !== -1)
  );
}

export function isHybridSDKLite() {
  const ua = getUserAgent();
  return ua.indexOf('vco_hsdk') !== -1;
}

export function isIOSSDKLite() {
  return isSDKLite() && isMobileSafari();
}

export function isUnknownBrowser() {
  const ua = getUserAgent();
  const knownBrowserMatches = ua.match(
    /(opera|android|chrome|vivaldi|micromessenger|samsungbrowser|googlebot|safari|tizen|applewebkit|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
  );

  const isUnknown = !knownBrowserMatches || knownBrowserMatches.length === 0;

  // Unknown browser without cookie support
  if (isUnknown && !window.navigator.cookieEnabled) {
    return true;
  }

  return false;
}

export function isAndroidWebview() {
  const ua = getUserAgent();
  // https://developer.chrome.com/multidevice/user-agent#webview_user_agent
  return /wv/i.test(ua);
}

export function isIOSWebview() {
  const ua = getUserAgent();
  return /iphone|ipod|ipad/i.test(ua) && !/safari/i.test(ua);
}

export function isUIWebview() {
  const ua = getUserAgent();
  // prettier-ignore
  const hasPerformanceAPI = (
    window.performance && window.performance.timing
    && (window.performance.mark && typeof window.performance.mark === 'function')
    && (window.performance.measure && typeof window.performance.measure === 'function')
    && (window.performance.getEntries && typeof window.performance.getEntries === 'function')
    && (window.performance.getEntriesByName && typeof window.performance.getEntriesByName === 'function')
    && (window.performance.getEntriesByType && typeof window.performance.getEntriesByType === 'function')
    && (window.performance.clearMeasures && typeof window.performance.clearMeasures === 'function')
  );

  return (isIOSWebview() && !hasPerformanceAPI) || /uiwebview/i.test(ua);
}

// Similar logic to HybridSDK.isHybridShimConfirmed(), except also returns true
// for any third party webviews. Any unknown browsers that don't accept cookies
// are also considered third party webviews.
export function isNativeWebview() {
  if (isAndroidWebview() || isIOSWebview()) {
    return true;
  }

  return isUnknownBrowser();
}

export function isHybridManualCheckout() {
  return window.location.href.indexOf('manualCheckout') !== -1;
}
