import { localStorage } from 'common/utils/storage';
import { destroyIframe, injectIframe, reloadIframe, revealPreloadedIframe } from 'sdk/dom/iframe';
import { preloadImage } from 'sdk/dom/image';
import { closePopup, navigatePopup, openPopup, renderOverlayText } from 'sdk/dom/popup';
import { injectScript, removeScript } from 'sdk/dom/script';
import { addBodyClass } from 'sdk/dom/style';
import { getConfigDataProperty, getWindowOption, getWindowType } from 'sdk/selectors';
import { Action, Middleware } from 'types/redux';

/**
 * Handles non-button DOM operations on the merchant page, including script and
 * style injection, iframe and popup interactions, and setting and removing
 * localStorage.
 */
const windowMiddleware: Middleware = store => next => (action: Action) => {
  // need state prior to the current action
  const state = store.getState();

  switch (action.type) {
    case '@@window/INJECT_SCRIPT':
      injectScript({
        ...action.data,
        onLoad(e) {
          /**
            * Track script loaded state in (state.script[scriptId].loaded). This
              is useful for triggering behavior on script load that also depends
              on other async events, like the resolution of merchant config. See
              sdk/lifecycle/init/hybrid-checkout.ts for example usage.
            */
          store.dispatch({
            data: { id: action.data.id },
            type: '@@window/SCRIPT_LOADED'
          });

          action.data.onLoad?.(e);
        }
      });
      break;
    case '@@window/REMOVE_SCRIPT':
      removeScript(action.data);
      break;
    case '@@window/OPEN_WINDOW': {
      const isPreloaded = getWindowType(state, action.data.id) === 'preload';

      if (action.data.type === 'popup') {
        // App may be preloaded in an iframe even if it launches in a popup. For
        // this case, we want to remove the preloaded iframe before launching.
        if (isPreloaded) {
          destroyIframe({
            id: action.data.id,
            noscript: getWindowOption(state, action.data.id, 'noscript') || false,
            type: 'preload'
          });
        }

        openPopup(action.data);

        break;
      }

      if (isPreloaded) {
        if (action.data.type === 'modal') {
          revealPreloadedIframe(action.data);
        } else if (action.data.type === 'preload') {
          // App can be preloaded multiple times before launching. This only
          // happens for merchants who call V.init() multiple times, or those
          // who call V.setOptions() after V.init() but prior to app launch. For
          // these cases, reload the preloaded iframe with the up-to-date query.
          reloadIframe(action.data);
        }

        break;
      }

      // Add sandbox attribute to checkout iframe only if metadata feature flag
      // "vdcp.addSandboxAttr" is "true".
      if (action.data.id === 'checkout' && getConfigDataProperty(state, 'vdcpAddSandboxAttr')) {
        action.data.sandbox = true;
      }

      injectIframe(action.data);

      break;
    }
    case '@@window/CLOSE_WINDOW': {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const windowType = getWindowType(state, action.data.id)!;
      windowType === 'popup'
        ? closePopup()
        : destroyIframe({
            id: action.data.id,
            noscript: getWindowOption(state, action.data.id, 'noscript') || false,
            type: windowType
          });
      break;
    }
    case '@@window/RELOAD':
      reloadIframe(action.data);
      break;
    case '@@window/NAVIGATE':
      const windowType = getWindowType(state, action.data.id);
      if (windowType === 'popup') {
        navigatePopup(action.data);
      }
      break;
    case '@@window/PRELOAD_IMAGE':
      preloadImage(action.data.url);
      break;
    case '@@window/RECEIVED_POPUP_OVERLAY_TEXT':
      renderOverlayText(action.data, action.events);
      break;
    case '@@window/ADD_BODY_CLASS':
      addBodyClass(action.data);
      break;
    case '@@window/REMOVE_LOCALSTORAGE':
      localStorage.removeItem(action.data.key);
      break;
    case '@@window/SET_LOCALSTORAGE':
      const { key, value } = action.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      localStorage.setItem(key, value as any);
      break;
  }

  return next(action);
};

export default windowMiddleware;
