import { VSBInitResponse } from 'types/orchestration';
import { Action } from 'types/redux';

export type OrchState = {
  error: string | null;
  status: 'error' | 'unloaded' | 'loading' | 'loaded';
  vsbInitResponse: VSBInitResponse | null;
};

const defaultOrchState: OrchState = {
  error: null,
  status: 'unloaded',
  vsbInitResponse: null
};

export default function orchestrationReducer(
  state: OrchState = defaultOrchState,
  action: Action
): OrchState {
  switch (action.type) {
    case '@@orchestration/ERROR':
      return { ...state, error: action.error, status: 'error' };
    case '@@orchestration/INIT':
      return {
        ...state,
        // Keep loaded status if orchestration layer is re-initialized, e.g.
        // following a visa.sdklite.update-payment-info postmessage or
        // additional calls to V.init()
        status: state.status === 'loaded' ? 'loaded' : 'loading'
      };
    case '@@orchestration/VSB_INIT_COMPLETE':
      return {
        ...state,
        vsbInitResponse: action.data
      };
    case '@@orchestration/READY':
      return { ...state, status: 'loaded' };
    default:
      return state;
  }
}
