import { GTMEvent } from 'types/gtm';
import { PushGTMData } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function pushGTMData(store: MiddlewareAPI, message: PushGTMData) {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const data = { ...message.data } as GTMEvent & {
    vInitRequest?: Record<string, unknown>;
  };

  // VCO-12541 - Prefixing event_label here instead of in Native codebase to
  // avoid another security assessment.
  // https://stash.trusted.visa.com:7990/projects/THINWALLET/repos/checkout-widget/pull-requests/11897
  if (data.event === 'Spinner Screen Load') {
    data.event_label = `Spinner Screen Load - ${data.event_label}`;
  }

  delete data.vInitRequest;

  store.dispatch({
    data,
    type: '@@window/SEND_GTM_EVENT'
  });
}
