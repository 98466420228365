import { WindowId } from 'types/sdk';

export const IFRAME_SANDBOX_VALUE =
  'allow-same-origin allow-scripts allow-forms allow-top-navigation allow-popups';

export const MODAL_TAKEOVER_MIN_HEIGHT = 600;
export const MODAL_TAKEOVER_MIN_WIDTH = 480;
export const MODAL_HEIGHT = '600px';
export const MODAL_WIDTH = '392px';

type IframeIdMap = {
  [Id in WindowId]: string;
};

export const iframeIdMap: IframeIdMap = {
  // Note: system-sdk also has "vcop-src-frame" hard-coded
  checkout: 'vcop-src-frame',
  config: 'CheckoutConfig',
  gtm: 'VmeGtm',
  learn: 'VLearnMore',
  // Note: the src-system iframe is added by the visa-sdk rather than
  // the merchant SDK. In PSP integrations, the merchant loads the visa-sdk
  // script, but not the merchant SDK.
  'src-system': 'vcop-src-system-frame',
  thm: 'threatmetrix_iframe'
};

export type PopupWindowId = 'checkout';

type PopupIdMap = {
  [Id in PopupWindowId]: string;
};

export const popupIdMap: PopupIdMap = {
  checkout: 'VmeCheckoutPopUp'
};
