export function injectGlobalStyle(id: string, styles: string) {
  // do not inject the same styles more than once
  if (document.getElementById(id)) {
    return;
  }

  const styleEl = document.createElement('style');
  styleEl.id = id;
  styleEl.appendChild(document.createTextNode(styles));
  document.head.insertBefore(styleEl, document.head.firstChild);
}

export function removeGlobalStyle(id: string) {
  const styleEl = document.getElementById(id);
  if (styleEl && styleEl.parentNode) {
    styleEl.parentNode.removeChild(styleEl);
  }
}

export function addBodyClass(className: string) {
  document.body.className = `${document.body.className} ${className}`;
}

export function removeBodyClass(className: string) {
  document.body.className = document.body.className.replace(` ${className}`, '');
}
