import hybridLogger from 'common/utils/hybrid-logger';
import { getIsSRCBranded } from 'sdk/selectors';
import { SDKLiteSyncCookies } from 'types/messages/incoming';
import { Store } from 'types/redux';

export default function sdkliteSyncCookies(store: Store, message: SDKLiteSyncCookies) {
  hybridLogger('src:sdklite:sync-cookies');

  store.dispatch({
    data: message.data.cookies,
    type: '@@hybrid/SYNC_COOKIES'
  });

  store.dispatch({
    message: {
      data: {
        isLegacyButton: getIsSRCBranded(store.getState())
      },
      type: 'src:sdklite:synced-cookies'
    },
    target: 'checkout',
    type: '@@window/SEND_POSTMESSAGE'
  });
}
