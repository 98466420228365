import { Message } from 'types/messages';

export const DATA_DELIM = '--'; // seperator within a message
export const MESSAGE_DELIM = '+-+-+'; // seperator for multiple messages

function legacyParseMessage(message: string): Message | null {
  const [type, ...rawMessageParts] = message.split(DATA_DELIM);

  const messageParts: Array<Record<string, unknown>> = [];
  let prevData = '';

  // Special logic to parse postmessages with a DATA_DELIM in their data, e.g. apikey/panjwe
  rawMessageParts.forEach(nextData => {
    if (prevData) {
      nextData = prevData + DATA_DELIM + nextData;
    }

    try {
      const parsedData = JSON.parse(nextData);
      messageParts.push(parsedData);
      prevData = '';
    } catch (e) {
      prevData = nextData;
    }
  });

  if (!messageParts.length) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return {
    data: messageParts[0],
    error: messageParts[1] || null,
    sdkOptions: messageParts[2] || null,
    type
  } as Message;
}

function legacyParse(e: MessageEvent): Message | Array<Message> | null {
  if (e.data.indexOf(MESSAGE_DELIM) !== -1) {
    const messages = e.data.split(MESSAGE_DELIM);
    return messages.map(legacyParseMessage).filter(Boolean);
  }

  if (e.data.indexOf(DATA_DELIM) !== -1) {
    return legacyParseMessage(e.data);
  }

  return null;
}

export default function parseMessage(e: MessageEvent): Message | Array<Message> | null {
  if (!e || e.data === null || e.data === undefined) {
    return null;
  }

  if (typeof e.data === 'object') {
    return e.data;
  }

  if (e.data.charAt(0) === '{' || e.data.charAt(0) === '[') {
    return JSON.parse(e.data);
  }

  return legacyParse(e);
}
