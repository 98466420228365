import { getIsCheckoutQueued } from 'sdk/selectors';
import { ConfigGTMMetrics } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function configGTMMetrics(store: MiddlewareAPI, message: ConfigGTMMetrics) {
  const { start, end } = message.data;
  const state = store.getState();
  const configTiming = getIsCheckoutQueued(state) ? 'After' : 'Before';

  // VCO-13975 - cache reported timing data to allow `flow` to settle before spawning GTM event downstream
  store.dispatch({
    data: {
      configTiming,
      end,
      start
    },
    type: '@@config/CONFIG_TIMING_RECEIVED'
  });
}
