import { MiddlewareAPI } from 'types/redux';

export default function abDataRequest(store: MiddlewareAPI) {
  store.dispatch({
    message: {
      // SDK 1.0 only ever sends an empty object
      data: {},
      type: 'setABData'
    },
    target: 'checkout',
    type: '@@window/SEND_POSTMESSAGE'
  });
}
