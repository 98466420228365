import { PopupOverlayText } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function popupOverlayText(store: MiddlewareAPI, message: PopupOverlayText) {
  store.dispatch({
    data: message.data,
    events: {
      onCancelClick: () => {
        store.dispatch({
          message: {
            type: 'ghostLayerCancelReturnClick'
          },
          target: 'checkout',
          type: '@@window/SEND_POSTMESSAGE'
        });
      },
      onFindClick: () => {
        store.dispatch({
          message: {
            type: 'ghostLayerFindButtonClick'
          },
          target: 'checkout',
          type: '@@window/SEND_POSTMESSAGE'
        });
      },
      onRestartClick: () => {
        store.dispatch({
          message: {
            type: 'mobileSafariPopupRestart'
          },
          target: 'checkout',
          type: '@@window/SEND_POSTMESSAGE'
        });

        setTimeout(() => {
          store.dispatch({
            data: { id: 'checkout' },
            type: '@@window/CLOSE_WINDOW'
          });

          setTimeout(() => {
            store.dispatch({
              type: '@@sdk/CHECKOUT_QUEUED'
            });
          }, 150);
        }, 50);
      }
    },
    type: '@@window/RECEIVED_POPUP_OVERLAY_TEXT'
  });
}
