import HybridSDK from 'common/hybrid';
import getWindowById from 'common/utils/get-window-by-id';
import hybridLogger from 'common/utils/hybrid-logger';
import { iframeIdMap } from 'sdk/constants/iframe';
import { getHybridCheckoutWindowName } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';

export default function sdkliteFetchUserInfo(store: MiddlewareAPI) {
  hybridLogger('fetchUserInfo');

  HybridSDK.get('userInfo')
    .then(payload => {
      hybridLogger('fetchUserInfo:promiseResolved');

      store.dispatch({
        message: {
          data: payload || {},
          type: 'src:sdklite:fetched-user-info'
        },
        target: 'checkout',
        type: '@@window/SEND_POSTMESSAGE'
      });

      const state = store.getState();
      const checkoutWindow = getWindowById('checkout');

      store.dispatch({
        message: {
          event: 'src:sdklite:fetched-user-info',
          iframeId: iframeIdMap.checkout,
          payload: payload || {},
          window_name: getHybridCheckoutWindowName(state),
          window_url: checkoutWindow && checkoutWindow.location.href
        },
        type: '@@hybrid/LOG_EVENT'
      });
    })
    .catch(() => {
      hybridLogger('fetchUserInfo:promiseResolved:error');

      store.dispatch({
        message: {
          data: {},
          type: 'src:sdklite:fetched-user-info'
        },
        target: 'checkout',
        type: '@@window/SEND_POSTMESSAGE'
      });

      const state = store.getState();
      const checkoutWindow = getWindowById('checkout');

      store.dispatch({
        logLevel: 'ERROR',
        message: {
          event: 'src:sdklite:fetched-user-info',
          iframeId: iframeIdMap.checkout,
          window_name: getHybridCheckoutWindowName(state),
          window_url: checkoutWindow && checkoutWindow.location.href
        },
        status: 400,
        type: '@@hybrid/LOG_EVENT'
      });
    });
}
