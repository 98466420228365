import { MerchantConfigTiming, MerchantResponse } from 'types/api/merchant-config';
import { ConfigData } from 'types/config';
import { Action } from 'types/redux';

export type ConfigState = {
  configData: ConfigData | null;
  configTiming: MerchantConfigTiming | null;
  merchantConfigResponse: MerchantResponse | null;
};

const defaultConfigState = {
  configData: null,
  configTiming: null,
  merchantConfigResponse: null
};

export default function configReducer(
  state: ConfigState = defaultConfigState,
  action: Action
): ConfigState {
  switch (action.type) {
    case '@@config/CONFIG_DATA_RECEIVED':
      return {
        ...state,
        configData: action.data
      };
    case '@@config/CONFIG_TIMING_RECEIVED':
      return {
        ...state,
        configTiming: action.data
      };
    case '@@config/MERCHANT_CONFIG_SUCCESS':
      return {
        ...state,
        merchantConfigResponse: action.data
      };
    case '@@config/UPDATE_SDK_PARAMS':
      return {
        ...state,
        configData: {
          // state.configData will always be defined by the time this action is dispatched
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.configData!,
          rememberMeType: action.data.rememberme_type
        }
      };
    case '@@config/UPDATE_LOCALE_COOKIE':
      return {
        ...state,
        configData: {
          // state.configData will always be defined by the time this action is dispatched
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...state.configData!,
          cookieCountry: action.data.countryCode,
          cookieLocale: action.data.locale
        }
      };
    default:
      return state;
  }
}
