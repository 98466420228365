/* START GENAI@GHCOPILOT */
import { LocaleUnderscore } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */

const localeRegex = /^[a-z]{2}_[A-Z]{2}$/;

function isNormalizedLocale(locale: string): locale is LocaleUnderscore {
  return localeRegex.test(locale);
}

/**
 * Returns an ISO formatted locale (e.g. en_US)
 */
export default function normalizeLocale(
  locale: string | null | undefined
): LocaleUnderscore | null {
  if (!locale || locale.length < 2) {
    return null;
  }

  if (isNormalizedLocale(locale)) {
    return locale;
  }

  if (locale.length === 2) {
    return locale as LocaleUnderscore;
  }

  const localeArr = locale.split(/[-_]+/);
  const languageCode = localeArr[0].toLowerCase();
  const countryCode = localeArr[localeArr.length - 1].toUpperCase();
  const normalizedLocale = `${languageCode}_${countryCode}`;

  return isNormalizedLocale(normalizedLocale) ? normalizedLocale : null;
}
