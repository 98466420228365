import { CheckoutComplete as HybridCheckoutComplete } from '@visa/hybrid-plugin';
import { CONFIG_WINDOW_URL, GTM_WINDOW_URL, ORCH_SCRIPT_URL } from 'common/constants/window';
import { gtmLoaded, mark, marks } from 'common/utils/instrumentation';
import {
  getCheckoutResponse,
  getConfigIframeQuery,
  getGTMIframeQuery,
  getIntegrationType,
  getIsCheckoutReadyToLaunch,
  getIsHybridAppReady,
  getMerchantResponse,
  getOrchestrationConfig
} from 'sdk/selectors';
import { getIsScriptLoaded } from 'sdk/selectors/script';
import observeStore from 'sdk/utils/observe-store';
import { Store } from 'types/redux';
import { PrefillCallback, VOptions } from 'types/sdk';

let removeCheckoutCompleteListener = () => {};

type InitHybridCheckoutOptions = {
  onPrefillRequest: PrefillCallback;
  store: Store;
  vOptions: VOptions;
};

export default function initHybridCheckout({
  onPrefillRequest,
  store,
  vOptions
}: InitHybridCheckoutOptions): void {
  store.dispatch({
    data: vOptions,
    type: '@@sdk/CHECKOUT_SETUP'
  });

  store.dispatch({
    type: '@@hybrid/REGISTER_DEFAULT_STRINGS'
  });

  const state = store.getState();

  if (getIntegrationType(state) === 'sdk-lite-cross-app') {
    /**
      * For typical web integrations, we wait for merchant config to resolve
      before injecting the orchestration script [1]. This is because merchant
      config can determine that we launch RXO, in which case we don't inject
      the orchestration script at all. However, for Hybrid Cross App
      integrations, when sdk.js is loaded in the checkout webview
      (sdk-lite.html), we must load the orchestration script as early as
      possible in order to avoid timing issues with the native code. In this
      case, we aren't required to wait for merchant config before injecting the
      script, because Cross App integrations will always load SRC over RXO.

      * Even though we are able to inject the script earlier, we still must allow
      merchant config to resolve before initializing the orchestration SDK[2]
      with the proper configs.
      
      - [1] see effects/postmessage/handlers/merchant-config.ts
      - [2] by calling `new OrchAdapter(orchConfig)`. Configs come from
        getOrchestrationConfig selector. See middleware/orchestration.ts and
        dom/orchestration.ts for more implementation details.
      */
    const unsubscribeOrchInit = observeStore(
      store,
      state => Boolean(getMerchantResponse(state) && getIsScriptLoaded(state, 'orchestration')),
      isOrchReadyToInit => {
        if (isOrchReadyToInit) {
          store.dispatch({
            data: getOrchestrationConfig(store.getState()),
            type: '@@orchestration/INIT'
          });

          unsubscribeOrchInit();
        }
      }
    );

    mark(marks.orchScriptLoadStart);

    store.dispatch({
      data: {
        id: 'orchestration',
        onError(e) {
          store.dispatch({
            error: e.toString(),
            type: '@@orchestration/ERROR'
          });

          unsubscribeOrchInit();
        },
        onLoad() {
          mark(marks.orchScriptLoadEnd);
        },
        src: ORCH_SCRIPT_URL
      },
      type: '@@window/INJECT_SCRIPT'
    });
  }

  store.dispatch({
    data: {
      id: 'gtm',
      onLoad: gtmLoaded,
      query: getGTMIframeQuery(),
      src: GTM_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });

  store.dispatch({
    data: {
      id: 'config',
      query: getConfigIframeQuery(state),
      src: CONFIG_WINDOW_URL,
      type: 'hidden'
    },
    type: '@@window/OPEN_WINDOW'
  });

  store.dispatch({
    onProfile(thmData) {
      store.dispatch({
        data: thmData,
        type: '@@thm/LAUNCH'
      });
    },
    type: '@@hybrid/PROFILE_DEVICE'
  });

  const unsubscribeCheckoutReady = observeStore(store, getIsCheckoutReadyToLaunch, isReady => {
    if (isReady) {
      if (getIntegrationType(store.getState()) === 'sdk-lite-cross-app') {
        store.dispatch({
          type: '@@hybrid/CHECKOUT_QUEUED'
        });
      } else {
        store.dispatch({
          events: {
            launchCheckout() {
              store.dispatch({
                // eslint-disable-next-line sonarjs/no-identical-functions
                onProfile(thmData) {
                  store.dispatch({
                    data: thmData,
                    type: '@@thm/LAUNCH'
                  });
                },
                type: '@@hybrid/PROFILE_DEVICE'
              });

              store.dispatch({
                events: { onPrefillRequest },
                type: '@@hybrid/CHECKOUT_LAUNCH'
              });
            }
          },
          type: '@@hybrid/CHECKOUT_READY'
        });
      }

      unsubscribeCheckoutReady();
    }
  });

  removeCheckoutCompleteListener();

  const checkoutCompletePromise: Promise<HybridCheckoutComplete> = new Promise(resolve => {
    removeCheckoutCompleteListener = observeStore(
      store,
      getCheckoutResponse,
      (currResponse, prevResponse) => {
        // Call merchant callbacks when checkout response becomes available
        if (!prevResponse && currResponse) {
          resolve(currResponse);
        }
      }
    );
  });

  // Launch checkout immediately. Button click is already implied since we are
  // running in the checkout webview.
  store.dispatch({
    onRender() {
      store.dispatch({
        type: '@@hybrid/CHECKOUT_QUEUED'
      });

      // For early clicks, wait for the `sdklite:appready` message from the
      // checkout window before dispatching `sdklite:buttonclick`. See
      // `handlers/sdklite-app-ready.ts`.
      if (getIsHybridAppReady(store.getState())) {
        store.dispatch({
          message: { type: 'sdklite:buttonclick' },
          target: 'checkout',
          type: '@@window/SEND_POSTMESSAGE'
        });
      }

      return checkoutCompletePromise;
    },
    type: '@@hybrid/RENDER'
  });
}
