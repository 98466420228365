import hybridLogger from 'common/utils/hybrid-logger';
import {
  PartialPaymentRequest,
  generateUpdatedPaymentRequest
} from 'mobile-button/utils/native-to-vinit';
import { getCheckoutAppType, getOrchestrationConfig, getVInit } from 'sdk/selectors';
import { SDKLiteUpdatePaymentInfo } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';
import { PaymentRequest } from 'types/sdk';

function didPaymentRequestChange(
  originalPaymentRequest?: PaymentRequest,
  updatedPaymentRequest?: PartialPaymentRequest
) {
  return (
    updatedPaymentRequest?.currencyCode !== originalPaymentRequest?.currencyCode ||
    updatedPaymentRequest?.subtotal !== originalPaymentRequest?.subtotal ||
    updatedPaymentRequest?.total !== originalPaymentRequest?.total
  );
}

/**
 * SDK Lite merchants who preload the mobile sdk and later update purchase info
 * will trigger this postmessage from the native SDK.
 *
 * @param {MiddlewareAPI} store
 * @param {SDKLiteUpdatePaymentInfo} message
 */
export default function sdkliteUpdatePaymentInfo(
  store: MiddlewareAPI,
  message: SDKLiteUpdatePaymentInfo
) {
  hybridLogger('visa.sdklite.updatePaymentInfo');

  if (getCheckoutAppType(store.getState()) !== 'SRC') {
    return;
  }

  const updatedPaymentRequestData = generateUpdatedPaymentRequest(message.data);
  // state.checkout.vInit will always be available at this point
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentVInitData = getVInit(store.getState())!;

  const paymentRequestChanged = didPaymentRequestChange(
    currentVInitData.paymentRequest,
    updatedPaymentRequestData
  );

  if (paymentRequestChanged) {
    // Update vInit data in state
    store.dispatch({
      data: {
        ...currentVInitData,
        paymentRequest: {
          ...currentVInitData.paymentRequest,
          ...updatedPaymentRequestData
        }
      },
      type: '@@sdk/CHECKOUT_SETUP'
    });

    store.dispatch({
      data: getOrchestrationConfig(store.getState()),
      type: '@@orchestration/INIT'
    });
  }

  store.dispatch({
    data: { paymentRequestChanged },
    type: '@@hybrid/UPDATE_PAYMENT_COMPLETE'
  });
}
