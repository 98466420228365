/**
 * @param {?boolean} onlyHostname default `false`
 * @returns {string}
 */
export default function getMerchantUrl(onlyHostname = false) {
  const { hostname, pathname, port, protocol } = window.location;
  const url = `${protocol}//${hostname}${port ? ':' : ''}${port}${pathname}`;

  if (onlyHostname) {
    return `${protocol}//${hostname}`;
  }

  // some sites use cache-busting and add ;jsessionid={value} to the url.
  // Remove all of this if present to prevent browser security errors.
  if (url.indexOf(';') !== -1) {
    return url.slice(0, url.indexOf(';'));
  }

  return url;
}
