import { RECEIVE_MESSAGE_PATH } from '../constants/message';
import { HybridPlugin } from '../core/hybrid-plugin';
import { ButtonClickEnabled, CheckoutResult } from '../types';

export class HybridNative {
  plugin = new HybridPlugin();

  /**
   * This is sent to the native SDK to signal when the javascript is
   * ready to call V.init. When ready, native will resolve this call
   * with the actual vinit data.
   */
  sendVinitReady = () =>
    this.plugin.sendMessage({
      // This property is what native expects when initializing it's bridge.
      messagePath: `window.${RECEIVE_MESSAGE_PATH}`,
      name: 'onVinitReady'
    });

  /**
   * Sends the encrypted payload to the native SDK so that it can be
   * converted into a native class object and returned to the merchant
   * through native completion handlers.
   *
   * @param result The payload returned from updateIntent calls.
   */
  sendResult = (result: CheckoutResult['data']) =>
    this.plugin.sendMessage({
      data: result,
      name: 'result'
    });

  /**
   * Sends a request for prefill data from the merchant. The callback that will
   * ultimiately respond with the data from the `PurchaseInfo` class in the
   * native code.
   */
  sendPrefillRequest = () =>
    this.plugin.sendMessage({
      name: 'purchaseInfo.prefill'
    });

  buttonClickEnabled = (data: ButtonClickEnabled['data']) =>
    this.plugin.sendMessage({
      data,
      name: 'buttonClickEnabled'
    });
}

let nativeWrapper: HybridNative;
export default () => {
  if (!nativeWrapper) {
    nativeWrapper = new HybridNative();
  }

  return nativeWrapper;
};
