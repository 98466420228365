import {
  isAndroidWebview,
  isHybridSDKLite,
  isIOSWebview,
  isSDKLite,
  isUIWebview,
  isUnknownBrowser
} from 'common/utils/browser';

export type MobileEnvironment = 'hybrid' | 'uiwebview' | 'unknown' | 'webview' | null;

export default function getMobileEnvironment(): MobileEnvironment {
  if (isHybridSDKLite() || isSDKLite()) {
    return 'hybrid';
  }

  if (isUIWebview()) {
    return 'uiwebview';
  }

  if (isAndroidWebview() || isIOSWebview()) {
    return 'webview';
  }

  if (isUnknownBrowser()) {
    return 'unknown';
  }

  return null;
}
