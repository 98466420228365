/* START GENAI@GHCOPILOT */
import { CountryCode } from '@visa/lib-types';
/* END GENAI@GHCOPILOT */
import { EU_COUNTRY_CODES } from 'common/constants/config';

type isEUMerchantOrUserOptions = {
  isEuroIp: boolean;
  merchantCountryCode: CountryCode | null;
  userCountryCode: CountryCode | null;
};

export default function isEUMerchantOrUser({
  isEuroIp,
  merchantCountryCode,
  userCountryCode
}: isEUMerchantOrUserOptions) {
  if (isEuroIp) {
    return true;
  }

  for (const euCountryCode of EU_COUNTRY_CODES) {
    if (userCountryCode === euCountryCode || merchantCountryCode === euCountryCode) {
      return true;
    }
  }

  return false;
}
