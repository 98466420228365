import { iframeIdMap } from 'sdk/constants/iframe';
import { get as getPopupWindow } from 'sdk/utils/popup-reference';
import { WindowId } from 'types/sdk';

export default function getWindowId(
  windowRef: MessageEvent['source']
): WindowId | 'self' | 'unknown' {
  if (windowRef === window) {
    return 'self';
  }

  if (!windowRef) {
    return 'unknown';
  }

  let iframeId: WindowId;
  for (iframeId in iframeIdMap) {
    const iframe = document.getElementById(iframeIdMap[iframeId]) as HTMLIFrameElement | null;

    if (iframe?.contentWindow === windowRef) {
      return iframeId;
    }
  }

  // In VSB integrations, the DCF iframe will have id="vcop-dcf"
  const dcfIframe = document.getElementById('vcop-dcf') as HTMLIFrameElement | null;

  if (windowRef === dcfIframe?.contentWindow) {
    return 'checkout';
  }

  const popupWindow = getPopupWindow();

  if (popupWindow === windowRef) {
    return 'checkout';
  }

  return 'unknown';
}
