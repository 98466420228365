export const fetchCardArt = (url: string) => {
  return fetch(url, { credentials: 'same-origin' })
    .then(response => response.arrayBuffer())
    .then(buffer => {
      let binary = '';
      const bytes = [].slice.call(new Uint8Array(buffer));
      bytes.forEach(b => (binary += String.fromCharCode(b)));
      return window.btoa(binary);
    });
};
