import {
  deactivateButton,
  hideButton,
  initButton,
  loadButton,
  lockButton,
  updateActiveButton
} from 'sdk/dom/button';
import {
  getButtonQuery,
  getCheckoutStatus,
  getIntegrationType,
  getIsHybridCheckoutWebview,
  getIsVSBButtonless
} from 'sdk/selectors';
import { CardNetwork } from 'types/enums';
import { Action, Middleware, MiddlewareAPI } from 'types/redux';

function queueCheckout(store: MiddlewareAPI) {
  const isCheckoutReady = getCheckoutStatus(store.getState()) === 'ready';

  if (isCheckoutReady) {
    store.dispatch({
      type: '@@sdk/CHECKOUT_QUEUED'
    });
  }
}

/**
 * Handles manipulating the button on the merchant page.
 */
const buttonMiddleware: Middleware = store => {
  // IMPORTANT NOTE: Each time V.init is called, events are re-attached to the
  // button. To prevent duplicate events from being registered, we rely on a
  // somewhat obscure browser behavior to automatically dedupe event listeners
  // as long as they have referential equality. This is why these handlers are
  // defined at the top of this middleware. We want to reuse these references
  // throughout the lifetime of the SDK.
  //
  // Browser behavior is documented on MDN:
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
  const handleButtonClick = () => queueCheckout(store);
  const handleButtonKeydown = (e: KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      queueCheckout(store);
    }
  };

  return next => (action: Action) => {
    const state = store.getState();

    if (
      getIsVSBButtonless(state) ||
      // SDKLite integrations have their own button logic in sdk/dom/hybrid and
      // mobile-button/index.js
      getIntegrationType(state) === 'sdk-lite' ||
      getIntegrationType(state) === 'sdk-lite-cross-app' ||
      // Ignore button behaviors in checkout webview
      getIsHybridCheckoutWebview(state)
    ) {
      return next(action);
    }

    switch (action.type) {
      case '@@config/INVALID_INIT_OPTIONS':
      case '@@config/MERCHANT_CONFIG_FAILURE':
      case '@@sdk/UNSUPPORTED_BROWSER':
      case '@@sdk/UNSUPPORTED_HYBRID_VERSION':
      case '@@sdk/CHECKOUT_DISABLED_BY_FORM_FACTOR':
        lockButton();
        break;
      case '@@button/BUTTON_HIDE':
        hideButton();
        break;
      case '@@button/BUTTON_INIT':
      case '@@hybrid/BUTTON_INIT': {
        const integrationType = getIntegrationType(state);

        if (integrationType === 'web') {
          initButton({
            events: {
              click: handleButtonClick,
              keydown: handleButtonKeydown
            },
            updateCardBrandOrder(cardBrands: CardNetwork[]) {
              store.dispatch({
                data: cardBrands,
                type: '@@button/CARD_BRAND_ORDER'
              });
            }
          });
        } else if (integrationType === 'hybrid-plugin') {
          // hybrid plugin integrations have separate click handling logic in sdk/dom/hybrid.ts
          initButton({ events: {} });
        }
        break;
      }
      case '@@button/BUTTON_LOAD':
      case '@@hybrid/BUTTON_LOAD':
        loadButton(getButtonQuery(store.getState()));
        break;
      case '@@hybrid/CHECKOUT_QUEUED':
      case '@@sdk/CHECKOUT_QUEUED': {
        const integrationType = getIntegrationType(state);

        if (integrationType === 'web' || integrationType === 'hybrid-plugin') {
          // Any query changes post-BUTTON_LOAD will only apply to the "active" button
          // (aka the one that was clicked). All other buttons should be unaffected.
          const buttonQuery = getButtonQuery(store.getState());

          if (buttonQuery.legacy && buttonQuery.svg) {
            buttonQuery.sliding = true;
          }

          updateActiveButton(buttonQuery);
        }

        break;
      }
      case '@@sdk/CHECKOUT_COMPLETE': {
        const state = store.getState();

        // CHECKOUT_COMPLETE can be dispatched prior to button click if merchant
        // config responds with a 404. In this case, there is no "active" button
        // to update.
        if (getCheckoutStatus(state) !== 'active') {
          break;
        }

        const buttonQuery = getButtonQuery(state);

        if (buttonQuery.legacy && buttonQuery.svg) {
          buttonQuery.sliding = false;
        }

        updateActiveButton(buttonQuery);
        deactivateButton();
        break;
      }
    }

    return next(action);
  };
};

export default buttonMiddleware;
