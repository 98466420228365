import getWindowById from 'common/utils/get-window-by-id';
import hybridLogger from 'common/utils/hybrid-logger';
import { mark, marks } from 'common/utils/instrumentation';
import {
  getCheckoutAppType,
  getCheckoutWindowQuery,
  getCheckoutWindowUrl,
  getHybridAPIVersion,
  getHybridWebviewType,
  getIntegrationType,
  getIsScriptInjected,
  getOrchestrationError,
  getOrchestrationStatus
} from 'sdk/selectors';
import { Store } from 'types/redux/store';

type CheckoutApi = {
  store: Store;
};

function onCheckoutWindowLoaded(store: Store) {
  mark(marks.srcIframeLoaded);

  if (getCheckoutAppType(store.getState()) === 'RXO') {
    store.dispatch({
      message: {
        data: Date.now(),
        type: 'rxo:render'
      },
      target: 'checkout',
      type: '@@window/SEND_POSTMESSAGE'
    });
  }
}

export default function checkoutHybrid({ store }: CheckoutApi): void {
  store.dispatch({
    type: '@@hybrid/CHECKOUT_STARTED'
  });

  hybridLogger('SRC:openSdkLite');

  const state = store.getState();
  const apiVersion = getHybridAPIVersion(state);
  const integrationType = getIntegrationType(state);
  const webviewType = getHybridWebviewType(state);

  if (webviewType === 'merchant' && apiVersion === '7.x' && integrationType === 'sdk-lite') {
    store.dispatch({
      data: {
        id: 'checkout',
        query: getCheckoutWindowQuery(state),
        src: getCheckoutWindowUrl(state)
      },
      type: '@@window/NAVIGATE'
    });
  } else {
    store.dispatch({
      data: {
        autofocus: true,
        id: 'checkout',
        onLoad: () => onCheckoutWindowLoaded(store),
        query: getCheckoutWindowQuery(state),
        src: getCheckoutWindowUrl(state),
        type: 'modal'
      },
      type: '@@window/OPEN_WINDOW'
    });
  }

  // Call logEvent to track orchestration script failures
  if (getIsScriptInjected(state, 'orchestration')) {
    const checkoutWindow = getWindowById('checkout');
    const iframeId = checkoutWindow ? checkoutWindow.location.href : 'iFrame is not available';
    const window_name = checkoutWindow?.name;
    const window_url = checkoutWindow?.location.href;

    if (getOrchestrationStatus(state) === 'error') {
      let event = 'orch-load-failure';
      const orchError = getOrchestrationError(state);

      if (orchError) {
        event += ` ${orchError}`;
      }

      store.dispatch({
        logLevel: 'ERROR',
        message: {
          event,
          iframeId,
          window_name,
          window_url
        },
        status: 400,
        type: '@@hybrid/LOG_EVENT'
      });
    } else {
      store.dispatch({
        logLevel: 'INFO',
        message: {
          event: 'orch-load-successful',
          iframeId,
          window_name,
          window_url
        },
        status: 200,
        type: '@@hybrid/LOG_EVENT'
      });
    }
  }
}
