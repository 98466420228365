import { isIOSOrAndroid } from 'common/utils/browser';
import { MiddlewareAPI } from 'types/redux';

export default function applyRXOStyles(store: MiddlewareAPI) {
  if (isIOSOrAndroid()) {
    store.dispatch({
      data: 'rxo-ios-android',
      type: '@@window/ADD_BODY_CLASS'
    });
  }
}
