import { State } from 'types/redux';

export const getHybridAPIVersion = (state: State) => state.hybrid.hybridAPIVersion;
export const getIsHybridAppReady = (state: State) => state.hybrid.isAppReady;
export const getIsHybridButtonClicked = (state: State) => state.hybrid.isButtonClicked;
export const getHybridWebviewType = (state: State) => state.hybrid.webviewType;
export const getIsHybridCheckoutWebview = (state: State) =>
  getHybridWebviewType(state) === 'checkout';
export const getIsHybrid = (state: State) => state.checkout.integrationType !== 'web';
export const getIsNativeSpinnerActive = (state: State) => state.hybrid.nativeSpinnerActive;
export const getHybridCheckoutWindowName = (state: State) => state.hybrid.checkoutWebviewName;
export const getMobileEnvironment = (state: State) => state.hybrid.mobileEnvironment;
export const getIsHybridManualCheckout = (state: State) => state.hybrid.isManualCheckout;
