import { LaunchFromLearn } from 'types/messages/incoming';
import { MiddlewareAPI } from 'types/redux';

export default function launchFromLearn(store: MiddlewareAPI, message: LaunchFromLearn) {
  store.dispatch({
    data: {
      id: 'learn'
    },
    type: '@@window/CLOSE_WINDOW'
  });

  store.dispatch({
    data: {
      startPath: message.data.startPath
    },
    type: '@@sdk/CHECKOUT_QUEUED'
  });
}
