// eslint-disable-next-line @typescript-eslint/ban-types
export const setDeep = <T extends object>(obj: T, path: string, value: unknown): T => {
  const keys = path.split('.');
  keys.reduce((obj, key, i) => {
    if (i === keys.length - 1) {
      obj[key] = value;
    } else if (!obj[key]) {
      obj[key] = {};
    }
    return obj[key] as Record<string, unknown>;
  }, obj as Record<string, unknown>);

  return obj;
};
