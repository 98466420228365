import { BUTTON_MISSING } from 'common/constants/error-messages';
import { ROOT_DOMAIN } from 'common/constants/window';
import HybridSDK from 'common/hybrid';
import getParam from 'common/utils/get-param';
import { mark, marks } from 'common/utils/instrumentation';
import logger from 'common/utils/logger';
import { createQueryObject, createQueryString } from 'common/utils/query-string';
import {
  BUTTON_SIZE_LARGE,
  BUTTON_SIZE_NORMAL,
  LOCKED_BUTTON_SRC_LARGE,
  LOCKED_BUTTON_SRC_MEDIUM,
  LOCKED_BUTTON_SRC_SMALL
} from 'sdk/constants/button';
import getButtonOrderedCardBrands from 'sdk/utils/get-button-ordered-card-brands';
import { CardNetwork } from 'types/enums';
import { ButtonEventNames, ButtonEvents, ButtonQuery } from 'types/sdk';

// Why getVButtons and not getVButton? Unfortunately some merchants use the
// .v-button class multiple times. E.g. Pizza Hut's integration is roughly
// <div class="v-button"><img class="v-button"></div>
export function getVButtons(options = { suppressError: false }) {
  const buttonEls = document.querySelectorAll('.v-button') as NodeListOf<HTMLElement>;

  // SDK Lite does not have a v-button
  if (!options.suppressError && !buttonEls.length && !HybridSDK.isSDKLite()) {
    logger.error(BUTTON_MISSING);
  }

  return buttonEls;
}

export function getActiveVButton() {
  const activeButtonSelector = '.v-button[data-active="true"]';
  let buttonEl = document.querySelector(activeButtonSelector) as HTMLElement | null;

  // If checkout is launched from the legacy Learn More app, then there will be
  // no active button. Instead, activate the first .v-button on the page.
  if (!buttonEl) {
    buttonEl = getVButtons()[0] ?? null;
  }

  return buttonEl;
}

export function isImage(el: Element): el is HTMLImageElement {
  return Boolean(
    el &&
      el.hasAttribute('src') &&
      // Some merchants may have .v-button elements that aren't image elements,
      // so even if a src attribute is defined, el.src will be undefined. For
      // instance: <a class="v-button" src="something invalid">.src === undefined
      'src' in el &&
      typeof (el as HTMLImageElement).src === 'string'
  );
}

type HighlightButtonOptions = {
  brightness: number;
  outline?: string;
  saturation?: number;
};

function focusHighlight(buttonEl: HTMLElement) {
  buttonEl.style.outline = '1px auto rgb(0, 95, 204)';
}

function updateButtonHighlight(
  buttonEl: HTMLElement,
  { brightness, outline = '', saturation = 1 }: HighlightButtonOptions
) {
  buttonEl.style.transitionProperty = 'filter';
  buttonEl.style.transitionDuration = '0.25s';
  buttonEl.style.filter = `brightness(${brightness}) saturate(${saturation})`;
  buttonEl.style.outline = outline;
}

function getButtonSrc(buttonEl: HTMLImageElement, queryObj: ButtonQuery) {
  const currentQuery = createQueryObject(buttonEl.src);
  const newQuery = createQueryString({ ...currentQuery, ...queryObj });
  const buttonSrc = buttonEl.src.split('?')[0];
  return `${buttonSrc}${newQuery}`;
}

function focusButton(this: HTMLElement) {
  // eslint-disable-next-line babel/no-invalid-this
  focusHighlight(this);
}
function highlightButton(this: HTMLElement) {
  // eslint-disable-next-line babel/no-invalid-this
  updateButtonHighlight(this, { brightness: 0.95 });
}
function unhighlightButton(this: HTMLElement) {
  // eslint-disable-next-line babel/no-invalid-this
  updateButtonHighlight(this, { brightness: 1 });
}

// IMPORTANT NOTE: Each time V.init is called, events are re-attached to the
// button. To prevent duplicate events from being registered, we rely on a
// somewhat obscure browser behavior to automatically dedupe event listeners as
// long as they have referential equality. This is why these button events are
// defined outside of initButton.
//
// Browser behavior is documented on MDN:
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
const staticEvents: ButtonEvents = {
  blur: unhighlightButton,
  click(this: HTMLElement) {
    mark(marks.buttonClickStart);
    this.setAttribute('data-active', 'true');
  },
  focus: focusButton,
  keydown(this: HTMLElement) {
    mark(marks.buttonClickStart);
    this.setAttribute('data-active', 'true');
  },
  mouseenter: highlightButton,
  mouseleave: unhighlightButton,
  mouseup: highlightButton
};

type InitButtonOptions = {
  events: ButtonEvents;
  updateCardBrandOrder?: (cardBrands: CardNetwork[]) => void;
};

export function initButton({ events: buttonEvents, updateCardBrandOrder }: InitButtonOptions) {
  let hasRegisteredCardBrandsParam = false;

  getVButtons().forEach(buttonEl => {
    if (!buttonEl) {
      return;
    }

    // If merchant button.png integration includes an orderedCardBrands query
    // parameter, save it to state to use for the second button load.
    // Design doc: https://visawiki.trusted.visa.com/x/wruAMw
    if (isImage(buttonEl) && !hasRegisteredCardBrandsParam) {
      const orderedCardBrandsParam = getParam(buttonEl.src, 'orderedCardBrands');

      if (orderedCardBrandsParam && updateCardBrandOrder) {
        const parsedCardBrandOrder = getButtonOrderedCardBrands(orderedCardBrandsParam);
        updateCardBrandOrder(parsedCardBrandOrder);
      }

      hasRegisteredCardBrandsParam = true;
    }

    // allow the button to be tab focused
    buttonEl.tabIndex = 0;

    const staticEventNames = Object.keys(staticEvents) as ButtonEventNames;
    for (const eventName of staticEventNames) {
      const handler = staticEvents[eventName] as EventListener;
      buttonEl.addEventListener(eventName, handler);
    }

    const buttonEventNames = Object.keys(buttonEvents) as ButtonEventNames;
    for (const eventName of buttonEventNames) {
      const handler = buttonEvents[eventName] as EventListener;
      buttonEl.addEventListener(eventName, handler);
    }
  });
}

export function deactivateButton() {
  const buttonEl = getActiveVButton();

  if (buttonEl) {
    // When checkout ends and the button is reset, remove data-active attribute.
    buttonEl.removeAttribute('data-active');
  }
}

export function updateActiveButton(queryObj: ButtonQuery) {
  const buttonEl = getActiveVButton();

  if (!buttonEl || !isImage(buttonEl)) {
    return;
  }

  buttonEl.src = getButtonSrc(buttonEl, queryObj);
}

export function loadButton(queryObj: ButtonQuery) {
  getVButtons().forEach(buttonEl => {
    buttonEl.style.cursor = 'pointer';

    if (!isImage(buttonEl)) {
      return;
    }

    // Design doc: https://visawiki.trusted.visa.com/x/wruAMw
    const orderedCardBrandsParam = getParam(buttonEl.src, 'orderedCardBrands');

    if (orderedCardBrandsParam) {
      const parsedCardBrandOrder = getButtonOrderedCardBrands(orderedCardBrandsParam);

      queryObj.orderedCardBrands = parsedCardBrandOrder.filter(
        brand => queryObj.orderedCardBrands?.indexOf(brand) !== -1
      );
    }

    buttonEl.src = getButtonSrc(buttonEl, queryObj);

    if (!queryObj.legacy) {
      buttonEl.setAttribute('alt', 'Click to pay with payment icon');
      buttonEl.setAttribute('title', 'Click to pay with payment icon');
      // Skip preload step for SRC button because it does not have animations
      return;
    }

    // NOTE: pretty sure preloading the button images doesn't do anything since the
    // backend sends them with a no-cache header
    const loadingButton = new Image();
    loadingButton.src = getButtonSrc(buttonEl, { ...queryObj, loading: true });
    loadingButton.onload = function () {
      mark(marks.buttonLoadEnd + loadingButton.src);
    };

    const slidingButton = new Image();
    slidingButton.src = getButtonSrc(buttonEl, { ...queryObj, sliding: true });
    slidingButton.onload = function () {
      mark(marks.buttonLoadEnd + slidingButton.src);
    };
  });
}

export function lockButton() {
  getVButtons().forEach(buttonEl => {
    if (!isImage(buttonEl)) {
      return;
    }

    const sizeParam = getParam(buttonEl.src, 'size');
    const width = sizeParam ? parseInt(sizeParam, 10) : BUTTON_SIZE_NORMAL;

    buttonEl.alt = 'Visa Checkout Button Disabled';

    if (width < BUTTON_SIZE_NORMAL) {
      buttonEl.src = `${ROOT_DOMAIN}/${LOCKED_BUTTON_SRC_SMALL}`;
    } else if (width < BUTTON_SIZE_LARGE) {
      buttonEl.src = `${ROOT_DOMAIN}/${LOCKED_BUTTON_SRC_MEDIUM}`;
    } else {
      buttonEl.src = `${ROOT_DOMAIN}/${LOCKED_BUTTON_SRC_LARGE}`;
    }
  });
}

export function hideButton() {
  getVButtons().forEach(buttonEl => {
    buttonEl.style.visibility = 'hidden';
  });
}

export function addVInitClickSpy(cb: EventListener) {
  getVButtons().forEach(buttonEl => buttonEl.addEventListener('click', cb));
}

export function removeVInitClickSpy(cb: EventListener) {
  getVButtons().forEach(buttonEl => buttonEl.removeEventListener('click', cb));
}

export function initVInitClickSpy(cb: EventListener) {
  const readyStateChangeHandler = () => {
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      addVInitClickSpy(cb);
      document.removeEventListener('readystatechange', readyStateChangeHandler);
    }
  };

  document.addEventListener('readystatechange', readyStateChangeHandler);
}
