import { ANDROID_NAMESPACE } from '../constants';
import { API_2_MIN, API_3_MIN, API_DEFAULT_MIN } from '../constants/version';

const STRING = (() => {
  const wk5_9 =
    window.webkit?.messageHandlers?.configureVisaCheckoutPlugin ??
    window.webkit?.messageHandlers?.getRefreshToken;
  const ui5_9 = window.V?.uiWebViewPluginVersion;
  const droid5_9 = window.VisaHybridInterface;

  if (wk5_9 || ui5_9 || droid5_9) {
    // kinda arbitrary but it's the last RXO version before the breaking changes.
    return API_DEFAULT_MIN;
  }

  // Get the version based on available global handlers.
  const wk6 = window.webkit?.messageHandlers?.visaMessage;
  const droid6 = window.VisaAndroid?.visaMessage;
  if (wk6 || droid6) {
    // In Hybrid API v2, the native SDK version number can be extracted from the user agent.
    const regexPattern = /VCO_SDK\/(\d+\.\d+\.\d+)/g;
    const version = regexPattern.exec(window.navigator.userAgent)?.[1];

    if (version) {
      return version;
    }

    const wkSRC = window.webkit?.messageHandlers?.supportsSRC;
    const droidSRC = window.VisaAndroid?.supportsSRC;

    return wkSRC || droidSRC ? API_3_MIN : API_2_MIN;
  }

  return null;
})();

const API = (version => {
  if (!version) {
    return 0;
  }

  const majorVersion = Number(version.split('.')[0]);

  if (majorVersion < 6) {
    return 1;
  } else if (majorVersion < 7) {
    return 2;
  }

  return 3;
})(STRING);

const IS_CHROME_CUSTOM_TAB = typeof window.VisaAndroid?.supportsCrossApp === 'function';

export { STRING, API, ANDROID_NAMESPACE, IS_CHROME_CUSTOM_TAB };

export default {
  ANDROID_NAMESPACE,
  API,
  IS_CHROME_CUSTOM_TAB,
  STRING
};
