import { getHybridAPIVersion, getIsHybridButtonClicked } from 'sdk/selectors';
import { MiddlewareAPI } from 'types/redux';

export default function sdkliteAppReady(
  store: MiddlewareAPI
  // message: SDKLiteAppReady
) {
  store.dispatch({
    type: '@@hybrid/APP_READY'
  });

  const state = store.getState();

  // If the button has already been clicked, immediately send a launch message.
  // Otherwise wait for button click. See lifecycle/init/hybrid-checkout.ts.
  if (getHybridAPIVersion(state) === '6.x' && getIsHybridButtonClicked(state)) {
    store.dispatch({
      message: { type: 'sdklite:buttonclick' },
      target: 'checkout',
      type: '@@window/SEND_POSTMESSAGE'
    });
  }
}
