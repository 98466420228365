import { ALREADY_LOADED } from 'common/constants/error-messages';
import { mark, marks } from 'common/utils/instrumentation';
import logger from 'common/utils/logger';
import getMerchantCallback from 'sdk/utils/get-merchant-callback';

mark(marks.sdkParse); // need a "permanent" t0 to measure against

if (window.V) {
  logger.error(ALREADY_LOADED);

  // NSMI Note: If the merchant loads the sdk more than once, subsequent loads
  // should re-invoke the merchant callback. See Tactical Link merchant
  // integration.
  const merchantCallback = getMerchantCallback();
  merchantCallback?.();
} else {
  mark(marks.sdkStart); // will be removed when END is marked

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const loadSDK = require('sdk/sdk').default;
  loadSDK();

  mark(marks.sdkEnd);
}
