import { ORCHESTRATION_LOAD_FAILED } from 'common/constants/error-messages';
import HybridSDK from 'common/hybrid';
import logger from 'common/utils/logger';
import { OrchestrationConfig, VSBInitResponse } from 'types/orchestration';

// System SDK depends on sdk.js creating this window global. Without it, errors
// will be thrown when calling reinitializeOrchSdk.
window.VsbOrchAdapterInstance = null;

export type InitOrchOptions = {
  isHybrid: boolean;
  orchConfigs: OrchestrationConfig;
  onInit: (response: VSBInitResponse) => void;
};

export function initOrch({ isHybrid, orchConfigs, onInit }: InitOrchOptions) {
  const VSB = window.vAdapters?.VSB;

  if (VSB) {
    VSB.init(orchConfigs).then(onInit);

    window.VsbOrchAdapterInstance = VSB;

    if (isHybrid) {
      HybridSDK.sendOrchestrationHasLoaded();
    }
  } else {
    logger.error(ORCHESTRATION_LOAD_FAILED);
  }
}

export function setSrcWindowReference(windowRef: Window | HTMLIFrameElement | null) {
  window.VsbOrchAdapterInstance?.setSrcWindowReference(windowRef);
}

export type ReinitializeOrchOptions = {
  correlationId: string;
  sessionId: string;
};

export function reinitializeOrchSdk({ correlationId, sessionId }: ReinitializeOrchOptions) {
  window.VsbOrchAdapterInstance?.reinitializeOrchSdk(correlationId, sessionId);
}
