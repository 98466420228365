import { v1 as uuid } from 'uuid';
import { MessageResponse } from '../types';

/**
 * Stores the Promise resolve and reject handlers of a sent message.
 * Those handlers are captured by the `callback` member and
 * invoked when a visaMessage result has been received.
 */
export class PendingMessage {
  callback: (message: MessageResponse) => void;
  id: string;

  constructor(callback: (message: MessageResponse) => void) {
    this.callback = callback;
    this.id = uuid();
  }
}
