import HybridSDK, { getIsSdkLiteCrossApp } from 'common/hybrid';
import { isHybridSDKLite, isSDKLite } from 'common/utils/browser';

export type IntegrationType = 'hybrid-plugin' | 'sdk-lite' | 'sdk-lite-cross-app' | 'web';

export default function getIntegrationType(): IntegrationType {
  // SDK Lite Cross App integrations do not update User Agent. To distinguish a
  // Cross App integration in sdk-lite.html, we set window.isSdkLiteCrossApp. In
  // mobile-button.html, we rely on HybridSDK.IS_CHROME_CUSTOM_TAB. Need to
  // check this first because HybridSDK.API_LEVEL === 0 in cross-tab
  // integrations.
  if (getIsSdkLiteCrossApp()) {
    return 'sdk-lite-cross-app';
  }

  // Normal web-based integration.
  if (HybridSDK.API_LEVEL < 2) {
    return 'web';
  }

  // The Hybrid SDK Lite integration exists for merchants who have a native app
  // that is simply their web app in a native webview. It is essentially a shim
  // for allowing the SDK to communicate with native code, enabling the checkout
  // app to be launched in a first-party Visa Webview (hybrid.html) and allowing
  // links to open in a new webview rather than an iframe. *Note that there are
  // currently NO MERCHANTS using this integration method.*
  if (isHybridSDKLite()) {
    return 'hybrid-plugin';
  }

  // The SDK Lite integration is a native iOS or Android component that renders
  // the checkout button in a special first-party Visa webview
  // (mobile-button.html). That webview is instrumented with methods to interact
  // with native code, allowing the app to launch the checkout app in a separate
  // first-party Visa webview (sdk-lite.html).
  if (isSDKLite()) {
    return 'sdk-lite';
  }

  return 'web';
}
