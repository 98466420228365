import { Action } from 'types/redux';

export type GTMNsmiState = {
  vInitCallStack: string;
  vInitClickSpySelected: boolean;
};

const defaultGTMNsmiState: GTMNsmiState = {
  vInitCallStack: '',
  vInitClickSpySelected: false
};

export default function gtmNsmiReducer(
  state: GTMNsmiState = defaultGTMNsmiState,
  action: Action
): GTMNsmiState {
  switch (action.type) {
    case '@@sdk/NSMI_VINIT_ERROR_STACK':
      return {
        ...state,
        vInitCallStack: action.payload
      };

    case '@@sdk/NSMI_VINIT_BUTTON_CLICK':
      return {
        ...state,
        vInitClickSpySelected: action.payload
      };

    default:
      return state;
  }
}
