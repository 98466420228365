// Parses incoming total string to a float to align with EMVCo spec. Removes all
// non-numeric, non-"." characters and sets precision to 4 digits.
export default function normalizeTotal(total: number | string | undefined) {
  if (typeof total === 'number') {
    return parseFloat(total.toFixed(4));
  }

  if (typeof total === 'string') {
    const cleanTotal = parseFloat(total.replace(/[^\d.]/g, ''));
    return parseFloat(cleanTotal.toFixed(4));
  }

  return NaN;
}
