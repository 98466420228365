import getWindowById from 'common/utils/get-window-by-id';
import { getIframeEl } from 'sdk/dom/iframe';
import { initOrch, reinitializeOrchSdk, setSrcWindowReference } from 'sdk/dom/orchestration';
import { getCheckoutFlow, getIsHybrid, getIsVSBButtonlessIntegration } from 'sdk/selectors';
import { VSBInitResponse } from 'types/orchestration';
import { Action, Middleware } from 'types/redux';

/**
 * Handles interactions with the system-sdk via the orchestration layer api.
 */
const orchestrationMiddleware: Middleware = store => next => (action: Action) => {
  const checkoutFlow = getCheckoutFlow(store.getState());

  // This middleware is only relevant for SRC/VDCP flows
  if (checkoutFlow !== 'SRC' && checkoutFlow !== 'VDCP') {
    return next(action);
  }

  if (action.type === '@@orchestration/INIT') {
    initOrch({
      isHybrid: getIsHybrid(store.getState()),
      onInit(response: VSBInitResponse) {
        // only resolve if VSB is enabled in metadata and merchant called
        // V.initializeVSB, not V.init
        if (getIsVSBButtonlessIntegration(store.getState())) {
          store.dispatch({
            data: response,
            type: '@@orchestration/VSB_INIT_COMPLETE'
          });
        }
        // reset vsb buttonless flag
        store.dispatch({
          data: false,
          type: '@@sdk/CHECKOUT_VSB_INIT_BUTTONLESS'
        });
      },
      orchConfigs: action.data
    });
  } else if (
    action.type === '@@sdk/CHECKOUT_COMPLETE' &&
    action.correlationId &&
    action.sessionId
  ) {
    reinitializeOrchSdk({
      correlationId: action.correlationId,
      sessionId: action.sessionId
    });
  } else if (action.type === '@@window/CLOSE_WINDOW' && action.data.id === 'checkout') {
    setSrcWindowReference(null);
  } else if (action.type === '@@window/OPEN_WINDOW' && action.data.id === 'checkout') {
    // system-sdk is inconsistent here. For popup, it expects the window object,
    // but for iframe, it expects the iframe element itself.
    const ref = action.data.type === 'popup' ? getWindowById('checkout') : getIframeEl('checkout');

    setSrcWindowReference(ref);
  } else if (action.type === '@@window/NAVIGATE') {
    setSrcWindowReference(getWindowById(action.data.id));
  }

  return next(action);
};

export default orchestrationMiddleware;
