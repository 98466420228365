export default function dedupe<A extends string[]>(arr: A): A {
  const deduped = [];
  const values: Record<string, true> = {};

  for (const val of arr) {
    if (!values[val]) {
      values[val] = true;
      deduped.push(val);
    }
  }

  return deduped as A;
}
