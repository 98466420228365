import { ScriptId } from 'types/sdk';

export type InjectScriptOptions = {
  id: ScriptId;
  src: string;
  onError?: OnErrorEventHandlerNonNull;
  onLoad?(e: Event): unknown;
};

export type RemoveScriptOptions = {
  id: ScriptId;
};

type ScriptIdMap = {
  [Id in ScriptId]: string;
};

const scriptIdMap: ScriptIdMap = {
  orchestration: 'orchJs',
  thm: 'threatmetrix_script_tag'
};

export function getScriptEl(id: ScriptId): HTMLScriptElement | null {
  return document.getElementById(scriptIdMap[id]) as HTMLScriptElement | null;
}

export function injectScript(data: InjectScriptOptions) {
  const script = document.createElement('script');
  if (scriptIdMap[data.id]) {
    script.setAttribute('id', scriptIdMap[data.id]);
  }
  script.setAttribute('src', data.src);
  document.head.appendChild(script);

  if (data.onLoad) {
    script.onload = data.onLoad;
  }

  if (data.onError) {
    script.onerror = data.onError;
  }
}

export function removeScript(data: RemoveScriptOptions) {
  const script = getScriptEl(data.id);

  if (script && script.parentNode) {
    script.parentNode.removeChild(script);
  }
}
