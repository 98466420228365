import { SDK_NAMESPACE } from '../constants';
import { defaultSdkLiteStrings } from '../constants/default-sdklite-strings';
import { RECEIVE_MESSAGE_PATH } from '../constants/message';
import { HybridPlugin } from '../core/hybrid-plugin';
import {
  CheckoutComplete,
  CheckoutLaunchData,
  Cookies,
  DeviceDataKey,
  JSONValue,
  MerchantData,
  PrefillData,
  RegisterMessagePathsOptions,
  Set,
  THMLaunchData
} from '../types';
import * as Version from '../utils/plugin-version';

export class HybridSDK {
  plugin = new HybridPlugin();

  API_LEVEL = Version.API;
  IS_CHROME_CUSTOM_TAB = Version.IS_CHROME_CUSTOM_TAB;
  VERSION = Version.STRING;

  /**
   * Initializes the native plugin to allow for various preloading and
   * other setup needed before a `launch()` call can be made.
   *
   * @param url - The url with which to initialize the checkout hybrid webview
   * @param method - The method to use for the web request. Default is 'GET'.
   * @param body - The body to send of the web request.
   * @param headers - The headers to sent as part of the request.
   * @return - A boolean denoting if webview is already launched.
   */
  configureVisaCheckout = (
    url: string,
    method?: string,
    body?: JSONValue,
    headers?: Record<string, string>
  ) => {
    if (!url) {
      return Promise.reject(new Error('Launch URL not set'));
    }

    const messagePath = `window.${RECEIVE_MESSAGE_PATH}`;
    return this.plugin.sendMessage({
      data: {
        body,
        headers,
        messagePath, // checkout webview
        method,
        // used to instantiate a plugin in rxo
        url: `${url}&hybridNamespace=${SDK_NAMESPACE}`
      },
      messagePath, // merchant webview
      name: 'configure'
    });
  };

  /**
   * Instructs the native plugin to dismiss the modally presented web view that
   * was launched with the `launch` method.
   */
  dismiss = () => this.plugin.sendMessage({ name: 'dismiss' });

  /**
   * Gets a value from device storage with a specified key.
   * A promise with the value of the stored key will be returned.
   * If a value was set with the `requireAuth` option set to true, the
   * user will be prompted with a native biometric prompt before the value
   * can be returned.
   *
   * @param key - The key used to retrieve the value from the device.
   * @return The value stored on the device if it exists or null.
   */
  get = <K extends DeviceDataKey>(key: K) =>
    this.plugin.sendMessage({
      data: key,
      name: 'get'
    });

  isHybridShimConfirmed = () => Version.API > 0;

  isLegacy = () => Version.API === 1;

  isSDKLite = () => Version.API > 1;

  isSRCSupported = () => Version.API > 2;

  /**
   * Tells the native plugin to display the configured webview, which will be
   * RXO. The native code will modally display the webview. After that returns
   * as successful, this method will instruct the RXO webview to render. The
   * Promise returned by that render event will resolve with the encrypted
   * payload data. This function is only used for API versions 6.x.x
   *
   * @param options - The info needed to launch the VCO experience.
   */
  launchVisaCheckout = (options: CheckoutLaunchData) => {
    return this.plugin.sendMessage({ name: 'launch' }).then(() => {
      // send the render message after the web view has been displayed
      return this.plugin.forwardEvent('render', options);
    });
  };

  /**
   * Tells the native plugin if the button is clickable. This  will be used by
   * merchants during manual checkout by an "isReady" feature in the Native
   * SDK.
   */
  notifyReady = () => this.plugin.sendMessage({ name: 'ready' });

  /**
   * Waits for the native plugin to resolve when a user explicitly
   * taps a "back" action. This will be used to honor that request by cleaning
   * up then completing the checkout experience with a "cancel" event.
   */
  onBackPressed = () => this.plugin.sendMessage({ name: 'onBack' });

  /**
   * Informs the native plugin that the button is finished loading
   * and passes the button html content to be cached by the SDK.
   *
   * @param html - The html button content.
   * @param altText - The accessible string used to describe the button to a
   * screen reader.
   * @param altTextKey - An optional translation key to be used on the button.
   * If provided and the key is valid, it overrides the `altText` string.
   */
  onButtonFinishedLoad = (html: string, altText: string, altTextKey?: string) =>
    this.plugin.sendMessage({
      data: {
        altText,
        altTextKey,
        html
      },
      name: 'onButtonFinishedLoad'
    });

  /**
   * Sends a request in case the merchant has implemented the "manual checkout"
   * feature in Native SDK. This will be invoked by merchants who generate
   * a custom checkout button and don't use our supplied checkout button.
   */
  onManualCheckout = () => this.plugin.sendMessage({ name: 'onManualCheckout' });

  /**
   * Wait for the native layer to say the native button click has happened.
   */
  onNativeButtonClick = () => this.plugin.sendMessage({ name: 'onNativeButtonClick' });

  onPrefillRequest = (callback: () => PrefillData | Promise<PrefillData>) =>
    this.plugin.on('purchaseInfo.prefill', callback);

  onProfileDevice = (callback: (data: THMLaunchData) => void) =>
    this.plugin.on('profileDevice', callback);

  onRender = (callback: () => Promise<CheckoutComplete>) => this.plugin.on('render', callback);

  onSyncCookies = (callback: (cookies: Cookies) => boolean) => this.plugin.on('cookie', callback);

  /**
   * Sends default localization strings (en_US) to SDKLite
   * while more precise strings will come from src/dcf.
   */
  registerDefaultStrings = () => {
    return this.plugin.sendMessage({
      data: defaultSdkLiteStrings,
      name: 'registerDefaultStrings'
    });
  };

  /**
   * Sends merchant data and CR so native hybrid plugin can sync log events
   * with the web.
   *
   * @param merchantData - The merchant data to register.
   */
  registerMerchantData = (merchantData: MerchantData) =>
    this.plugin.sendMessage({
      data: merchantData,
      name: 'registerMerchantData'
    });

  /**
   * Helps initialize native connections and other setup attributes.
   *
   * @param data - The register message paths options.
   */
  registerMessagePaths = (data: RegisterMessagePathsOptions) => {
    const receiveMessagePath = `window.${RECEIVE_MESSAGE_PATH}`;
    return this.plugin.sendMessage({
      checkoutMessagePath: receiveMessagePath,
      data,
      merchantMessagePath: receiveMessagePath,
      name: 'registerMessagePaths'
    });
  };

  /**
   * Informs native that the orchestration layer has loaded so that native
   * can now send certain data to the web such as updatePayment calls.
   */
  sendOrchestrationHasLoaded = () => this.plugin.sendMessage({ name: 'orchestrationHasLoaded' });

  /**
   * Sets a value into device storage.
   *
   * @param key - The key of the value set on the device.
   * @param value - The value of the key set on the device.
   * @param options - The options used to define how the data is saved.
   */
  set = (key: DeviceDataKey, value: JSONValue, options?: Set['options']) =>
    this.plugin.sendMessage({
      data: { key, value },
      name: 'set',
      options
    });

  /**
   * Instructs the native plugin to show the loading spinner while the SDK
   * is still waiting for RXO to finish configuring and preloading.
   *
   * @param domain - The url domain to be used for showing
   * the loading view
   */
  showLoading = (domain: string) =>
    this.plugin.sendMessage({
      data: domain,
      name: 'showLoading'
    });

  /**
   * Tells the native plugin to display the configured checkout webview
   * Once native returns a success, we simply resolve the promise.
   * This function is for API versions 7.x.x and up so further communication
   * with the checkout webview happens through direct postMessages.
   */
  srcLaunch = () => this.plugin.sendMessage({ name: 'launch' });

  /**
   * Informs native that the updatePayment calls was completed and if
   * it was successful.
   */
  updatePaymentComplete = (wasSuccessful: boolean) =>
    this.plugin.sendMessage({
      data: { wasSuccessful },
      name: 'updatePaymentComplete'
    });
}

let sdkWrapper: HybridSDK;

export default () => {
  if (!sdkWrapper) {
    sdkWrapper = new HybridSDK();
  }

  return sdkWrapper;
};
