// NOTE: This file is written in this way to ensure that no error messages are
// included in the production bundle. Relies on dead code elimination since
// messages are not assigned when `process.env.NODE_ENV === 'production'`.

let ALREADY_LOADED = '';
let BUTTON_MISSING = '';
let CHECKOUT_IN_PROGRESS = '';
let CHECKOUT_INITIALIZED = '';
let CHECKOUT_NOT_INITIALIZED = '';
let DATA_LAYER_UNDEFINED = '';
let DESTROY_NONEXISTANT_ELEMENT = '';
let HYBRID_INTERFACE_MISSING = '';
let INVALID_OPTIONS = '';
let ORCHESTRATION_LOAD_FAILED = '';
let UPDATE_NONEXISTANT_ELEMENT = '';

type MessageFn<D> = (data: D) => string;

let COOKIE_PARSE_FAILED: MessageFn<{ name: string }> = () => '';
let NONEXISTANT_ELEMENT: MessageFn<{ selector: string }> = () => '';
let POSTMESSAGE_SEND_ERROR: MessageFn<{ targetId: string }> = () => '';

if (process.env.NODE_ENV !== 'production') {
  ALREADY_LOADED =
    'More than one instance of the Visa SDK has been included on this page. Remove duplicate <script>s to avoid errors.';
  BUTTON_MISSING =
    'Could not find an image tag with class "v-button". Please review the Visa Checkout integration guide for more details.';
  CHECKOUT_IN_PROGRESS = 'Checkout method called while a checkout process is still in progress.';
  CHECKOUT_INITIALIZED =
    'V.init() has already been called once. This behavior is DEPRECATED in favor of V.setOptions(). In the future, subsequent calls to V.init() will result in a no-op.';
  CHECKOUT_NOT_INITIALIZED = 'Cannot call V.setOptions since V.init() has not yet been called.';
  DATA_LAYER_UNDEFINED = 'VXO data layer not defined -- GTM impresssion not logged.';
  DESTROY_NONEXISTANT_ELEMENT = 'Attempting to remove an element that does not exist on the page.';
  HYBRID_INTERFACE_MISSING =
    'Could not find `window.VisaCheckoutSDK.InboundHybridHandlers.messageHandlers` on the checkout window object.';
  INVALID_OPTIONS = 'Must provide apiKey to V.init().';
  ORCHESTRATION_LOAD_FAILED = 'VSB API failed to load. window.vAdapters.VSB is undefined.';
  UPDATE_NONEXISTANT_ELEMENT = 'Attempting to update an element that does not exist on the page.';

  COOKIE_PARSE_FAILED = data => `Could not parse cookie value for: ${data.name}`;
  NONEXISTANT_ELEMENT = data =>
    `Could not find and element matching the selector: ${data.selector}.`;
  POSTMESSAGE_SEND_ERROR = data =>
    `Could not postmessage to window that doesn't exist: "${data.targetId}".`;
}

export {
  ALREADY_LOADED,
  BUTTON_MISSING,
  CHECKOUT_IN_PROGRESS,
  CHECKOUT_INITIALIZED,
  CHECKOUT_NOT_INITIALIZED,
  COOKIE_PARSE_FAILED,
  DATA_LAYER_UNDEFINED,
  DESTROY_NONEXISTANT_ELEMENT,
  HYBRID_INTERFACE_MISSING,
  INVALID_OPTIONS,
  NONEXISTANT_ELEMENT,
  ORCHESTRATION_LOAD_FAILED,
  POSTMESSAGE_SEND_ERROR,
  UPDATE_NONEXISTANT_ELEMENT
};
