import { GTMPerformanceEvent, PerfMarks } from '@visa/lib-instrumentation';
import * as IDStorage from 'common/utils/id-storage';
import * as Message from 'common/utils/postmessage';

const namespace = 'visa::perf';

export const marks = {
  // auto-measure
  buttonClickEnd: 'visa::perf::END::ButtonClick',
  buttonClickStart: 'visa::perf::START::ButtonClick',

  buttonLoadEnd: 'visa::perf::END::ButtonLoad::',
  buttonLoadStart: 'visa::perf::START::ButtonLoad::',

  createIframeEnd: 'visa::perf::END::CreateIframe',
  createIframeStart: 'visa::perf::START::CreateIframe',
  // necessary?
  legacyButtonLoad: 'visa::perf::LegacyButtonLoad::',
  mobileButtonLoad: 'visa::perf::MobileButtonLoad::',

  onVisaReady: 'visa::perf::START::onVisaReadyCalled',

  orchScriptLoadEnd: 'visa::perf::END::orchLoad',
  orchScriptLoadStart: 'visa::perf::START::orchLoad',

  pmRecv: 'visa::perf::RECV::pm::',
  pmSend: 'visa::perf::SEND::pm::',

  popupLoad: 'visa::perf::END::popupLoad',

  sdkEnd: 'visa::perf::END::sdkLoad',
  sdkParse: 'visa::perf::PARSE::sdkLoad',
  sdkStart: 'visa::perf::START::sdkLoad',

  srcIframeLoaded: 'visa::perf::VDCPIframeLoad',
  srcIsRecognized: 'visa::perf::RECV::pm::visa.src.is-recognized',

  vinit: 'visa::perf::VINIT'
};

// Explicit measures that cannot necessarily leverage START/END conventions
const measures = [
  {
    budget: 1000,
    end: `${marks.pmRecv}visa:config:config_data`,
    name: 'Init to SRC configs set',
    start: marks.vinit
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa:config:merchant_config_response`,
    name: 'Init to merchant configs set',
    start: marks.vinit
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa.src.init`,
    name: 'Init to SRC-init',
    start: marks.vinit
  },
  {
    budget: 1000,
    end: marks.srcIsRecognized,
    name: 'Init to SRC isRecognized return',
    start: marks.vinit
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa.src.hide-launch-loader`,
    name: 'Button Click to SRC hide launch loader',
    start: marks.buttonClickStart
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}src:app-shell:fetch-configs`,
    name: 'Button Click to appShell fetch configs',
    start: marks.buttonClickStart
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa.orch.ino-decision`,
    name: 'Button Click to INO decision',
    start: marks.buttonClickStart
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa.orch.init`,
    name: 'Button Click to Orchestration Layer Init',
    start: marks.buttonClickStart
  },
  {
    budget: 1000,
    end: `${marks.pmRecv}visa.orch.is-recognized`,
    name: 'Button Click to Orchestration isRecognized return',
    start: marks.buttonClickStart
  },
  {
    budget: 7000,
    end: marks.buttonClickEnd,
    name: 'Button Click to Button Click End',
    start: marks.buttonClickStart
  },
  {
    budget: 7000,
    end: marks.srcIframeLoaded,
    name: 'Button Click to SRC iframe loaded',
    start: marks.buttonClickStart
  }
];

let eventQueue: Array<GTMPerformanceEvent> = [];
let isGTMLoaded = false;

function sendEvent(event: GTMPerformanceEvent) {
  const finalEvent = {
    correlation_id: IDStorage.get('correlationId'),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    visit_id: IDStorage.get('visitId')!,
    ...event,
    event_action: 'SDK Performance - sdk2'
  };

  Message.send({ event: finalEvent, type: 'visa:gtm:event' }, 'gtm');
}

const Perf = new PerfMarks(namespace, measures, function sendGTMEvent(event: GTMPerformanceEvent) {
  // If gtm window is not yet ready, or correlationId/visitId have not yet been
  // received from the config iframe, enqueue events to be postmessaged later.
  if (isGTMLoaded && IDStorage.get('correlationId') && IDStorage.get('visitId')) {
    if (eventQueue.length) {
      eventQueue.forEach(sendEvent);
      eventQueue = [];
    }

    sendEvent(event);
  } else {
    eventQueue.push(event);
  }
});

Perf.debug = /^(.*;)?\s*_debug\s*=/.test(document.cookie);

export const mark = Perf.mark;

export function gtmLoaded() {
  isGTMLoaded = true;

  // If correlationId/visitId are available, send all queued performance events
  // as soon as GTM iframe is loaded.
  if (eventQueue.length && IDStorage.get('correlationId') && IDStorage.get('visitId')) {
    eventQueue.forEach(sendEvent);
    eventQueue = [];
  }
}
